import { makeStyles } from '@material-ui/core/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { ReactElement } from 'react';

import { useClient } from '../clients/hooks/useClients';

interface UploadProps {
  img: string | undefined;
  onChangeImage: (data: any) => void;
  additionStyle: any;
}

const useStyles = makeStyles(() => ({
  input: {
    display: 'none',
  },
  btnStyleUpload: {
    fontSize: '16px!important' as any,
    lineHeight: '24px!important' as any,
    fontWeight: 'lighter!important' as any,
    fontFamily: 'Poppins!important' as any,
    textTransform: 'initial!important' as any,
    background: '#0CE1B7!important' as any,
    borderRadius: '4px!important' as any,
    marginTop: '17px!important' as any,
    color: '#011531!important' as any,
    width: '160px',
    height: '40px',
  },
  imgDiv: {
    border: '1px solid #E2E2E2',
    padding: '20px 20px 20px 30px',
    borderRadius: '10px',
  },
}));

export default function AddLocationImage({
  img = '',
  onChangeImage,
  additionStyle,
}: UploadProps): ReactElement {
  const classes = useStyles();
  const { uploadFile } = useClient();

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const fileLoaded = URL.createObjectURL(event.target.files[0]);
      const data = await uploadFile(event?.target.files[0]);
      onChangeImage(data?.pathToS3);
    }
  };

  return (
    <div className={classes.imgDiv} style={additionStyle}>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box mr="30px">
          <div>Add social Image:</div>
          <div>
            <input
              accept="image/*"
              className={classes.input}
              id="location-add-image"
              onChange={(event) => handleChange(event)}
              type="file"
            />
            <label htmlFor="location-add-image">
              <Button
                className={classes.btnStyleUpload}
                variant="contained"
                component="span"
              >
                Choose File{' '}
                <Box
                  component="img"
                  ml="5px"
                  mb="2px"
                  sx={{
                    height: 13,
                    width: 11,
                  }}
                  alt="The house from the offer."
                  src="/Upload.png"
                />
              </Button>
            </label>
          </div>
        </Box>
        <Box
          component="img"
          sx={{
            height: 114,
            width: 182,
          }}
          alt="The house from the offer."
          src={img || '/Frame.png'}
        />
      </Box>
    </div>
  );
}
