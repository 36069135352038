import './App.css';

import { ReactElement } from 'react';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import Loading from './components/common/Loading';
// import Sidebar from './components/Layout/Sidebar';
import { queryClient } from './react-query/queryClient';
import Router from './router/Router';

function App(): ReactElement {
  return (
    <QueryClientProvider client={queryClient}>
      <div className="App">
        <Router />
        <Loading />
      </div>
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
}

export default App;
