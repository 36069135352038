/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { debounce } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

import {
  fetchAutocomplete,
  fetchPlaceDetails,
  LanLngCoords,
  Photo,
} from '../locations/hooks/useGoogleMaps';

export interface Place {
  description: string;
  placeId: string;
}

interface SearchbarProps {
  onClick: (
    location: LanLngCoords,
    photos: Photo[],
    place: Place,
    accesible: boolean,
  ) => void;
  locationTxt?: string;
}

export default function Searchbar({ onClick, locationTxt }: SearchbarProps) {
  const [places, setPlaces] = useState<Place[]>([]);

  const handleInitValueTxt = async () => {
    if (locationTxt) {
      const data = await fetchAutocomplete(locationTxt);
      setPlaces(data);
      data.length && onSelect(data[0]);
    }
  };

  useEffect(() => {
    handleInitValueTxt();
  }, []);

  const usePlaces = async (e: any) => {
    if (!e?.target.value) {
      return;
    }
    const data = await fetchAutocomplete(e.target.value);
    setPlaces(data);
  };

  const onSelect = async (place: Place) => {
    const { placeId } = place;
    const { location, photos, accesible } = await fetchPlaceDetails(placeId);
    onClick(location, photos, place, accesible);
  };

  const debouncedChangeHandler = useMemo(() => debounce(usePlaces, 500), []);

  return (
    <Autocomplete
      onChange={(_, newValue) => (newValue ? onSelect(newValue) : null)}
      disablePortal
      id="combo-box-demo"
      options={places}
      isOptionEqualToValue={(option, value) => option.placeId === value.placeId}
      getOptionLabel={(option) => (option ? option.description : '')}
      renderInput={(params) => <TextField {...params} label="search" />}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.description}>
            {option.description}
          </li>
        );
      }}
      onInputChange={debouncedChangeHandler}
    />
  );
}
