import { makeStyles } from '@material-ui/core';
import Fab from '@mui/material/Fab';
import FormControlLabel from '@mui/material/FormControlLabel';
import Paper from '@mui/material/Paper';
import Switch from '@mui/material/Switch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import DeleteIcons from '../../../Icons/DeleteIcons';
import EditPencilGreen from '../../../Icons/EditPencilGreen';
import { Item } from '../../../interfaces/Item';

interface Column {
  id: 'title' | 'subType' | 'address' | 'website';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
  onClick?: () => void;
}

const columns: readonly Column[] = [
  { id: 'title', label: 'Title', minWidth: 170 },
  { id: 'subType', label: 'Sub Type', minWidth: 100 },
  {
    id: 'address',
    label: 'Address',
    minWidth: 170,
  },
  {
    id: 'website',
    label: 'Website',
    minWidth: 170,
  },
];

const useStyles = makeStyles(() => ({
  tableRow: {
    background: '#FFFFFF',
    borderSpacing: '0px 15px',
  },
  tableHead: {
    borderSpacing: '0px 5px!important' as '0px 5px',
    background: '#F0F0F0',
  },
  titleDiv: {
    fontSize: '14px!important' as '14px',
    lineHeight: '21px!important' as '21px',
    fontWeight: 400,
    fontFamily: 'Poppins!important' as 'Poppins',
    fontStyle: 'normal!important' as 'normal',
    color: '#011531!important' as '#011531',
  },
  titleDivDesc: {
    fontSize: '12px!important' as '14px',
    lineHeight: '18px!important' as '21px',
    fontWeight: 400,
    fontFamily: 'Poppins!important' as 'Poppins',
    fontStyle: 'normal!important' as 'normal',
    color: '#011531!important' as '#011531',
    opacity: '0.6',
  },
  fabStyle: {
    boxShadow: 'none!important' as 'none',
    background: 'none!important' as 'none',
  },
  headerTitle: {
    fontSize: '14px!important' as '14px',
    lineHeight: '21px!important' as '21px',
    fontWeight: 'normal!important' as 'normal',
    fontFamily: 'Poppins!important' as 'Poppins',
    fontStyle: 'normal!important' as 'normal',
    opacity: 0.6,
  },
  switchStyle: {
    padding: 8,
    '& .MuiSwitch-track': {
      borderRadius: 22 / 2,
      '&:before, &:after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        width: 55,
        height: 28,
      },
      '&:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="11" height="12" viewBox="0 0 11 12" fill="none">
        <path d="M1.21387 6.31103L4.48768 10.6666L9.07101 1.33325" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>')`,
        left: 12,
      },
      '&:after': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="11" height="12" viewBox="0 0 11 12" fill="none">
        <path d="M1.21387 6.31103L4.48768 10.6666L9.07101 1.33325" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>')`,
        right: 12,
      },
      width: '55px',
      height: '21px',
    },
    '& .Mui-checked+.MuiSwitch-track': {
      backgroundColor: 'white!important' as 'white',
      border: '1px solid',
    },
    '& .MuiSwitch-thumb': {
      boxShadow: 'none',
      color: '#0CE1B7',
      width: 23,
      height: 23,
      margin: 2,
    },
  },
  tableStyle: {
    border: '1px solid #ddd',
    '& th': {
      backgroundColor: '#f5f5f5',
      fontWeight: 'bold',
      padding: '10px',
    },
    '& td': {
      padding: '10px',
    },
  },
}));

interface TypesTableProp {
  items: Item[];
  onEditItem: (itemTitle: string) => void;
  onDeleteItem: (itemTitle: string) => void;
  onChangePublishMode: (itemTitle: string) => void;
}

export default function TypesTable({
  items = [],
  onEditItem,
  onDeleteItem,
  onChangePublishMode,
}: TypesTableProp) {
  const classes = useStyles();
  const rows = items.map(
    ({ title, subType, location, contact, published, _id: id }: Item) => ({
      title,
      subType,
      address: location?.address || '',
      website: contact.website,
      published: published || false,
      id,
    }),
  );

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table
          className={classes.tableStyle}
          stickyHeader
          aria-label="sticky table"
        >
          <TableHead className={classes.tableHead}>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  className={classes.headerTitle}
                  key={column.id}
                  align="center"
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
              <TableCell
                className={classes.headerTitle}
                key="status"
                align="center"
                style={{ minWidth: 170 }}
              >
                Published
              </TableCell>
              <TableCell
                className={classes.headerTitle}
                key="action"
                align="center"
                style={{ minWidth: 170 }}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.title}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell
                        className={
                          column.label === 'Title'
                            ? classes.titleDiv
                            : classes.titleDivDesc
                        }
                        key={column.id}
                        align="center"
                      >
                        {value}
                      </TableCell>
                    );
                  })}
                  <TableCell align="center">
                    <FormControlLabel
                      control={
                        <Switch
                          className={classes.switchStyle}
                          checked={row?.published}
                          onChange={() => {
                            onChangePublishMode(row.title);
                          }}
                        />
                      }
                      label=""
                    />
                  </TableCell>
                  <TableCell align="center">
                    <Fab
                      className={classes.fabStyle}
                      size="small"
                      aria-label="edit"
                      onClick={() => onEditItem(row.title)}
                    >
                      <EditPencilGreen />
                    </Fab>
                    <Fab
                      className={classes.fabStyle}
                      size="small"
                      onClick={() => onDeleteItem(row.title)}
                      aria-label="edit"
                    >
                      <DeleteIcons />
                    </Fab>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
