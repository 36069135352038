/* eslint-disable no-console */
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  makeStyles,
} from '@material-ui/core';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { Search } from '../../Icons/Search';
import { Client } from '../clients/hooks/useClients';
import { useClientLocation } from '../locations/hooks/useClientLocations';
import AddBundleForm from './AddBundleForm';
import { shortenLocation, useBundle } from './hooks/useBundle';
import Locations from './Locations';
import SelectedLocations from './SelectedLocations';

const schema = yup
  .object({
    bundleUUID: yup.string().required(),
    title: yup.string().required(),
    date: yup.string(),
    shorex: yup.string(),
  })
  .required();

interface AddBundleProps {
  onRefresh: (client: Client) => void;
}

const useStyles = makeStyles(() => ({
  label: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 'lighter',
    fontFamily: 'Poppins',
    textAlign: 'left',
  },
  searchBox: {
    width: '100%',
    background: '#FFFFFF',
    border: ' 0.5px solid rgba(1, 21, 49, 0.4)',
    borderRadius: '4px',
    padding: '11px 22px',
  },
  btnStyleCancle: {
    fontSize: '16px!important' as any,
    lineHeight: '24px!important' as any,
    fontWeight: 'normal!important' as any,
    fontFamily: 'Poppins!important' as any,
    textTransform: 'initial!important' as any,
    background: '#0CE1B7',
    color: '#011531!important',
    width: '140px',
    height: '50px',
  },
  btnStyleSave: {
    fontSize: '16px!important' as any,
    lineHeight: '24px!important' as any,
    fontWeight: 'normal!important' as any,
    fontFamily: 'Poppins!important' as any,
    textTransform: 'initial!important' as any,
    background: '#0CE1B7!important' as any,
    borderRadius: '4px!important' as any,
    color: '#011531!important' as any,
    width: '140px',
    height: '50px',
  },
}));

export default function AddBundle({ onRefresh }: AddBundleProps): ReactElement {
  const [searchText, setSearchText] = useState<string>('');
  const navigate = useNavigate();
  const [selectedLocations, setSelected] = useState<shortenLocation[]>([]);
  const location = useLocation();
  const {
    state: { client = {} },
  } = location;
  const { _id: clientId = '', clientName = '' } = client;
  const { locations: locationsDB } = useClientLocation(clientId);
  const locations = locationsDB.filter(({ published }) => published);
  const { addBundleMutation } = useBundle(clientId);
  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      bundleUUID: '',
      title: '',
      date: '',
      shorex: '',
      bannerUrl: '',
    },
  });

  const classes = useStyles();
  useEffect(() => {
    onRefresh(client);
  }, []);

  const handleSelect = (id: string, title: string) => {
    const exist = selectedLocations.find(
      ({ id: locationId }) => locationId === id,
    );
    setSelected(
      exist ? selectedLocations : [...selectedLocations, { id, title }],
    );
  };

  const handleDeleteLocation = (locationId: string) => {
    const filtered = selectedLocations.filter(({ id }) => id !== locationId);
    setSelected(filtered);
  };

  const onSubmit = () => {
    const pages = selectedLocations.map(({ id }) => id);
    addBundleMutation({ clientId, pages, ...getValues() });
    navigate(`/clients/${clientName}/bundles`, {
      replace: true,
      state: {
        client: {
          _id: client.clientId,
          ...client,
        },
      },
    });
  };

  return (
    <>
      <div>
        <AddBundleForm control={control} errors={errors} />
        <Grid
          item
          style={{ display: 'grid', paddingRight: '20px', paddingTop: '10px' }}
          container
          xs={12}
          lg={12}
          sm={12}
          md={12}
        >
          <InputLabel className={classes.label} shrink>
            *Bundle Locations :
          </InputLabel>

          <Input
            className={classes.searchBox}
            startAdornment={
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            }
            disableUnderline
            placeholder="Search"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setSearchText(e.target.value)
            }
            value={searchText}
          />
        </Grid>
        <Grid
          item
          style={{ paddingRight: '20px' }}
          container
          xs={12}
          lg={12}
          sm={12}
          md={12}
        >
          <SelectedLocations
            onDeleteLocation={handleDeleteLocation}
            selectedLocations={selectedLocations}
          />
          <Locations
            searchText={searchText}
            onSelect={handleSelect}
            locations={locations}
          />
        </Grid>
      </div>

      <Box mt="20px" style={{ textAlign: 'right' }}>
        <Box component="span">
          <Box ml="20px" component="span">
            <Button
              className={classes.btnStyleSave}
              onClick={handleSubmit(onSubmit)}
            >
              Save
            </Button>
            <Button
              className={classes.btnStyleCancle}
              onClick={() => navigate('/')}
            >
              Cancle
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
}
