function CoffeeIcon({ fill }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
    >
      <path
        d="M9.87206 13.4258H23.9427C24.2839 13.4258 24.6219 13.4938 24.9372 13.6259C25.2525 13.7579 25.539 13.9516 25.7803 14.1956C26.0217 14.4397 26.2131 14.7295 26.3437 15.0483C26.4743 15.3672 26.5415 15.709 26.5415 16.0542V20.1848C26.5415 21.3422 26.3161 22.4882 25.8782 23.5574C25.4403 24.6266 24.7984 25.5982 23.9893 26.4165C22.3551 28.0693 20.1387 28.9978 17.8277 28.9978H15.9871C13.676 28.9978 11.4596 28.0693 9.82542 26.4165C8.19126 24.7638 7.27319 22.5222 7.27319 20.1848V16.0542C7.27319 15.3571 7.547 14.6886 8.03438 14.1956C8.52177 13.7027 9.1828 13.4258 9.87206 13.4258V13.4258Z"
        stroke={fill}
        strokeOpacity="0.4"
        strokeWidth="1.5"
      />
      <path
        d="M5.2232 16.3164H4.4466C3.349 16.3164 2.45923 17.2163 2.45923 18.3264V19.811C2.45923 20.921 3.349 21.8209 4.4466 21.8209H5.2232C6.3208 21.8209 7.21057 20.921 7.21057 19.811V18.3264C7.21057 17.2163 6.3208 16.3164 5.2232 16.3164Z"
        stroke={fill}
        strokeOpacity="0.4"
        strokeWidth="1.5"
      />
      <path
        d="M14.3665 7.00658C14.5404 6.55967 14.8512 6.17094 15.26 5.88923C15.687 5.60718 15.9484 5.64957 16.7492 5.33056C17.2344 5.1697 17.6875 4.93364 18.0895 4.63222C18.3282 4.42978 18.8776 3.87655 18.983 2.67578"
        stroke={fill}
        strokeOpacity="0.4"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M13.6218 3.51429C14.1641 3.30855 14.6335 2.96346 14.976 2.51868C15.3184 2.07389 15.5201 1.54749 15.5578 1"
        stroke={fill}
        strokeOpacity="0.4"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M16.6006 9.93809C16.768 9.40895 17.0737 8.92739 17.4898 8.5371C17.906 8.14681 18.4194 7.86015 18.9836 7.70312"
        stroke={fill}
        strokeOpacity="0.4"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default CoffeeIcon;
