import { makeStyles } from '@material-ui/core';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { ReactElement, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

import { Client } from '../../clients/hooks/useClients';
import BackBtn from '../../common/BackBtn';
import NextBtn from '../../common/NextBtn';
import AddLocationStep4Form from './AddLocationStep4Form';
import { useEssentialPhrases } from './hooks/useEssentialPhrases';

const useStyles = makeStyles(() => ({
  cancelBtn: {
    fontSize: '16px!important' as '16px',
    lineHeight: '24px!important' as '24px',
    fontWeight: 'normal!important' as 'normal',
    color: ' #0CB4A5!important' as '#0CB4A5',
    textDecorationLine: 'underline!important' as 'underline',
    textTransform: 'capitalize!important' as 'capitalize',
  },
}));

interface EditLocationStep4Props {
  onRefresh: (client: Client) => void;
}

export default function EditLocationStep4({
  onRefresh,
}: EditLocationStep4Props): ReactElement {
  const classes = useStyles();
  const location = useLocation();
  const {
    state: { client, locationId },
  } = location;
  const {
    page: { essentialPhrases },
    updateEssentialPhrasesMutation,
  } = useEssentialPhrases(locationId);
  const { clientName } = client;

  const { handleSubmit, control, getValues, setValue } = useForm({
    defaultValues: {
      essentialPhrases: {
        language: essentialPhrases?.language || '',
        goodMorning: {
          text: essentialPhrases?.goodMorning?.text || '',
          pronunciation: essentialPhrases?.goodMorning?.pronunciation || '',
        },
        goodAfternoon: {
          text: essentialPhrases?.goodAfternoon?.text || '',
          pronunciation: essentialPhrases?.goodAfternoon?.pronunciation || '',
        },
        goodNight: {
          text: essentialPhrases?.goodNight?.text || '',
          pronunciation: essentialPhrases?.goodNight?.pronunciation || '',
        },
        hello: {
          text: essentialPhrases?.hello?.text || '',
          pronunciation: essentialPhrases?.hello?.pronunciation || '',
        },
        whereIs: {
          text: essentialPhrases?.whereIs?.text || '',
          pronunciation: essentialPhrases?.whereIs?.pronunciation || '',
        },
        please: {
          text: essentialPhrases?.please?.text || '',
          pronunciation: essentialPhrases?.please?.pronunciation || '',
        },
        thankYou: {
          text: essentialPhrases?.thankYou?.text || '',
          pronunciation: essentialPhrases?.thankYou?.pronunciation || '',
        },
        excuseMe: {
          text: essentialPhrases?.excuseMe?.text || '',
          pronunciation: essentialPhrases?.excuseMe?.pronunciation || '',
        },
        iDontUnderstand: {
          text: essentialPhrases?.iDontUnderstand?.text || '',
          pronunciation: essentialPhrases?.iDontUnderstand?.pronunciation || '',
        },
      },
    },
  });

  useEffect(() => {
    onRefresh(client);
  }, []);

  useEffect(() => {
    setValue('essentialPhrases.language', essentialPhrases?.language || '');
    setValue(
      'essentialPhrases.goodMorning.text',
      essentialPhrases?.goodMorning?.text || '',
    );
    setValue(
      'essentialPhrases.goodMorning.pronunciation',
      essentialPhrases?.goodMorning?.pronunciation || '',
    );
    setValue(
      'essentialPhrases.goodAfternoon.text',
      essentialPhrases?.goodAfternoon?.text || '',
    );
    setValue(
      'essentialPhrases.goodAfternoon.pronunciation',
      essentialPhrases?.goodAfternoon?.pronunciation || '',
    );
    setValue(
      'essentialPhrases.goodNight.text',
      essentialPhrases?.goodNight?.text || '',
    );
    setValue(
      'essentialPhrases.goodNight.pronunciation',
      essentialPhrases?.goodNight?.pronunciation || '',
    );
    setValue(
      'essentialPhrases.hello.text',
      essentialPhrases?.hello?.text || '',
    );
    setValue(
      'essentialPhrases.hello.pronunciation',
      essentialPhrases?.hello?.pronunciation || '',
    );
    setValue(
      'essentialPhrases.whereIs.text',
      essentialPhrases?.whereIs?.text || '',
    );
    setValue(
      'essentialPhrases.whereIs.pronunciation',
      essentialPhrases?.whereIs?.pronunciation || '',
    );
    setValue(
      'essentialPhrases.please.text',
      essentialPhrases?.please?.text || '',
    );
    setValue(
      'essentialPhrases.please.pronunciation',
      essentialPhrases?.please?.pronunciation || '',
    );
    setValue(
      'essentialPhrases.thankYou.text',
      essentialPhrases?.thankYou?.text || '',
    );
    setValue(
      'essentialPhrases.thankYou.pronunciation',
      essentialPhrases?.thankYou?.pronunciation || '',
    );
    setValue(
      'essentialPhrases.excuseMe.text',
      essentialPhrases?.excuseMe?.text || '',
    );
    setValue(
      'essentialPhrases.excuseMe.pronunciation',
      essentialPhrases?.excuseMe?.pronunciation || '',
    );
    setValue(
      'essentialPhrases.iDontUnderstand.text',
      essentialPhrases?.iDontUnderstand?.text || '',
    );
    setValue(
      'essentialPhrases.iDontUnderstand.pronunciation',
      essentialPhrases?.iDontUnderstand?.pronunciation || '',
    );
  }, [essentialPhrases]);

  const onSubmit = () =>
    updateEssentialPhrasesMutation({
      locationId,
      essentialPhrases: { ...getValues() },
      client,
    });

  return (
    <div>
      <AddLocationStep4Form control={control} />
      <Box mt="20px" style={{ textAlign: 'right' }}>
        <BackBtn
          path={`/clients/${clientName}/locations/edit/step1/step2/step3`}
          state={{ client, locationId }}
        />
        <NextBtn onSubmitted={handleSubmit(onSubmit)} />
        <Box ml="20px" mr="20px" component="span">
          <Button
            className={classes.cancelBtn}
            onClick={() => console.log('bla')}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </div>
  );
}
