import React from 'react';

function EditPencilGreen() {
  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
    >
      <rect width="30" height="30" rx="8" fill="#0CE1B7" fillOpacity="0.6" />
      <path
        d="M16.1634 11.0531L10.4328 17.4824C10.3671 17.5561 10.3247 17.6476 10.3109 17.7453L9.86432 20.9142C9.81074 21.2944 10.1878 21.5906 10.5445 21.4485L13.2755 20.3604C13.3476 20.3317 13.412 20.2866 13.4637 20.2286L19.2165 13.7744M16.1634 11.0531L18.5521 8.37325C18.7358 8.16711 19.0519 8.14895 19.258 8.33269L21.5646 10.3886C21.7707 10.5724 21.7889 10.8884 21.6052 11.0946L19.2165 13.7744M16.1634 11.0531L19.2165 13.7744"
        stroke="#011531"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default EditPencilGreen;
