import { makeStyles } from '@material-ui/core';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { ReactElement, useState } from 'react';

interface SelectSortProps {
  sortType: string;
  onChange: (type: string) => void;
}

const useStyles = makeStyles(() => ({
  sortedBy: {
    width: '100%',
  },
  searchBox: {
    width: '100%',
    background: '#FFFFFF',
    borderRadius: '4px',
    textAlign: 'left',
  },
}));

export default function SelectSort({
  sortType,
  onChange,
}: SelectSortProps): ReactElement {
  const [sort, setSort] = useState(sortType);

  const handleChange = (event: SelectChangeEvent) => {
    setSort(event.target.value as string);
    onChange(event.target.value);
  };

  const classes = useStyles();

  return (
    <Box>
      <FormControl className={classes.sortedBy}>
        <InputLabel id="demo-simple-select-label">Sort by</InputLabel>
        <Select
          className={classes.searchBox}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={sort}
          label="Sort by"
          onChange={handleChange}
        >
          <MenuItem value={1}>from newest to oldest</MenuItem>
          <MenuItem value={2}>from oldest to newest</MenuItem>
          <MenuItem value={3}>alphbetical order</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}
