/* eslint-disable react-hooks/exhaustive-deps */
import { makeStyles } from '@material-ui/core';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { ReactElement, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import PlusIcon from '../../../Icons/PlusIcon';
import { Book } from '../../../interfaces/Book';
import { Client } from '../../clients/hooks/useClients';
import BackBtn from '../../common/BackBtn';
import AddBookingPopup from '../../popups/addBookingPopup/AddBookingPopup';
import DeleteBookingPopup from '../../popups/deleteBookingPopup/DeleteBookingPopup';
import EditBookingPopup from '../../popups/editBookingPopup/EditBookingPopup';
import { useClientLocation } from '../hooks/useClientLocations';
import { usePage } from '../hooks/usePage';
import BookingTable from './BookingTable';

const useStyles = makeStyles(() => ({
  btnStyleCancle: {
    fontSize: '16px!important' as any,
    lineHeight: '24px!important' as any,
    fontWeight: 'normal!important' as any,
    fontFamily: 'Poppins!important' as any,
    textTransform: 'initial!important' as any,
    background: '#0CE1B7',
    color: '#011531!important' as any,
    borderRadius: '4px!important' as any,
    border: '1px solid #011531!important' as any,
    width: '180px',
    height: '50px',
  },
  btnStyleSave: {
    fontSize: '16px!important' as any,
    lineHeight: '24px!important' as any,
    fontWeight: 'normal!important' as any,
    fontFamily: 'Poppins!important' as any,
    textTransform: 'initial!important' as any,
    background: '#0CE1B7!important' as any,
    borderRadius: '4px!important' as any,
    color: '#011531!important' as any,
    width: '180px',
    height: '50px',
  },
  btnStyle: {
    fontSize: '16px!important' as '16px',
    lineHeight: '24px!important' as '24px',
    fontWeight: 'normal!important' as 'normal',
    fontFamily: 'Poppins!important' as 'Poppins',
    textTransform: 'initial!important' as 'initial',
    background: '#0CE1B7!important' as '#0CE1B7',
    borderRadius: '4px!important' as '4px',
    color: '#011531!important' as '#011531',
    borderColor: '#0CE1B7!important' as '#0CE1B7',
    width: '200px',
    height: '40px',
  },
  divStyle: {
    display: 'flex',
    justifyContent: 'end',
    paddingRight: '24px',
    paddingBottom: '40px',
    paddingTop: '10px',
  },
  mainDiv: {
    marginTop: '60px',
    paddingLeft: '50px',
    paddingRight: '50px',
  },
  bookingStyle: {
    fontSize: '16px!important' as '16px',
    lineHeight: '24px!important' as '24px',
    fontWeight: 500 as any,
    fontFamily: 'Poppins!important' as 'Poppins',
    textAlign: 'left',
    margin: '10px 20px 10px 20px',
  },
  btnStyleCancel: {
    fontSize: '16px!important' as '16px',
    lineHeight: '40px!important' as '40px',
    fontWeight: 'normal!important' as 'normal',
    fontFamily: 'Poppins!important' as 'Poppins',
    textAlign: 'center',
    textDecorationLine: 'underline!important' as 'underline',
    color: '#0CB4A5!important' as '#0CB4A5',
    textTransform: 'capitalize!important' as 'capitalize',
  },
}));

interface EditLocationStep5Props {
  onRefresh: (client: Client) => void;
}

export default function EditLocationStep5({
  onRefresh,
}: EditLocationStep5Props): ReactElement {
  const classes = useStyles();
  const [editBookingPopupOpen, setEditBookingPopupOpen] =
    useState<boolean>(false);
  const [addBookingPopupOpen, setAddBookingPopupOpen] =
    useState<boolean>(false);
  const [deleteBookingPopupOpen, setDeleteBookingPopupOpen] = useState(false);
  const [editedBooking, setEditedBooking] = useState<Book>();
  const [bookingToDelete, setBookingToDelete] = useState<Book>();
  const location = useLocation();
  const [bookIndex, setBookIndex] = useState<number>();

  const {
    state: { client, locationId },
  } = location;
  const {
    page,
    editBookingMutation,
    deleteBookingMutation,
    addBookingMutation,
  } = usePage(locationId);
  const { finishProcessMutation } = useClientLocation(client?.clientId);
  const { _id, bookings = [] } = page;
  const { clientName } = client;

  useEffect(() => {
    onRefresh(client);
  }, [client]);

  const handleEditedBooking = (index: number) => {
    setBookIndex(index);
    setEditedBooking(bookings[index]);
    setEditBookingPopupOpen(true);
  };

  const handleDecition = (toDelete: boolean) => {
    if (toDelete && bookingToDelete) {
      deleteBookingMutation({
        pageId: _id,
        bookingTitle: bookingToDelete?.title,
      });
    }
    setBookingToDelete(undefined);
    setBookIndex(undefined);
  };

  const handleDeleteBooking = (index: number) => {
    setDeleteBookingPopupOpen(true);
    setBookingToDelete(bookings[index]);
  };

  const saveEditedBooking = (editedBook: Book) => {
    editBookingMutation({ pageId: _id, editedBook, bookIndex });
    handleClosePopup();
  };

  const saveNewBooking = (newBook: Book) => {
    addBookingMutation({ pageId: _id, ...newBook });
    setAddBookingPopupOpen(false);
  };

  const handleSubmit = (toPublish: boolean) => {
    finishProcessMutation({ toPublish, pageId: locationId, client });
  };

  const handleClosePopup = () => {
    setEditBookingPopupOpen(false);
    setEditedBooking(undefined);
  };

  return (
    <>
      <div className={classes.mainDiv}>
        <div className={classes.bookingStyle}>Bookings</div>
        <Paper className="test" sx={{ overflow: 'hidden' }}>
          <BookingTable
            onEditItem={handleEditedBooking}
            onDeleteBooking={handleDeleteBooking}
            bookings={bookings}
          />
          <div className={classes.divStyle}>
            <Button
              onClick={() => setAddBookingPopupOpen(true)}
              variant="outlined"
              className={classes.btnStyle}
            >
              <span style={{ marginRight: '6px', display: 'flex' }}>
                <PlusIcon />
              </span>
              Add Booking
            </Button>
          </div>
        </Paper>
        <AddBookingPopup
          open={addBookingPopupOpen}
          onAddingBooking={saveNewBooking}
          onClose={() => setAddBookingPopupOpen(false)}
        />
        {editedBooking && (
          <EditBookingPopup
            book={editedBooking}
            open={editBookingPopupOpen}
            onEditBooking={saveEditedBooking}
            onClose={handleClosePopup}
          />
        )}
      </div>
      <DeleteBookingPopup
        open={deleteBookingPopupOpen}
        onClose={() => setDeleteBookingPopupOpen(false)}
        toDeleteBooking={handleDecition}
      />
      <Box mt="20px" sx={{ width: '90%' }} style={{ textAlign: 'right' }}>
        <BackBtn
          path={`/clients/${clientName}/locations/edit/step1/step2/step3/step4`}
          state={{ client, locationId }}
        />
        <Box ml="20px" component="span">
          <Button
            className={classes.btnStyleSave}
            onClick={() => handleSubmit(false)}
          >
            Save
          </Button>
        </Box>
        <Box ml="20px" component="span">
          <Button
            className={classes.btnStyleSave}
            onClick={() => handleSubmit(true)}
          >
            Publish and Save
          </Button>
        </Box>
        <Box ml="20px" component="span">
          <Button className={classes.btnStyleCancel}>Cancel </Button>
        </Box>
      </Box>
    </>
  );
}
