import React from 'react';

export default function DeleteIcons() {
  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
    >
      <path
        d="M12.1553 19C13.925 19 15.3454 17.5882 15.4153 15.7694L15.7879 3.94828H17.5343C17.7905 3.94828 18 3.73295 18 3.46973C18 3.20651 17.7905 2.99118 17.5343 2.99118H12.6442V1.9144C12.6442 0.861461 11.8059 0 10.7813 0H7.24196C6.21734 0 5.37904 0.861461 5.37904 1.9144V2.99118H0.46568C0.209525 2.99118 0 3.20651 0 3.46973C0 3.73295 0.209537 3.94828 0.46568 3.94828H2.21213L2.58475 15.7694C2.65459 17.588 4.07506 19 5.84471 19H12.1553ZM6.31048 1.91436C6.31048 1.38791 6.72955 0.957264 7.24184 0.957264H10.7814C11.2937 0.957264 11.7127 1.38791 11.7127 1.91436V2.99114H6.31036L6.31048 1.91436ZM3.5394 15.7217L3.16679 3.92443H14.8797L14.484 15.7217C14.4374 17.0138 13.4128 18.0428 12.1554 18.0428H5.86815C4.61062 18.0428 3.58599 17.0377 3.53938 15.7217H3.5394Z"
        fill="#011531"
      />
      <path
        d="M6.5 15C6.77503 15 7 14.7877 7 14.5281V7.47195C7 7.21236 6.77502 7.00002 6.5 7.00002C6.22497 7.00002 6 7.21237 6 7.47195V14.5281C6 14.7875 6.22498 14.9998 6.5 14.9998V15Z"
        fill="#011531"
      />
      <path
        d="M9 15C9.27503 15 9.5 14.7877 9.5 14.5281V7.47195C9.5 7.21236 9.27502 7.00002 9 7.00002C8.72497 7.00002 8.5 7.21237 8.5 7.47195V14.5281C8.5 14.7875 8.72498 15 9 15Z"
        fill="#011531"
      />
      <path
        d="M11.5 15C11.775 15 12 14.7877 12 14.5281V7.47195C12 7.21236 11.775 7.00002 11.5 7.00002C11.225 7.00002 11 7.21237 11 7.47195V14.5281C11 14.7875 11.225 14.9998 11.5 14.9998V15Z"
        fill="#011531"
      />
    </svg>
  );
}
