import { makeStyles } from '@material-ui/core';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { ReactElement } from 'react';

const useStyles = makeStyles(() => ({
  btnStyleNext: {
    fontSize: '16px!important' as '16px',
    lineHeight: '24px!important' as '24px',
    fontWeight: 'normal!important' as 'normal',
    fontFamily: 'Poppins!important' as 'Poppins',
    textTransform: 'initial!important' as 'initial',
    background: '#0CE1B7!important' as '#0CE1B7',
    borderRadius: '4px!important' as '4px',
    color: '#011531!important' as '#011531',
    width: '180px',
    height: '50px',
  },
}));

interface NextBtnProps {
  onSubmitted: () => void;
}

export default function NextBtn({ onSubmitted }: NextBtnProps): ReactElement {
  const classes = useStyles();

  return (
    <Box ml="20px" component="span">
      <Button className={classes.btnStyleNext} onClick={() => onSubmitted()}>
        Next
      </Button>
    </Box>
  );
}
