/* eslint-disable react/require-default-props */
import { Status, Wrapper } from '@googlemaps/react-wrapper';
import { yupResolver } from '@hookform/resolvers/yup';
import { InputLabel, makeStyles } from '@material-ui/core';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { ReactElement, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { addLocation } from '../locations/hooks/useClientLocations';
import {
  googleApiKey,
  LanLngCoords,
  Photo,
  photosApiUrl,
} from '../locations/hooks/useGoogleMaps';
import Map from './Map';
import Marker from './Marker';
import Searchbar, { Place } from './Searchbar';

const schema = yup
  .object({
    title: yup.string().required(),
    pageSubTitle: yup.string().required(),
    city: yup.string().required(),
    countryCode: yup.string().required(),
    introduction: yup.string().required(),
    description: yup.string().required(),
    dockingPoint: yup.string().required(),
  })
  .required();

const useStyles = makeStyles(() => ({
  label: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 'lighter',
    fontFamily: 'Poppins',
    textAlign: 'left',
  },
}));

const render = (status: Status) => <h1>{status}</h1>;

interface WrapperMapProps {
  coordinate?: number[];
  onSelectLocation: (
    images: Photo[],
    position: LanLngCoords,
    place: Place,
    accesible: boolean,
  ) => void;
  showLanLng?: boolean;
  locationTxt?: string;
}

export default function WrapperMap({
  coordinate,
  onSelectLocation,
  showLanLng = true,
  locationTxt,
}: WrapperMapProps): ReactElement {
  const classes = useStyles();
  const [zoom, setZoom] = useState(8);
  const initialValues = { lat: -33, lng: 151 };
  const [center, setCenter] =
    useState<google.maps.LatLngLiteral>(initialValues);
  const [position, setPosition] =
    useState<google.maps.LatLngLiteral>(initialValues);
  const { control, setValue } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      lat: coordinate ? coordinate[0] : initialValues.lat,
      lng: coordinate ? coordinate[0] : initialValues.lng,
    },
  });

  useEffect(() => {
    setValue('lat', coordinate ? coordinate[0] : initialValues.lat);
    setValue('lng', coordinate ? coordinate[1] : initialValues.lng);
    if (coordinate) {
      setPosition({ lat: coordinate[0], lng: coordinate[1] });
    }
  }, [coordinate]);

  const onIdle = (m: google.maps.Map) => {
    setZoom(m.getZoom()!);
    setCenter(m.getCenter()!.toJSON());
  };

  const handleSelect = (
    location: LanLngCoords,
    photos: Photo[],
    place: Place,
    accesible: boolean,
  ) => {
    setPosition(location);
    setCenter(location);
    onSelectLocation(photos, location, place, accesible);
    setValue('lat', location.lat);
    setValue('lng', location.lng);
  };

  return (
    <div>
      <Searchbar onClick={handleSelect} locationTxt={locationTxt} />
      <div style={{ height: '400px', minWidth: 350 }}>
        <Wrapper apiKey={googleApiKey} render={render}>
          <Map onIdle={onIdle} zoom={zoom} center={position}>
            <Marker position={position} />
          </Map>
        </Wrapper>
      </div>
      {showLanLng && (
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <InputLabel className={classes.label} shrink>
              *lat:
            </InputLabel>
            <Controller
              name="lat"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField onChange={onChange} fullWidth value={value} />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <InputLabel className={classes.label} shrink>
              *lng:
            </InputLabel>
            <Controller
              name="lng"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField onChange={onChange} fullWidth value={value} />
              )}
            />
          </Grid>
        </Grid>
      )}
    </div>
  );
}
