function PlusIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 8C0 3.58172 3.58172 0 8 0C12.4182 0 16 3.58172 16 8C16 12.4182 12.4182 16 8 16C3.58172 16 0 12.4182 0 8ZM8 1.6C4.46538 1.6 1.6 4.46538 1.6 8C1.6 11.5346 4.46538 14.4 8 14.4C11.5346 14.4 14.4 11.5346 14.4 8C14.4 4.46538 11.5346 1.6 8 1.6Z"
        fill="#011531"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.66667 4.66667C8.66667 4.29848 8.3682 4 8 4C7.6318 4 7.33333 4.29848 7.33333 4.66667V7.33333H4.66667C4.29848 7.33333 4 7.6318 4 8C4 8.3682 4.29848 8.66667 4.66667 8.66667H7.33333V11.3333C7.33333 11.7015 7.6318 12 8 12C8.3682 12 8.66667 11.7015 8.66667 11.3333V8.66667H11.3333C11.7015 8.66667 12 8.3682 12 8C12 7.6318 11.7015 7.33333 11.3333 7.33333H8.66667V4.66667Z"
        fill="#011531"
      />
    </svg>
  );
}
export default PlusIcon;
