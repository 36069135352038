/* eslint-disable */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { yupResolver } from '@hookform/resolvers/yup';
import { InputLabel, makeStyles } from '@material-ui/core';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { ReactElement, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import ColorPicker from 'react-pick-color';
import * as yup from 'yup';
import { useClient } from './hooks/useClients';
import './addClient.css';
import UploadFile from '../common/UploadFile';

const schema = yup
  .object({
    clientName: yup.string().required(),
    domains: yup.object({
      guidesDomain: yup.string().required(),
      clientDomain: yup.string().required(),
      clientMeta: yup.string().required(),
      bannerDomain: yup.string().required(),
    }),
  })
  .required();

const useStyles = makeStyles(() => ({
  mainDiv: {
    maxWidth: '1100px',
    margin: '0px auto',
  },
  titleDiv: {
    textAlign: 'left',
    marginBottom: '10px',
    marginLeft: '0px',
  },
  imgDiv: {
    border: '1px solid #E2E2E2',
    display: 'inline-flex',
    padding: '20px',
    borderRadius: '10px',
  },
  label: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 'lighter',
    fontFamily: 'Poppins',
    textAlign: 'left',
  },

  gridOption: {
    display: 'block',
  },
  addInput: {
    border: '0.5px solid #011531',
    borderRadius: '4px',
  },
  btnStyleCancle: {
    fontSize: '16px!important' as any,
    lineHeight: '24px!important' as any,
    fontWeight: 'normal!important' as any,
    fontFamily: 'Poppins!important' as any,
    textTransform: 'initial!important' as any,
    background: '#0CE1B7',
    color: '#011531!important' as any,
    borderRadius: '4px!important' as any,
    border: '1px solid #011531!important' as any,
    width: '180px',
    height: '50px',
  },
  btnStyleSave: {
    fontSize: '16px!important' as any,
    lineHeight: '24px!important' as any,
    fontWeight: 'normal!important' as any,
    fontFamily: 'Poppins!important' as any,
    textTransform: 'initial!important' as any,
    background: '#0CE1B7!important' as any,
    borderRadius: '4px!important' as any,
    color: '#011531!important' as any,
    width: '180px',
    height: '50px',
  },
  gridDiv: {
    alignItems: 'start!important' as any,
    display: 'flex',
    flexDirection: 'column!important' as any,
    width: '100%',
  },
}));

export default function AddClient(): ReactElement {
  const navigate = useNavigate();
  const { addClientMutation } = useClient();
  const [clientLogoURL, setClientLogoURL] = useState<string | undefined>('');

  const [color, setColor] = useState('#fff');
  const [mainShorexImg, setMainShorexImg] = useState<string | undefined>('');

  const classes = useStyles();

  const { handleSubmit, control, getValues } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      clientName: '',
      domains: {
        guidesDomain: '',
        clientDomain: '',
        clientMeta: '',
        bannerDomain: '',
      },
    },
  });

  const handleChangeImage = (data: any, id: string) => {
    id === 'logo' ? setClientLogoURL(data) : setMainShorexImg(data);
  };

  const onSubmit = () => {
    addClientMutation({
      ...getValues(),
      clientLogoURL,
      mainShorexImg,
    });
  };

  return (
    <div className={classes.mainDiv}>
      <Box ml={'20px'} mr={'20px'} mb="50px">
        <Grid container spacing={5} rowSpacing={3.5}>
          <Grid item lg={6} md={6} xs={12}>
            <InputLabel className={classes.label} shrink>
              Name
            </InputLabel>
            <Controller
              name="clientName"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  inputProps={{
                    style: {
                      border: '0.5 px solid #011531',
                      borderRadius: '4px',
                      padding: '12px 14px',
                    },
                  }}
                  fullWidth
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </Grid>
          <Grid item lg={6} md={6} xs={12}>
            <InputLabel className={classes.label} shrink>
              Guides url
            </InputLabel>

            <Controller
              name="domains.guidesDomain"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  inputProps={{
                    style: {
                      border: '0.5 px solid #011531',
                      borderRadius: '4px',
                      padding: '12px 14px',
                    },
                  }}
                  fullWidth
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </Grid>
          <Grid item lg={6} md={6} xs={12}>
            <InputLabel className={classes.label} shrink>
              Main url
            </InputLabel>
            <Controller
              name="domains.clientDomain"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  inputProps={{
                    style: {
                      border: '0.5 px solid #011531',
                      borderRadius: '4px',
                      padding: '12px 14px',
                    },
                  }}
                  fullWidth
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </Grid>

          <Grid item lg={6} md={6} xs={12}>
            <InputLabel className={classes.label} shrink>
              Banner url
            </InputLabel>
            <Controller
              name="domains.bannerDomain"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  inputProps={{
                    style: {
                      border: '0.5 px solid #011531',
                      borderRadius: '4px',
                      padding: '12px 14px',
                    },
                  }}
                  fullWidth
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </Grid>
          <Grid item lg={6} md={6} xs={12}>
            <InputLabel className={classes.label} shrink>
              Meta text
            </InputLabel>
            <Controller
              name="domains.clientMeta"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  fullWidth
                  inputProps={{
                    style: {
                      border: '0.5 px solid #011531',
                      borderRadius: '4px',
                      padding: '12px 14px',
                    },
                  }}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </Grid>
        </Grid>
      </Box>
      <Box ml={'20px'} className="second-block" mr={'20px'} mb="50px">
        <Grid container spacing={5} rowSpacing={3.5}>
          <Grid item className={classes.gridDiv} lg={4} md={6} sm={6} xs={12}>
            <div className={classes.titleDiv}>Logo:</div>
            <UploadFile
              id="logo"
              isFrom="logo"
              img={clientLogoURL}
              onChangeImage={handleChangeImage}
            />
          </Grid>
          <Grid item className={classes.gridDiv} lg={4} md={6} sm={6} xs={12}>
            <div className={classes.titleDiv}>Main Background Image:</div>
            <UploadFile
              id="shorex"
              isFrom="background"
              img={mainShorexImg}
              onChangeImage={handleChangeImage}
            />
          </Grid>
          <Grid item className={classes.gridDiv} lg={4} md={6} sm={6} xs={12}>
            <div className={classes.titleDiv}>Button Color:</div>
            <div className={classes.imgDiv}>
              <div>
                <ColorPicker
                  color={color}
                  theme={{ width: '200px' }}
                  className="color-picker"
                  onChange={(color) => setColor(color.hex)}
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <Box mt={'20px'} style={{ textAlign: 'right' }}>
        <Box component="span">
          <Button
            className={classes.btnStyleCancle}
            onClick={() => navigate('/clients')}
          >
            Cancle
          </Button>
        </Box>
        <Box ml={'20px'} component="span">
          <Button
            className={classes.btnStyleSave}
            onClick={handleSubmit(onSubmit)}
          >
            Save
          </Button>
        </Box>
      </Box>
    </div>
  );
}
