import { makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(() => ({
  numberStyle: {
    position: 'absolute',
    top: '30%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));
function LocationStep({ fill, index }) {
  const classes = useStyles();
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="35"
        height="35"
        viewBox="0 0 35 35"
        fill="none"
      >
        <rect width="35" height="35" rx="8" fill={fill} />
      </svg>
      <span className={classes.numberStyle}>{index}</span>
    </>
  );
}
export default LocationStep;
