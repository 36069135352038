import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { ReactElement, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { Client } from '../../clients/hooks/useClients';
import GoogleImageCard from '../../common/GoogleImageCard';
import LocationImage from '../../common/LocationImage';
import SelectImageTypeTabs from '../../common/SelectImageTypeTabs';
import UploadImageCard from '../../common/UploadImageCard';
import UploadSocialImage from '../../common/UploadSocialImage';
import WrapperMap from '../../maps/WrapperMap';
import ExitPopup from '../../popups/exitPopup/ExitPopup';
import ImagesPopup from '../../popups/imagesPopup/ImagesPopup';
import { useClientLocation } from '../hooks/useClientLocations';
import {
  googleApiKey,
  LanLngCoords,
  Photo,
  photosApiUrl,
} from '../hooks/useGoogleMaps';
import AddLocationBasicForm from './AddLocationBasicForm';

enum ImageType {
  GoogleImage,
  SimpleImage,
}

const schema = yup
  .object({
    title: yup.string().required(),
    pageSubTitle: yup.string().required(),
    city: yup.string().required(),
    countryCode: yup.string().required(),
    introduction: yup.string().required(),
    description: yup.string().required(),
  })
  .required();

interface AddLocationBasicProps {
  onRefresh: (client: Client) => void;
}

export default function AddLocationBasic({
  onRefresh,
}: AddLocationBasicProps): ReactElement {
  const [imagesPopupOpen, setImagesPopupOpen] = useState<boolean>(false);
  const [exitPopupOpen, setExitPopupOpen] = useState<boolean>(false);
  const [images, setImages] = useState<Photo[]>([]);
  const [socialImage, setSocialImage] = useState<string | undefined>('');
  const [coordinates, setCoordinates] = useState<number[]>();
  const [googleSelectedImage, setGoogleSelectedImage] = useState<string>('');
  const [customSelectedImage, setCustomSelectedImage] = useState<string>('');
  const [pageImageRef, setPageImageRef] = useState({
    title: '',
    url: '',
  });
  const location = useLocation();
  const {
    state: { client = {} },
  } = location;
  const { _id: clientId = '', clientName } = client;
  const { addLocationMutation } = useClientLocation(clientId);
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState<ImageType>(
    ImageType.GoogleImage,
  );
  const { handleSubmit, control, getValues } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      title: '',
      pageSubTitle: '',
      city: '',
      countryCode: '',
      introduction: '',
      description: '',
    },
  });

  useEffect(() => {
    onRefresh(client);
  }, []);

  const onSubmit = () => addNewLocation(true);

  const addNewLocation = (next: boolean) => {
    addLocationMutation({
      ...getValues(),
      imageUrl: selectedTab ? customSelectedImage : googleSelectedImage,
      pageImageRef: {
        title: selectedTab ? '' : pageImageRef.title,
        url: selectedTab ? '' : pageImageRef.url,
      },
      dockingPoint: { coordinates },
      clientId,
      clientName,
      next,
    });
  };

  const handleLocationChanged = (photos: Photo[], position: LanLngCoords) => {
    const { lat, lng } = position;
    setCoordinates([lat, lng]);
    setImages(photos);
    setGoogleSelectedImage(
      photos.length
        ? `${photosApiUrl}=${photos[0].photo_reference}&key=${googleApiKey}`
        : '',
    );
    setPageImageRef({
      title: photos[0].pageImageRef?.title || pageImageRef.title,
      url: photos[0].pageImageRef?.url || pageImageRef.url,
    });
  };

  const handleDecision = (toSave: boolean) => {
    toSave && addNewLocation(false);
    navigate(`/clients/${client.clientName}/locations`, {
      replace: true,
      state: {
        client,
      },
    });
  };

  const handleImageRef = (index: number) => {
    setGoogleSelectedImage(
      `${photosApiUrl}=${images[index].photo_reference}&key=${googleApiKey}`,
    );
    setPageImageRef({
      title: images[index].pageImageRef?.title || pageImageRef.title,
      url: images[index].pageImageRef?.url || pageImageRef.url,
    });
  };

  const handleChangeImage = (data: any) => {
    setSocialImage(data);
  };

  return (
    <div style={{ padding: '10px' }}>
      <AddLocationBasicForm control={control} />
      <Box sx={{ flexGrow: 1, mt: '20px' }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <WrapperMap
              coordinate={coordinates}
              onSelectLocation={handleLocationChanged}
            />
          </Grid>
          <Grid item xs={6}>
            <LocationImage
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              customSelectedImage={customSelectedImage}
              setCustomSelectedImage={setCustomSelectedImage}
              setImagesPopupOpen={setImagesPopupOpen}
              disable={!images.length}
              googleSelectedImage={googleSelectedImage}
            />
            <UploadSocialImage
              additionStyle={{ marginTop: '5px' }}
              img={socialImage}
              onChangeImage={handleChangeImage}
            />
          </Grid>
        </Grid>
      </Box>
      <ImagesPopup
        onSelected={handleImageRef}
        open={imagesPopupOpen}
        onClose={() => setImagesPopupOpen(false)}
        images={images}
      />
      <ExitPopup
        open={exitPopupOpen}
        onDecide={handleDecision}
        onClose={() => setExitPopupOpen(false)}
      />
      <div style={{ marginTop: 1 }}>
        <Box component="span" sx={{ p: 2, border: '1px dashed grey' }}>
          <Button onClick={handleSubmit(onSubmit)}>next</Button>
        </Box>
        <Box component="span" sx={{ p: 2, border: '1px dashed grey' }}>
          <Button onClick={() => setExitPopupOpen(true)}>cancel</Button>
        </Box>
      </div>
    </div>
  );
}
