import Box from '@mui/material/Box';
import Step from '@mui/material/Step';
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { styled } from '@mui/material/styles';
import { ReactElement } from 'react';

import LocationStep from '../../Icons/LocationStep';

const steps = [
  'Basic Info',
  'Create Type',
  'General Info',
  'Essential Phrases',
  'Bookings',
];

interface StepperProps {
  activeStep: number;
}

const ConnectorLine = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 16,
  },
  [`& .${stepConnectorClasses.line}`]: {
    border: '1px solid #000000',
    opacity: 0.4,
  },
}));

export default function LocationStepper({
  activeStep,
}: StepperProps): ReactElement {
  return (
    <Box sx={{ minWidth: 400, ml: '125px', mt: 5 }}>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        connector={<ConnectorLine />}
      >
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel
              icon={
                <LocationStep
                  fill={activeStep >= index ? '#0CE1B7' : '#F0F0F0'}
                  index={index + 1}
                />
              }
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
