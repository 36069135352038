import { Grid, makeStyles } from '@material-ui/core';
import Box from '@mui/material/Box';
import { ReactElement, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import { Client } from '../../clients/hooks/useClients';
import BackBtn from '../../common/BackBtn';
import NextBtn from '../../common/NextBtn';
import { Place } from '../../maps/Searchbar';
import WrapperMap from '../../maps/WrapperMap';
import { LanLngCoords, Photo } from '../hooks/useGoogleMaps';
import { usePage } from '../hooks/usePage';
import AddLocationStep3Form from './AddLocationStep3Form';

const useStyles = makeStyles(() => ({
  btnStyleCancle: {
    fontSize: '16px!important' as any,
    lineHeight: '24px!important' as any,
    fontWeight: 'normal!important' as any,
    fontFamily: 'Poppins!important' as any,
    textTransform: 'initial!important' as any,
    background: '#0CE1B7',
    color: '#011531!important' as any,
    borderRadius: '4px!important' as any,
    border: '1px solid #011531!important' as any,
    width: '180px',
    height: '50px',
  },
  btnStyleNext: {
    fontSize: '16px!important' as any,
    lineHeight: '24px!important' as any,
    fontWeight: 'normal!important' as any,
    fontFamily: 'Poppins!important' as any,
    textTransform: 'initial!important' as any,
    background: '#0CE1B7!important' as any,
    borderRadius: '4px!important' as any,
    color: '#011531!important' as any,
    width: '180px',
    height: '50px',
  },
}));

interface EditLocationStep3Props {
  onRefresh: (client: Client) => void;
}

export default function EditLocationStep3({
  onRefresh,
}: EditLocationStep3Props): ReactElement {
  const location = useLocation();
  const {
    state: { client, locationId },
  } = location;
  const {
    page: { generalTips },
    updateGeneralTipsMutation,
  } = usePage(locationId);
  const { clientName } = client;
  const [policeCoords, setPoliceCoords] = useState(
    generalTips?.policeStation?.coordinates,
  );
  const [pharmacyCoords, setPharmacyCoords] = useState(
    generalTips?.pharmacy?.coordinates,
  );
  const [touristCoords, setTouristCoords] = useState(
    generalTips?.touristInfoOffice?.coordinates,
  );
  const { handleSubmit, control, getValues, setValue } = useForm({
    defaultValues: {
      generalTips: {
        emergencyServices: {
          policePhone: generalTips?.emergencyServices?.policePhone || '',
        },
        policeStation: {
          phone: generalTips?.policeStation?.phone || '',
          address: generalTips?.policeStation?.address || '',
        },
        pharmacy: {
          name: generalTips?.pharmacy?.name || '',
          phone: generalTips?.pharmacy?.phone || '',
          address: generalTips?.pharmacy?.address || '',
        },
        touristInfoOffice: {
          phone: generalTips?.touristInfoOffice?.phone || '',
          address: generalTips?.touristInfoOffice?.address || '',
        },
        ambulancePhone: generalTips?.ambulancePhone || '',
        currency: generalTips?.currency || '',
        taxi: {
          name: generalTips?.taxi?.name || '',
          phone: generalTips?.taxi?.phone || '',
        },
        toAvoid: generalTips?.toAvoid || '',
        tipping: generalTips?.toAvoid || '',
        gifts: generalTips?.gifts || '',
        security: generalTips?.security || '',
      },
    },
  });

  useEffect(() => {
    onRefresh(client);
  }, []);

  useEffect(() => {
    setValue(
      'generalTips.emergencyServices.policePhone',
      generalTips?.emergencyServices?.policePhone || '',
    );
    setValue(
      'generalTips.policeStation.phone',
      generalTips?.policeStation?.phone || '',
    );
    setValue(
      'generalTips.policeStation.address',
      generalTips?.policeStation?.address || '',
    );
    setValue(
      'generalTips.emergencyServices.policePhone',
      generalTips?.emergencyServices?.policePhone || '',
    );
    setValue('generalTips.pharmacy.name', generalTips?.pharmacy?.name || '');
    setValue('generalTips.pharmacy.phone', generalTips?.pharmacy?.phone || '');
    setValue(
      'generalTips.pharmacy.address',
      generalTips?.pharmacy?.address || '',
    );
    setValue(
      'generalTips.touristInfoOffice.phone',
      generalTips?.touristInfoOffice?.phone || '',
    );
    setValue(
      'generalTips.touristInfoOffice.address',
      generalTips?.touristInfoOffice?.address || '',
    );
    setValue('generalTips.ambulancePhone', generalTips?.ambulancePhone || '');
    setValue('generalTips.currency', generalTips?.currency || '');
    setValue('generalTips.taxi.name', generalTips?.taxi?.name || '');
    setValue('generalTips.taxi.phone', generalTips?.taxi?.phone || '');
    setValue('generalTips.toAvoid', generalTips?.toAvoid || '');
    setValue('generalTips.tipping', generalTips?.tipping || '');
    setValue('generalTips.gifts', generalTips?.gifts || '');
    setValue('generalTips.security', generalTips?.security || '');
  }, [generalTips]);

  const onSubmit = () => {
    const formValues = getValues();
    updateGeneralTipsMutation({
      locationId,
      generalTips: {
        ...formValues,
        generalTips: {
          ...formValues.generalTips,
          policeStation: {
            ...formValues.generalTips.policeStation,
            coordinates: policeCoords,
          },
          pharmacy: {
            ...formValues.generalTips.pharmacy,
            coordinates: pharmacyCoords,
          },
          touristInfoOffice: {
            ...formValues.generalTips.touristInfoOffice,
            coordinates: touristCoords,
          },
        },
      },
      client,
    });
  };

  const handleNewPoliceLocation = (
    images: Photo[],
    position: LanLngCoords,
    place: Place,
  ) => {
    const { lat, lng } = position;
    setPoliceCoords([lat, lng]);
    setValue('generalTips.policeStation.address', place?.description);
  };

  const handleNewPharmacyLocation = (
    images: Photo[],
    position: LanLngCoords,
    place: Place,
  ) => {
    const { lat, lng } = position;
    setPharmacyCoords([lat, lng]);
    setValue('generalTips.pharmacy.address', place?.description);
  };

  const handleNewTouristLocation = (
    images: Photo[],
    position: LanLngCoords,
    place: Place,
  ) => {
    const { lat, lng } = position;
    setTouristCoords([lat, lng]);
    setValue('generalTips.touristInfoOffice.address', place?.description);
  };

  return (
    <div>
      <AddLocationStep3Form control={control} />
      <Box sx={{ flexGrow: 1, mt: '20px' }}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <div>police address</div>
            <WrapperMap
              coordinate={policeCoords}
              onSelectLocation={handleNewPoliceLocation}
              showLanLng={false}
              locationTxt={generalTips?.policeStation?.address}
            />
          </Grid>
          <Grid item xs={4}>
            <div>pharmacy address</div>
            <WrapperMap
              coordinate={pharmacyCoords}
              onSelectLocation={handleNewPharmacyLocation}
              showLanLng={false}
              locationTxt={generalTips?.pharmacy?.address}
            />
          </Grid>
          <Grid item xs={4}>
            <div>tourist info address</div>
            <WrapperMap
              coordinate={touristCoords}
              onSelectLocation={handleNewTouristLocation}
              showLanLng={false}
              locationTxt={generalTips?.touristInfoOffice?.address}
            />
          </Grid>
        </Grid>
      </Box>
      <Box mt="20px" style={{ textAlign: 'right' }}>
        <BackBtn
          path={`/clients/${clientName}/locations/edit/step1/step2`}
          state={{ client, locationId }}
        />
        <NextBtn onSubmitted={handleSubmit(onSubmit)} />
      </Box>
    </div>
  );
}
