import { Grid, Paper } from '@material-ui/core';
import Box from '@mui/material/Box';
import { ReactElement, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { Client } from '../../clients/hooks/useClients';
import BackBtn from '../../common/BackBtn';
import NextBtn from '../../common/NextBtn';
import TypeDescription from '../../common/TypeDescription';
import AddItemPopup from '../../popups/addItemPopup/AddItemPopup';
import DeleteItemPopup from '../../popups/deleteItemPopup/DeleteItemPopup';
import EditItemPopup from '../../popups/editItemPopup/EditItemPopup';
import CreateTypeSection from './CreateTypeSection';
import { useCreateType } from './hooks/useCreateType';
import TableFooter from './TableFooter';
import Tabsbar from './Tabsbar';
import TypesTable from './TypesTable';

interface CreateTypeLocationProps {
  onRefresh: (client: Client) => void;
}

export default function CreateTypeLocation({
  onRefresh,
}: CreateTypeLocationProps): ReactElement {
  const location = useLocation();
  const {
    state: { client, locationId },
  } = location;
  const { clientName } = client;
  const {
    page,
    addItemPopupOpen,
    setAddItemPopupOpen,
    editItemPopupOpen,
    deleteItemPopupOpen,
    setDeleteItemPopupOpen,
    editItemPopupProps,
    tabIndex,
    handleNewType,
    handleTabSelected,
    handleSubmit,
    handleEditItem,
    handleDecition,
    handleDeleteItem,
    saveEditedItem,
    saveNewItem,
    handleCancelEdit,
    handleDescriptionChange,
    handleItemPublishMode,
  } = useCreateType(locationId, client);

  useEffect(() => {
    onRefresh(client);
  }, []);

  return (
    <div
      style={{ marginTop: '60px', paddingLeft: '50px', paddingRight: '50px' }}
    >
      <CreateTypeSection
        withTypes={!!page?.types?.length}
        handleNewType={handleNewType}
      />
      {!!page?.types?.length && (
        <>
          <div>
            <Grid container>
              <Grid>
                <Tabsbar
                  onTabSelected={handleTabSelected}
                  types={page?.types}
                />
              </Grid>
            </Grid>
          </div>
          <Paper>
            {tabIndex !== -1 && (
              <div style={{ padding: '10px 30px' }}>
                <TypeDescription
                  onDescriptionChange={handleDescriptionChange}
                  type={page?.types[tabIndex]}
                />
              </div>
            )}
            {tabIndex !== -1 && !!page?.types[tabIndex]?.items.length && (
              <TypesTable
                onEditItem={handleEditItem}
                onDeleteItem={handleDeleteItem}
                onChangePublishMode={handleItemPublishMode}
                items={page?.types[tabIndex]?.items}
              />
            )}
            <TableFooter setAddItemPopupOpen={setAddItemPopupOpen} />
          </Paper>
        </>
      )}
      <AddItemPopup
        open={addItemPopupOpen}
        onAddingItem={saveNewItem}
        onClose={() => setAddItemPopupOpen(false)}
      />
      {editItemPopupProps && (
        <EditItemPopup
          item={editItemPopupProps}
          open={editItemPopupOpen}
          onEditItem={saveEditedItem}
          onClose={handleCancelEdit}
        />
      )}
      <DeleteItemPopup
        open={deleteItemPopupOpen}
        onClose={() => setDeleteItemPopupOpen(false)}
        toDeleteItem={handleDecition}
      />
      <Box mt="20px" style={{ textAlign: 'right' }}>
        <BackBtn
          path={`/clients/${clientName}/locations/edit/step1`}
          state={{ client, locationId }}
        />
        <NextBtn onSubmitted={handleSubmit} />
      </Box>
    </div>
  );
}
