import { axiosInstance } from '../../../axiosInstance';
import { Type } from '../../../interfaces/Type';

export async function fetchPage(pageId: string) {
  const { data } = await axiosInstance.get(`/test/pages/${pageId}`);
  return data;
}

export async function addTypesAndItems(details: {
  pageId: string;
  types: Type[];
}) {
  const { pageId } = details;
  const { data } = await axiosInstance.post(`/test/types-and-items/${pageId}`, {
    ...details,
  });
  return data;
}
