import React from 'react';

function Logout() {
  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="21"
      fill="none"
      viewBox="0 0 22 21"
    >
      <path
        stroke="#011531"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M8.272 6.615c.273-3.15 1.9-4.436 5.465-4.436h.114c3.934 0 5.509 1.566 5.509 5.477v5.705c0 3.912-1.575 5.478-5.509 5.478h-.114c-3.538 0-5.166-1.269-5.456-4.366M13.64 10.5H3.626M5.588 7.569L2.64 10.5l2.948 2.931"
      />
    </svg>
  );
}

export default Logout;
