import { makeStyles } from '@material-ui/core/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { ReactElement } from 'react';

import { useClient } from '../clients/hooks/useClients';

const useStyles = makeStyles(() => ({
  input: {
    display: 'none',
  },
  btnStyleUpload: {
    fontSize: '16px!important' as any,
    lineHeight: '24px!important' as any,
    fontWeight: 'lighter!important' as any,
    fontFamily: 'Poppins!important' as any,
    textTransform: 'initial!important' as any,
    background: '#0CE1B7!important' as any,
    borderRadius: '4px!important' as any,
    marginTop: '17px!important' as any,
    color: '#011531!important' as any,
    width: '160px',
    height: '40px',
  },
}));

interface UploadProps {
  onChangeImage: (pathToS3: string) => void;
}

export default function UploadFile({
  onChangeImage,
}: UploadProps): ReactElement {
  const classes = useStyles();
  const { uploadFile } = useClient();

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const fileLoaded = URL.createObjectURL(event.target.files[0]);
      const data = await uploadFile(event?.target.files[0]);
      onChangeImage(data?.pathToS3);
    }
  };

  return (
    <Box>
      <div>
        <input
          accept="image/*"
          className={classes.input}
          id="id"
          onChange={(event) => handleChange(event)}
          type="file"
        />
        <label htmlFor="id">
          <Button
            className={classes.btnStyleUpload}
            variant="contained"
            component="span"
          >
            Choose File{' '}
            <Box
              component="img"
              ml="5px"
              mb="2px"
              sx={{
                height: 13,
                width: 11,
              }}
              alt="The house from the offer."
              src="/Upload.png"
            />
          </Button>
        </label>
      </div>
    </Box>
  );
}
