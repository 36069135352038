import Button from '@mui/material/Button';
import { ReactElement } from 'react';

import PlusIcon from '../../../Icons/PlusIcon';
import UpDownArrow from '../../../Icons/UpDownArrow';
import { useStyles } from './CreateTypeStyle';

interface TableFooterProps {
  setAddItemPopupOpen: (value: React.SetStateAction<boolean>) => void;
}

export default function TableFooter({
  setAddItemPopupOpen,
}: TableFooterProps): ReactElement {
  const classes = useStyles();

  return (
    <div
      style={{
        margin: ' 0 30px 30px 30px',
        paddingBottom: '30px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div>
          <UpDownArrow />
        </div>
        <div>Change type order :</div>
        <div
          style={{
            border: '0.5px solid rgba(1, 21, 49, 0.4)',
            borderRadius: '4px',
            padding: '10px',
          }}
        >
          1
        </div>
      </div>
      <div className={classes.btnDivStyle}>
        <Button
          onClick={() => setAddItemPopupOpen(true)}
          variant="outlined"
          className={classes.btnStyleNext}
        >
          <span style={{ marginRight: '6px', display: 'flex' }}>
            <PlusIcon />
          </span>
          Add Item
        </Button>
      </div>
    </div>
  );
}
