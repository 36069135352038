import { makeStyles } from '@material-ui/core';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';

import { Client } from '../clients/hooks/useClients';

const useStyles = makeStyles(() => ({
  btnStyleCancle: {
    fontSize: '16px!important' as '16px',
    lineHeight: '24px!important' as '24px',
    fontWeight: 'normal!important' as 'normal',
    fontFamily: 'Poppins!important' as 'Poppins',
    textTransform: 'initial!important' as 'initial',
    background: '#0CE1B7',
    color: '#011531!important' as '#011531',
    borderRadius: '4px!important' as '4px',
    border: '1px solid #011531!important' as '1px',
    width: '180px',
    height: '50px',
  },
}));

interface BackBtnProps {
  path: string;
  state: {
    client: Client;
    locationId: string;
  };
}

export default function BackBtn({ path, state }: BackBtnProps): ReactElement {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Box component="span">
      <Button
        className={classes.btnStyleCancle}
        onClick={() =>
          navigate(path, {
            state,
          })
        }
      >
        Back
      </Button>
    </Box>
  );
}
