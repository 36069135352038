import React from 'react';

function Close() {
  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="15"
      fill="none"
      viewBox="0 0 16 15"
    >
      <path
        stroke="#011531"
        strokeLinecap="round"
        d="M0.5 -0.5L19.574 -0.5"
        transform="scale(1.02825 .97093) rotate(45 -1.318 1.102)"
      />
      <path
        stroke="#011531"
        strokeLinecap="round"
        d="M0.5 -0.5L19.574 -0.5"
        transform="matrix(-.72708 .68655 -.72708 -.68655 14.595 0)"
      />
    </svg>
  );
}
export default Close;
