import { makeStyles, TableHead } from '@material-ui/core';
import Fab from '@mui/material/Fab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import * as React from 'react';

import DeleteIcons from '../../../Icons/DeleteIcons';
import EditPencilGreen from '../../../Icons/EditPencilGreen';
import { Book } from '../../../interfaces/Book';

interface Column {
  id: 'title' | 'tripLentgh' | 'redirectionUrl';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
  onClick?: () => void;
}

const columns: readonly Column[] = [
  { id: 'title', label: 'Title', minWidth: 170 },
  { id: 'tripLentgh', label: 'Trip length', minWidth: 100 },
  {
    id: 'redirectionUrl',
    label: 'Redirection',
    minWidth: 170,
  },
];

interface BookingTableProp {
  bookings: Book[];
  onEditItem: (index: number) => void;
  onDeleteBooking: (index: number) => void;
}

const useStyles = makeStyles(() => ({
  tableRow: {
    background: '#FFFFFF',
    borderSpacing: '0px 15px',
  },
  tableHead: {
    borderSpacing: '0px 5px!important' as '0px 5px',
    background: '#F0F0F0',
  },

  titleDiv: {
    fontSize: '14px!important' as '14px',
    lineHeight: '21px!important' as '21px',
    fontWeight: 400,
    fontFamily: 'Poppins!important' as 'Poppins',
    fontStyle: 'normal!important' as 'normal',
    color: '#011531!important' as '#011531',
  },
  titleDivDesc: {
    fontSize: '12px!important' as '14px',
    lineHeight: '18px!important' as '21px',
    fontWeight: 400,
    fontFamily: 'Poppins!important' as 'Poppins',
    fontStyle: 'normal!important' as 'normal',
    color: '#011531!important' as '#011531',
    opacity: '0.6',
  },
  fabStyle: {
    boxShadow: 'none!important' as 'none',
    background: 'none!important' as 'none',
  },
  headerTitle: {
    fontSize: '14px!important' as '14px',
    lineHeight: '21px!important' as '21px',
    fontWeight: 400,
    fontFamily: 'Poppins!important' as 'Poppins',
    fontStyle: 'normal!important' as 'normal',
    opacity: 0.6,
  },
  tableStyle: {
    border: '1px solid #ddd',
    '& th': {
      backgroundColor: '#f5f5f5',
      fontWeight: 'bold',
      padding: '10px',
    },
    '& td': {
      padding: '10px',
    },
  },
}));

export default function BookingTable({
  bookings,
  onEditItem,
  onDeleteBooking,
}: BookingTableProp) {
  const classes = useStyles();
  const rows = bookings.map(({ title, tripLentgh, redirectionUrl }: Book) => ({
    title,
    tripLentgh,
    redirectionUrl,
  }));

  return (
    <TableContainer sx={{ maxHeight: 440 }}>
      <Table
        className={classes.tableStyle}
        stickyHeader
        aria-label="sticky table"
      >
        <TableHead className={classes.tableHead}>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                className={classes.headerTitle}
                align="center"
                style={{ minWidth: column.minWidth }}
              >
                {column.label}
              </TableCell>
            ))}

            <TableCell
              className={classes.headerTitle}
              key="action"
              align="center"
              style={{ minWidth: 170 }}
            >
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => {
            return (
              <TableRow hover role="checkbox" tabIndex={-1} key={row.title}>
                {columns.map((column) => {
                  const value = row[column.id];
                  return (
                    <TableCell
                      className={
                        column.label === 'Title'
                          ? classes.titleDiv
                          : classes.titleDivDesc
                      }
                      key={column.id}
                      align="center"
                    >
                      {value}
                    </TableCell>
                  );
                })}

                <TableCell align="center">
                  <Fab
                    className={classes.fabStyle}
                    onClick={() => onEditItem(index)}
                    size="small"
                    aria-label="edit"
                  >
                    <EditPencilGreen />
                  </Fab>

                  <Fab
                    onClick={() => onDeleteBooking(index)}
                    size="small"
                    className={classes.fabStyle}
                    aria-label="edit"
                  >
                    <DeleteIcons />
                  </Fab>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
