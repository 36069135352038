import { makeStyles } from '@material-ui/core';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { ReactElement } from 'react';

const useStyles = makeStyles(() => ({
  btnStyleCancle: {
    fontSize: '16px!important' as any,
    lineHeight: '24px!important' as any,
    fontWeight: 'normal!important' as any,
    fontFamily: 'Poppins!important' as any,
    textTransform: 'initial!important' as any,
    background: '#0CE1B7',
    color: '#011531!important' as any,
    borderRadius: '4px!important' as any,
    border: '1px solid #011531!important' as any,
    width: '180px',
    height: '50px',
  },
  btnStyleNext: {
    fontSize: '16px!important' as any,
    lineHeight: '24px!important' as any,
    fontWeight: 'normal!important' as any,
    fontFamily: 'Poppins!important' as any,
    textTransform: 'initial!important' as any,
    background: '#0CE1B7!important' as any,
    borderRadius: '4px!important' as any,
    color: '#011531!important' as any,
    width: '180px',
    height: '50px',
  },
}));

interface EditLocationBasicProps {
  onCancelClick: () => void;
  onNextClicked: () => void;
}

export default function EditLocationBasic({
  onCancelClick,
  onNextClicked,
}: EditLocationBasicProps): ReactElement {
  const classes = useStyles();

  return (
    <Box mt="20px" style={{ textAlign: 'right' }}>
      <Box component="span">
        <Button
          className={classes.btnStyleCancle}
          onClick={() => onCancelClick()}
        >
          Cancel
        </Button>
      </Box>
      <Box ml="20px" component="span">
        <Button
          className={classes.btnStyleNext}
          onClick={() => onNextClicked()}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
}
