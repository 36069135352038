import {
  Control,
  UseFormGetValues,
  UseFormHandleSubmit,
} from 'react-hook-form';

import { Page } from '../../../../interfaces/Page';
import { LanLngCoords, Photo } from '../../hooks/useGoogleMaps';

export enum ImageType {
  GoogleImage,
  SimpleImage,
}

export interface UseBasicLocation {
  page: Page;
  editLocationMutation: any;
  googleSelectedImage: string;
  selectedTab: ImageType;
  setSelectedTab: React.Dispatch<React.SetStateAction<ImageType>>;
  pageImageRef: {
    title: string;
    url: string;
  };
  images: Photo[];
  imagesPopupOpen: boolean;
  exitPopupOpen: boolean;
  customSelectedImage: string;
  setImagesPopupOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setExitPopupOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setCustomSelectedImage: React.Dispatch<React.SetStateAction<string>>;
  coordinates: number[];
  handleImageRef: (index: number) => void;
  handleLocationChanged: (photos: Photo[], position: LanLngCoords) => void;
  handleSubmit: UseFormHandleSubmit<{
    title: string;
    pageSubTitle: string;
    city: string;
    countryCode: string;
    introduction: string;
    description: string;
  }>;
  control: Control<
    {
      title: string;
      pageSubTitle: string;
      city: string;
      countryCode: string;
      introduction: string;
      description: string;
    },
    any
  >;
  getValues: UseFormGetValues<{
    title: string;
    pageSubTitle: string;
    city: string;
    countryCode: string;
    introduction: string;
    description: string;
  }>;
  handleDecision: (toSave: boolean, client: any) => void;
  onSubmit: () => void;
}
