import React from 'react';

function OutdoorIcon({ fill }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill={fill}
    >
      <path
        d="M12.7292 10.2083L10.6458 9.16666L14.8125 5L18.9792 9.16666L16.8958 10.2083L21.0625 14.375L17.9375 15.4167L22.625 20.625H7L11.1667 15.4167L8.5625 14.375L12.7292 10.2083Z"
        stroke="#011531"
        strokeOpacity="0.4"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.4587 25.832H11.167"
        stroke="#011531"
        strokeOpacity="0.4"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.2503 20.625L12.7295 25.8334"
        stroke="#011531"
        strokeOpacity="0.4"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.375 20.625L16.8958 25.8334"
        stroke="#011531"
        strokeOpacity="0.4"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default OutdoorIcon;
