/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/require-default-props */
/* eslint-disable no-console */
/* eslint-disable no-new */
import { isLatLngLiteral } from '@googlemaps/typescript-guards';
import { createCustomEqual, deepEqual } from 'fast-equals';
import {
  Children,
  cloneElement,
  EffectCallback,
  isValidElement,
  useEffect,
  useRef,
  useState,
} from 'react';

interface MapProps extends google.maps.MapOptions {
  onClick?: (e: google.maps.MapMouseEvent) => void;
  onIdle?: (map: google.maps.Map) => void;
  // center: google.maps.LatLngLiteral;
  // zoom: number;
  children?: React.ReactNode;
}

export default function Map({
  onClick,
  onIdle,
  // center,
  // zoom,
  children,
  ...options
}: MapProps) {
  const ref = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<google.maps.Map>();

  useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, {}));
      // new window.google.maps.Map(ref.current, {
      //   center,
      //   zoom,
      // });
    }
  }, [ref, map]);

  useEffect(() => {
    if (map) {
      ['idle'].forEach((eventName) =>
        google.maps.event.clearListeners(map, eventName),
      );

      if (onIdle) {
        map.addListener('idle', () => onIdle(map));
      }
    }
  }, [map, onIdle]);

  useDeepCompareEffectForMaps(() => {
    if (map) {
      map.setOptions(options);
    }
  }, [map, options]);

  return (
    <>
      <div ref={ref} style={{ width: '100%', height: '100%' }} />
      {Children.map(children, (child) => {
        if (isValidElement(child)) {
          // @ts-ignore
          return cloneElement(child, { map });
        }
      })}
    </>
  );
}

const deepCompareEqualsForMaps = createCustomEqual(() => ({
  areObjectsEqual: (a, b) => {
    if (
      isLatLngLiteral(a) ||
      a instanceof google.maps.LatLng ||
      isLatLngLiteral(b) ||
      b instanceof google.maps.LatLng
    ) {
      return new google.maps.LatLng(a).equals(new google.maps.LatLng(b));
    }

    return deepEqual(a, b);
  },
}));

function useDeepCompareMemoize(value: any) {
  const ref = useRef();

  if (!deepCompareEqualsForMaps(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}

function useDeepCompareEffectForMaps(
  callback: React.EffectCallback,
  dependencies: any[],
) {
  useEffect(callback, dependencies.map(useDeepCompareMemoize));
}
