import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { ReactElement, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Client } from '../../clients/hooks/useClients';
import LocationImage from '../../common/LocationImage';
import UploadSocialImage from '../../common/UploadSocialImage';
import WrapperMap from '../../maps/WrapperMap';
import ExitPopup from '../../popups/exitPopup/ExitPopup';
import ImagesPopup from '../../popups/imagesPopup/ImagesPopup';
import AddLocationBasicForm from './AddLocationBasicForm';
import ButtonsNavigation from './ButtonsNavigation';
import { useBasicLocation } from './hooks/useBasicLocation';

interface EditLocationBasicProps {
  onRefresh: (client: Client) => void;
}

export default function EditLocationBasic({
  onRefresh,
}: EditLocationBasicProps): ReactElement {
  const [socialImage, setSocialImage] = useState<string | undefined>('');
  const location = useLocation();
  const {
    state: { client, locationId },
  } = location;
  const {
    imagesPopupOpen,
    setImagesPopupOpen,
    exitPopupOpen,
    setExitPopupOpen,
    customSelectedImage,
    setCustomSelectedImage,
    selectedTab,
    setSelectedTab,
    googleSelectedImage,
    handleImageRef,
    coordinates,
    images,
    handleLocationChanged,
    handleSubmit,
    control,
    handleDecision,
    onSubmit,
  } = useBasicLocation(locationId, client);

  useEffect(() => {
    onRefresh(client);
  }, []);

  return (
    <div style={{ maxWidth: 'fit-content' }}>
      <AddLocationBasicForm control={control} />
      <Box sx={{ mt: '20px' }}>
        <Grid
          container
          spacing={5}
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid item xs={6}>
            <WrapperMap
              coordinate={coordinates}
              onSelectLocation={handleLocationChanged}
            />
          </Grid>
          <Grid item xs={6}>
            <div>location images: </div>
            <LocationImage
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              customSelectedImage={customSelectedImage}
              setCustomSelectedImage={setCustomSelectedImage}
              setImagesPopupOpen={setImagesPopupOpen}
              disable={!images.length}
              googleSelectedImage={googleSelectedImage}
            />
            <UploadSocialImage
              additionStyle={{ marginTop: 52, maxWidth: '500px' }}
              img={socialImage}
              onChangeImage={(img) => setSocialImage(img)}
            />
          </Grid>
        </Grid>
      </Box>
      <ImagesPopup
        onSelected={handleImageRef}
        open={imagesPopupOpen}
        onClose={() => setImagesPopupOpen(false)}
        images={images}
      />
      <ExitPopup
        open={exitPopupOpen}
        onDecide={(toSave: boolean) => handleDecision(toSave, client)}
        onClose={() => setExitPopupOpen(false)}
      />
      <ButtonsNavigation
        onCancelClick={() => setExitPopupOpen(true)}
        onNextClicked={handleSubmit(onSubmit)}
      />
    </div>
  );
}
