import React from 'react';

export function Cloud() {
  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <svg
      width="21"
      height="14"
      viewBox="0 0 21 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.2835 13.4999H4.25607C1.90934 13.4999 0 11.5487 0 9.15049C0 7.02583 1.51658 5.2261 3.52631 4.8693C4.33601 2.02312 6.93602 0 9.86722 0C11.6484 0 13.3534 0.741834 14.5958 2.04588C14.8296 2.015 15.0565 2.00008 15.283 2.00008C18.3853 2.00008 20.9091 4.57937 20.9091 7.75004C20.9089 10.9209 18.3851 13.5 15.283 13.5L15.2835 13.4999ZM9.86764 1.23126C7.3524 1.23126 5.13565 3.05253 4.59609 5.56179C4.5389 5.82911 4.31565 6.02556 4.04838 6.044C2.45352 6.15382 1.20485 7.51859 1.20485 9.15106C1.20485 10.8706 2.57379 12.2697 4.25623 12.2697H15.2837C17.7219 12.2697 19.7052 10.2429 19.7052 7.75105C19.7052 5.25919 17.7219 3.23236 15.2837 3.23236C15.0255 3.23236 14.7629 3.25826 14.4811 3.3111C14.2712 3.34944 14.0599 3.27422 13.9204 3.11238C12.8917 1.9165 11.4146 1.23121 9.8679 1.23121L9.86764 1.23126Z"
        fill="#011531"
      />
      <path
        d="M9.03187 10.8C8.89949 10.8 8.76711 10.7468 8.66608 10.6403L7.42449 9.33119C7.22244 9.11816 7.22244 8.77308 7.42449 8.55987C7.62654 8.34684 7.95383 8.34684 8.15605 8.55987L9.03204 9.48347L12.7535 5.5598C12.9555 5.34677 13.2828 5.34677 13.485 5.5598C13.6871 5.77283 13.6871 6.1179 13.485 6.33111L9.39729 10.6403C9.29644 10.7469 9.16405 10.8 9.03185 10.8H9.03187Z"
        fill="#011531"
      />
    </svg>
  );
}
