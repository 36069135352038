/* eslint-disable react/jsx-props-no-spreading */
import { makeStyles } from '@material-ui/core';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { ReactElement, SyntheticEvent, useState } from 'react';

import CoffeeIcon from '../../../Icons/CoffeeIcon';
import CultureIcon from '../../../Icons/CultureIcon';
import DrinksIcon from '../../../Icons/DrinksIcon';
import LunchIcon from '../../../Icons/LunchIcon';
import OutdoorIcon from '../../../Icons/OutdoorIcon';
import ShoppingIcon from '../../../Icons/ShoppingIcon';
import { Type } from '../../../interfaces/Type';

const useStyles = makeStyles(() => ({
  tabStyle: {
    display: 'flex!important' as 'flex',
    flexDirection: 'initial!important' as 'initial',
    alignItems: 'center!important' as 'center',
  },
}));

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface TabsbarProps {
  types: Type[];
  onTabSelected: (typeTitle?: string) => void;
}

export default function Tabsbar({
  types,
  onTabSelected,
}: TabsbarProps): ReactElement {
  const [tab, setTab] = useState(0);
  const classes = useStyles();
  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  const handleAddIcon = (type: string) => {
    switch (type) {
      case 'Coffee':
        return <CoffeeIcon fill={tab === 0 ? '#0CE1B7' : 'none'} />;
      case 'Drinks':
        return <DrinksIcon fill={tab === 1 ? '#0CE1B7' : 'none'} />;
      case 'Lunch':
        return <LunchIcon fill={tab === 2 ? '#0CE1B7' : 'none'} />;
      case 'Shopping':
        return <ShoppingIcon fill={tab === 3 ? '#0CE1B7' : 'none'} />;
      case 'Outdoor':
        return <OutdoorIcon fill={tab === 4 ? '#0CE1B7' : 'none'} />;
      case 'Culture':
        return <CultureIcon fill={tab === 5 ? '#0CE1B7' : 'none'} />;
      default:
        return '';
    }
  };

  return (
    <Box sx={{ maxWidth: { xs: 320, sm: 780 }, bgcolor: 'background.paper' }}>
      <Tabs
        value={tab}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
      >
        {types.map((type, index) => (
          <Tab
            icon={type.typeTitle && handleAddIcon(type.typeTitle)}
            key={type.typeTitle}
            sx={{
              fontSize: '16px',
              lineHeight: '24px',
              fontWeight: 500,
              fontFamily: 'Poppins',
              color: '#011531!important',
              opacity: '0.5',
              '&.Mui-selected': {
                color: '#0CE1B7!important',
              },
              '&.MuiTabs-indicator': {
                backgroundColor: '#0CE1B7!important',
              },
            }}
            className={classes.tabStyle}
            label={type.typeTitle}
            {...a11yProps(index)}
            onClick={() => onTabSelected(type?.typeTitle)}
          />
        ))}
      </Tabs>
    </Box>
  );
}
