import {
  Grid,
  Input,
  InputAdornment,
  makeStyles,
  styled,
  Table,
  TableHead,
  withStyles,
} from '@material-ui/core';
import {
  Box,
  Button,
  Fab,
  Paper,
  Tab,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tabs,
} from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Download from '../../../Icons/Download';
import EditPencil from '../../../Icons/EditPencil';
import { Search } from '../../../Icons/Search';
import SelectSort from '../../common/SelectSort';
import { Location } from '../hooks/useClientLocations';

const StyledTable = withStyles(() => ({
  root: {
    padding: '0px 20px !important',
  },
}))(Table);

const StyledTableHead = withStyles(() => ({
  root: {
    backgroundColor: '#F9F9F9 !important',
  },
}))(TableHead);

const AntTabs = styled(Tabs)({
  '& .MuiTabs-indicator': {
    backgroundColor: '#0CE1B7',
  },
});
const AntTab = styled(Tab)({
  fontWeight: 500,
  fontSize: '18px !important',
  fontFamily: 'Poppins !important',
  '&.Mui-selected': {
    color: '#0CE1B7 !important',
  },
});

interface LocationTableProp {
  locations: Location[];
  client: {
    clientId: string;
    clientName: string;
  };
  onChangePublishMode: (
    id: string,
    published: boolean,
    locationTitle: string,
  ) => void;
}

export enum SortType {
  none,
  fromNewestToOldest,
  fromOldestToNewest,
  alphabetical,
}

const useStyles = makeStyles(() => ({
  searchBoxContainer: {
    textAlign: 'start',
  },
  tablePadding: {
    padding: '0px 20px !important',
  },
  searchBox: {
    width: '100%',
    background: '#FFFFFF',
    border: ' 0.5px solid rgba(1, 21, 49, 0.4)',
    borderRadius: '4px',
    padding: '13px 22px',
  },
  downloadButton: {
    borderRadius: '0px !important',
    borderBottom: '1px solid black !important',
    fontFamily: 'Poppins',
    color: '#011531 !important',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
  },
  actionButton: {
    background: 'rgba(12, 225, 183, 0.6) !important',
    borderRadius: '8px !important',
    boxShadow: 'none !important',
  },
  mt15: {
    marginTop: '15px',
  },
  ml10: {
    marginLeft: '10px',
  },
  MuiSelected: {
    color: 'red !important',
  },
  locationTitle: {
    color: '#011531 !important',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    textTransform: 'capitalize',
  },
  lastUpdated: {
    opacity: '0.4',
  },
  statusSuccess: {
    color: '#07B44C !important',
    textTransform: 'capitalize',
  },
  statusUnpublished: {
    color: '#B48407 !important',
    textTransform: 'capitalize',
  },
}));

export default function LocationTable({
  locations,
  client,
  onChangePublishMode,
}: LocationTableProp) {
  const [tab, setTab] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };
  const navigate = useNavigate();
  const [sortType, setSortType] = useState('');
  const [searchText, setSearchText] = useState('');

  const rows = locations.map(
    ({ title, updatedAt, published, pageGuideURL, _id: id }) => ({
      title,
      updated: updatedAt || '',
      published,
      pageGuideURL: pageGuideURL || '',
      id,
    }),
  );

  const handleSort = (a: any, b: any) => {
    if (sortType === 'alphabetical') {
      return a.title.localeCompare(b.title);
    }
    if (sortType === 'fromNewestToOldest') {
      return b.updated.localeCompare(a.updated);
    }
    if (sortType === 'fromOldestToNewest') {
      return a.updated.localeCompare(b.updated);
    }
    return 0;
  };

  const classes = useStyles();

  return (
    <>
      <Grid className={classes.mt15} container spacing={2}>
        <Grid item xs={8} className={classes.searchBoxContainer}>
          <Input
            className={classes.searchBox}
            startAdornment={
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            }
            disableUnderline
            placeholder="Search"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setSearchText(event.target.value)
            }
            value={searchText}
          />
        </Grid>
        <Grid item xs={4}>
          <SelectSort
            sortType={sortType}
            onChange={(type: string) => setSortType(SortType[Number(type)])}
          />
        </Grid>
      </Grid>
      <Box sx={{ width: '100%', justifyContent: 'start', mt: 6 }}>
        <AntTabs
          indicatorColor="secondary"
          value={tab}
          onChange={handleChange}
          aria-label="secondary tabs"
        >
          <AntTab label="active content" />
          <AntTab label="unpublished content" />
        </AntTabs>
      </Box>

      <Paper sx={{ marginTop: '50px', boxShadow: 'none' }}>
        <TableContainer>
          <StyledTable aria-label="sticky table">
            <StyledTableHead className={classes.tablePadding}>
              <TableRow>
                <TableCell key="name" align="left">
                  Location Name
                </TableCell>
                <TableCell key="update" align="center">
                  Last Update
                </TableCell>
                <TableCell key="status" align="center">
                  Status
                </TableCell>
                <TableCell key="guide" align="center">
                  Offline Guides
                </TableCell>
                <TableCell key="action" align="center">
                  Action
                </TableCell>
              </TableRow>
            </StyledTableHead>
            <TableBody className={classes.tablePadding}>
              {rows
                .filter(({ title }) => title.toLowerCase().includes(searchText))
                .filter(({ published }) => (tab ? !published : published))
                .sort(handleSort)
                .map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      <TableCell key={row.title} align="left">
                        <div className={classes.locationTitle}>{row.title}</div>
                      </TableCell>
                      <TableCell key={row.updated} align="center">
                        <div
                          className={`${classes.lastUpdated} ${classes.locationTitle}`}
                        >
                          {row.updated.slice(0, 10)}
                        </div>
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          className={`${
                            tab
                              ? classes.statusUnpublished
                              : classes.statusSuccess
                          } ${classes.locationTitle}`}
                          onClick={() =>
                            onChangePublishMode(
                              row.id,
                              row.published,
                              row.title,
                            )
                          }
                        >
                          {tab ? 'unpublished' : 'active'}
                        </Button>
                      </TableCell>
                      <TableCell align="center">
                        {row.pageGuideURL && (
                          <Button
                            className={classes.downloadButton}
                            href={row?.pageGuideURL}
                            startIcon={<Download />}
                          >
                            PDF
                          </Button>
                        )}
                      </TableCell>
                      <TableCell align="center">
                        <Fab
                          className={classes.actionButton}
                          size="small"
                          aria-label="edit"
                          onClick={() =>
                            navigate('edit/step1', {
                              state: {
                                client,
                                locationId: row.id,
                                locationName: row.title,
                              },
                            })
                          }
                        >
                          <EditPencil />
                        </Fab>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </StyledTable>
        </TableContainer>
        {/* <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
      </Paper>
    </>
  );
}
