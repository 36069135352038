/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-return-assign */
/* eslint-disable no-console */
import { UseMutateFunction, useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { axiosInstance } from '../../../axiosInstance';
import { queryKeys } from '../../../react-query/consts';
import { queryClient } from '../../../react-query/queryClient';

export interface Client {
  _id: string;
  clientName: string;
  clientLogoURL?: string;
}

export interface UploadResponse {
  pathToS3: string;
  status: number;
}

async function getClients(): Promise<Client[]> {
  const { data } = await axiosInstance.get('/test/clients');
  return data;
}

export async function addClient(client: {
  clientName: string;
  domains: {
    guidesDomain: string;
    clientDomain: string;
    clientMeta: string;
    bannerDomain: string;
  };
  clientLogoURL: string | undefined;
  mainShorexImg: string | undefined;
}) {
  const { data } = await axiosInstance.post('/test/clients/create', client);
  return data;
}

async function uploadFile(file: File): Promise<UploadResponse> {
  const formData = new FormData();
  formData.append('file', file);
  const {
    data: { pathToS3 = '' },
    status,
  } = await axiosInstance({
    url: 'test/upload-file',
    method: 'POST',
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  console.log('pathToS3: ', pathToS3);
  console.log('status: ', status);

  return {
    pathToS3,
    status,
  };
}

export async function updateClient(client: {
  clientName: string;
  domains: {
    guidesDomain: string;
    clientDomain: string;
    clientMeta: string;
    bannerDomain: string;
  };
  clientLogoURL: string | undefined;
  mainShorexImg: string | undefined;
  clientId: string;
}) {
  const { clientId, ...rest } = client;
  const { data } = await axiosInstance.post(
    `/test/clients/update-client/${clientId}`,
    rest,
  );
  return data;
}

interface UseClient {
  clients: Client[];
  initialClient: Client;
  uploadFile: (file: File) => Promise<UploadResponse>;
  addClientMutation: any;
  updateClientMutation: any;
  setSelectedClient: (index: number) => Client | undefined;
}

const setSelectedClient = (index?: number) => {
  const clients: Client[] | undefined = queryClient.getQueryData(['clients']);
  const client = clients && clients[index || 0];
  console.log(client);
  return client;
};

export function useClient(): UseClient {
  const navigate = useNavigate();
  const { data: clients = [] } = useQuery(queryKeys.clients, getClients);
  const [initialClient] = clients;
  const { mutate: addClientMutation } = useMutation(addClient, {
    onSettled: () => {
      queryClient.invalidateQueries(queryKeys.clients);
      navigate('/clients');
    },
  });

  const { mutate: updateClientMutation } = useMutation(updateClient, {
    onSettled: () => {
      queryClient.invalidateQueries(queryKeys.clients);
      navigate('/clients');
    },
  });

  return {
    clients,
    initialClient,
    uploadFile,
    addClientMutation,
    setSelectedClient,
    updateClientMutation,
  };
}
