import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import * as React from 'react';

interface ChangePublishModePopupProps {
  locationStatus: boolean;
  locationTitle: string;
  open: boolean;
  onSave: (toSave: boolean) => void;
}

export default function ChangePublishModePopup({
  locationStatus,
  locationTitle,
  open,
  onSave,
}: ChangePublishModePopupProps) {
  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {locationStatus ? 'active' : 'non-active'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`are u sure you want to move ${locationTitle} to active mode`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onSave(true)}>yes</Button>
          <Button onClick={() => onSave(false)}>no</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
