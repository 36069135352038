import { InputLabel, makeStyles } from '@material-ui/core';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { ReactElement } from 'react';
import { Control, Controller } from 'react-hook-form';

interface BasicFormProps {
  control: Control<{
    title: string;
    pageSubTitle: string;
    city: string;
    countryCode: string;
    introduction: string;
    description: string;
  }>;
}

const useStyles = makeStyles(() => ({
  label: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 'lighter',
    fontFamily: 'Poppins',
    textAlign: 'left',
  },
}));

export default function AddLocationBasicForm({
  control,
}: BasicFormProps): ReactElement {
  const classes = useStyles();
  return (
    <Box sx={{ mt: 5 }}>
      <Grid container spacing={1} rowSpacing={2}>
        <Grid item xs={3}>
          <InputLabel className={classes.label} shrink>
            *Item title :
          </InputLabel>
          <Controller
            name="title"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField fullWidth onChange={onChange} value={value} />
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <InputLabel className={classes.label} shrink>
            *Subtitle :
          </InputLabel>
          <Controller
            name="pageSubTitle"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField fullWidth onChange={onChange} value={value} />
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <InputLabel className={classes.label} shrink>
            *City :
          </InputLabel>
          <Controller
            name="city"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField fullWidth onChange={onChange} value={value} />
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <InputLabel className={classes.label} shrink>
            Country Code :
          </InputLabel>
          <Controller
            name="countryCode"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField fullWidth onChange={onChange} value={value} />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <InputLabel className={classes.label} shrink>
            *Introduction :
          </InputLabel>
          <Controller
            name="introduction"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                fullWidth
                multiline
                rows={4}
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <InputLabel className={classes.label} shrink>
            *Description:
          </InputLabel>
          <Controller
            name="description"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                fullWidth
                multiline
                rows={4}
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
