import Grid from '@mui/material/Grid';
import { ReactElement, useState } from 'react';
import { Link, Route, Routes } from 'react-router-dom';

import AddBundle from '../components/bundles/AddBundle';
import AllBundles from '../components/bundles/AllBundles';
import EditBundle from '../components/bundles/EditBundle';
import AddClient from '../components/clients/AddClient';
import AllClients from '../components/clients/AllClients';
import EditClient from '../components/clients/EditClient';
import { Client } from '../components/clients/hooks/useClients';
import LocationStepper from '../components/Layout/LocationStepper';
import Sidebar from '../components/Layout/Sidebar';
import AllLocations from '../components/locations/AllLocations/AllLocations';
import Step1Add from '../components/locations/BasicLocation/AddLocationBasic';
import Step1Edit from '../components/locations/BasicLocation/EditBasicLocation';
import EditLocationSteps5 from '../components/locations/Bookings/EditLocationSteps5';
import EditLocationStep2 from '../components/locations/CreateType/CreateTypeLocation';
import EditLocationSteps4 from '../components/locations/EssentialPhrases/EditLocationSteps4';
import EditLocationSteps3 from '../components/locations/GeneralInfo/EditLocationSteps3';
import Login from '../components/users/Login';
import ProtectedRoute from './ProtectedRoute';

export default function Router(): ReactElement {
  const [selectedClient, setSelectedClient] = useState<Client>();
  const handleSelect = (client: Client) => setSelectedClient(client);

  return (
    <Routes>
      <Route>
        <Route path="/" element={<Login />} />
        <Route
          path="/clients/:clientName/bundles"
          element={
            <ProtectedRoute>
              <Grid container spacing={0}>
                <Grid item xs={3}>
                  <Sidebar client={selectedClient} pageTitle="bundles" />
                </Grid>
                <Grid item xs={9}>
                  <AllBundles onRefresh={handleSelect} />
                </Grid>
              </Grid>
            </ProtectedRoute>
          }
        />
        <Route
          path="/clients"
          element={
            <ProtectedRoute>
              <Grid container spacing={0}>
                <Grid item xs={3}>
                  <Sidebar client={selectedClient} pageTitle="clients" />
                </Grid>
                <Grid item xs={9}>
                  <AllClients setSelected={handleSelect} />
                </Grid>
              </Grid>
            </ProtectedRoute>
          }
        />
        <Route
          index
          path="/clients/:clientName/bundles/new"
          element={
            <ProtectedRoute>
              <Grid container spacing={0}>
                <Grid item xs={3}>
                  <Sidebar client={selectedClient} pageTitle="Add Bundle" />
                </Grid>
                <Grid item xs={9}>
                  <AddBundle onRefresh={handleSelect} />
                </Grid>
              </Grid>
            </ProtectedRoute>
          }
        />
        <Route
          index
          path="/clients/:clientName/bundles/edit"
          element={
            <ProtectedRoute>
              <Grid container spacing={0}>
                <Grid item xs={3}>
                  <Sidebar client={selectedClient} pageTitle="edit bundle" />
                </Grid>
                <Grid item xs={9}>
                  <EditBundle onRefresh={handleSelect} />
                </Grid>
              </Grid>
            </ProtectedRoute>
          }
        />
        <Route
          path="/clients/new"
          element={
            <ProtectedRoute>
              <Grid container spacing={0}>
                <Grid item xs={3}>
                  <Sidebar client={selectedClient} pageTitle="add client" />
                </Grid>
                <Grid item xs={9}>
                  <AddClient />
                </Grid>
              </Grid>
            </ProtectedRoute>
          }
        />
        <Route
          path="/clients/:clientName/edit"
          element={
            <ProtectedRoute>
              <Grid container spacing={0}>
                <Grid item xs={3}>
                  <Sidebar client={selectedClient} pageTitle="edit client" />
                </Grid>
                <Grid item xs={9}>
                  <EditClient onRefresh={handleSelect} />
                </Grid>
              </Grid>
            </ProtectedRoute>
          }
        />
        <Route
          index
          path="/clients/:clientName/locations"
          element={
            <ProtectedRoute>
              <Grid container spacing={0}>
                <Grid item xs={3}>
                  <Sidebar client={selectedClient} pageTitle="edit client" />
                </Grid>
                <Grid item xs={9}>
                  <AllLocations onRefresh={handleSelect} />
                </Grid>
              </Grid>
            </ProtectedRoute>
          }
        />
        <Route
          index
          path="/clients/:clientName/locations/new"
          element={
            <ProtectedRoute>
              <Grid container spacing={0}>
                <Grid item xs={3}>
                  <Sidebar client={selectedClient} pageTitle="locations" />
                </Grid>
                <Grid item xs={9}>
                  <LocationStepper activeStep={0} />
                  <Step1Add onRefresh={handleSelect} />
                </Grid>
              </Grid>
            </ProtectedRoute>
          }
        />
        <Route
          path="/clients/:clientName/locations/edit/step1"
          element={
            <ProtectedRoute>
              <Grid container spacing={0}>
                <Grid item xs={3}>
                  <Sidebar client={selectedClient} pageTitle="locations" />
                </Grid>
                <Grid item xs={9}>
                  <LocationStepper activeStep={0} />
                  <Step1Edit onRefresh={handleSelect} />
                </Grid>
              </Grid>
            </ProtectedRoute>
          }
        />
        <Route
          path="/clients/:clientName/locations/edit/step1/step2"
          element={
            <ProtectedRoute>
              <Grid container spacing={0}>
                <Grid item xs={3}>
                  <Sidebar client={selectedClient} pageTitle="locations" />
                </Grid>
                <Grid item xs={9}>
                  <LocationStepper activeStep={1} />
                  <EditLocationStep2 onRefresh={handleSelect} />
                </Grid>
              </Grid>
            </ProtectedRoute>
          }
        />
        <Route
          path="/clients/:clientName/locations/edit/step1/step2/step3"
          element={
            <ProtectedRoute>
              <Grid container spacing={0}>
                <Grid item xs={3}>
                  <Sidebar client={selectedClient} pageTitle="locations" />
                </Grid>
                <Grid item xs={9}>
                  <LocationStepper activeStep={2} />
                  <EditLocationSteps3 onRefresh={handleSelect} />
                </Grid>
              </Grid>
            </ProtectedRoute>
          }
        />
        <Route
          path="/clients/:clientName/locations/edit/step1/step2/step3/step4"
          element={
            <ProtectedRoute>
              <Grid container spacing={0}>
                <Grid item xs={3}>
                  <Sidebar client={selectedClient} pageTitle="locations" />
                </Grid>
                <Grid item xs={9}>
                  <LocationStepper activeStep={3} />
                  <EditLocationSteps4 onRefresh={handleSelect} />
                </Grid>
              </Grid>
            </ProtectedRoute>
          }
        />
        <Route
          path="/clients/:clientName/locations/edit/step1/step2/step3/step4/step5"
          element={
            <ProtectedRoute>
              <Grid container spacing={0}>
                <Grid item xs={3}>
                  <Sidebar client={selectedClient} pageTitle="locations" />
                </Grid>
                <Grid item xs={9}>
                  <LocationStepper activeStep={4} />
                  <EditLocationSteps5 onRefresh={handleSelect} />
                </Grid>
              </Grid>
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<NoMatch />} />
      </Route>
    </Routes>
  );
}

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}
