import React from 'react';

export function Pencil() {
  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.8233 4.39916L3.03388 11.5572C2.97898 11.6393 2.9496 11.7357 2.94944 11.8345L2.94406 15.0347C2.94342 15.4186 3.35776 15.66 3.69141 15.47L6.24627 14.0158C6.31373 13.9774 6.37132 13.9238 6.41448 13.8593L11.2225 6.67351M7.8233 4.39916L9.81961 1.41556C9.97317 1.18605 10.2837 1.12449 10.5132 1.27805L13.0813 2.99631C13.3108 3.14987 13.3723 3.46041 13.2188 3.68991L11.2225 6.67351M7.8233 4.39916L11.2225 6.67351"
        stroke="#011531"
        strokeLinecap="round"
      />
      <path d="M1 17.4687H15" stroke="#011531" strokeLinecap="round" />
    </svg>
  );
}
