import './PasswordDialog.css';

import { IconButton, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import * as React from 'react';

import Close from '../../Icons/Close';
import Lock from '../../Icons/Lock';
import Mark from '../../Icons/Mark';

interface Props {
  open: boolean;
  handleClose: React.MouseEventHandler<HTMLButtonElement>;
  setOpen: (arg0: boolean) => void;
}
export default function PasswordDialog({ open, handleClose, setOpen }: Props) {
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get('email'),
    });
  };
  return (
    <Dialog open={open} onClose={handleClose}>
      <Box
        component="form"
        onSubmit={handleSubmit}
        noValidate
        sx={{
          mt: 1,
          backgroundColor: '#FFFFFF',
          borderRadius: '20px',
          padding: '56px 46px',
          width: '470px',
          boxShadow: '0px 20px 50px rgba(0, 0, 0, 0.07)',
        }}
      >
        <IconButton
          aria-label="close"
          onClick={() => setOpen(!open)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
        <div className="login">
          <div>
            <Lock />
          </div>
          <div style={{ lineHeight: '32px' }}>
            <div>Password</div>
            <div>Recovery</div>
          </div>
        </div>
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Admin Email"
          name="email"
          autoComplete="email"
          autoFocus
        />
        <div className="btnConteiner">
          <Button
            type="submit"
            sx={{
              backgroundColor: 'hsla(168, 90%, 46%, 1)',
              borderRadius: '4px',
              boxShadow: '0px 6px 10px rgba(12, 225, 183, 0.2)',
              textTransform: 'none',
              padding: '8px 30px',
            }}
          >
            <div className="submitBtn">Reset Password</div>
          </Button>
        </div>
        <div className="signupConteiner">
          <div className="new">
            <span style={{ marginRight: '5px' }}>
              <Mark />
            </span>
            Back to
          </div>
          <button type="button" className="sign">
            Log in
          </button>
        </div>
      </Box>
    </Dialog>
  );
}
