/* eslint-disable no-console */
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@mui/material/CircularProgress';
import { ReactElement } from 'react';
import { useIsFetching, useIsMutating } from 'react-query';

const useStyles = makeStyles(() => ({
  box: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 400,
  },
  loader: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

export default function Loading(): ReactElement {
  const classes = useStyles();
  const isFetching = useIsFetching();
  const isMutating = useIsMutating();

  const display = isFetching || isMutating ? 'indeterminate' : 'determinate';

  return (
    <CircularProgress
      className={classes.box}
      variant={display}
      thickness={5}
      size={100}
    />
  );
}
