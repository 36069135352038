import { makeStyles } from '@material-ui/core';
import { Button, InputLabel, Modal } from '@mui/material';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { ReactElement, useState } from 'react';
import { Controller } from 'react-hook-form';

import PlusIcon from '../../../Icons/PlusIcon';
import { AddLocationStep4FormProps } from './interfaces/AddLocationStep4FormProps';

const useStyles = makeStyles(() => ({
  btnStyleNext: {
    fontSize: '16px!important' as any,
    lineHeight: '24px!important' as any,
    fontWeight: 'normal!important' as any,
    fontFamily: 'Poppins!important' as any,
    textTransform: 'initial!important' as any,
    background: '#0CE1B7!important' as any,
    borderRadius: '4px!important' as any,
    color: '#011531!important' as any,
    width: '220px',
    height: '50px',
  },
  btnStyleSave: {
    fontSize: '16px!important' as any,
    lineHeight: '24px!important' as any,
    fontWeight: 'normal!important' as any,
    fontFamily: 'Poppins!important' as any,
    textTransform: 'initial!important' as any,
    background: '#0CE1B7!important' as any,
    borderRadius: '4px!important' as any,
    color: '#011531!important' as any,
    width: '140px',
    height: '50px',
  },

  divStyle: {
    textAlign: 'left',
    width: '100%',
    borderBottom: '1px solid #000',
    lineHeight: '0.1em',
    margin: '10px 0 20px',
  },
  popupStyle: {
    background: '#ffffff',
    borderRadius: '20px',
    margin: '0 auto',
    top: '50%',
  },
  label: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 'lighter',
    fontFamily: 'Poppins',
    textAlign: 'left',
  },
  btnStyleCancle: {
    fontSize: '16px!important' as any,
    lineHeight: '24px!important' as any,
    fontWeight: 'normal!important' as any,
    fontFamily: 'Poppins!important' as any,
    textTransform: 'initial!important' as any,
    background: '#0CE1B7',
    color: '#011531!important' as any,
    width: '180px',
    height: '50px',
  },
  gridStyle: {
    textAlign: 'center',
  },
  popupModelStyle: {
    margin: '0 auto',
  },
}));

export default function AddLocationStep3Form({
  control,
}: AddLocationStep4FormProps): ReactElement {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const handleClose = () => {
    setIsOpen(false);
  };

  const renderSection = (
    title: string,
    width: string,
    name1: any,
    name2: any,
  ) => {
    return (
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <div style={{ display: 'flex' }}>
          <div style={{ width }}>{title}</div>
          <div className={classes.divStyle} />
        </div>
        <Grid container spacing={1} rowSpacing={1}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Controller
              name={name1}
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  fullWidth
                  onChange={onChange}
                  value={value}
                  label="translating"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Controller
              name={name2}
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  fullWidth
                  onChange={onChange}
                  value={value}
                  label="pronunciation"
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <Box sx={{ display: 'flex', padding: '16px 25px' }}>
        <Grid
          justifyContent="space-between"
          container
          spacing={1}
          rowSpacing={1}
        >
          <Grid item xs={4} lg={3} md={3} sm={4} xl={3}>
            <FormControl sx={{ m: 1, minWidth: 235 }}>
              <Controller
                name="essentialPhrases.language"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    onChange={onChange}
                    value={value}
                    label="language"
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4} lg={3} md={3} sm={4} xl={3}>
            <Button
              onClick={() => setIsOpen(true)}
              variant="outlined"
              className={classes.btnStyleNext}
            >
              <span style={{ marginRight: '6px', display: 'flex' }}>
                <PlusIcon />
              </span>
              Add phrase
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ padding: '16px 25px' }}>
        <Grid container spacing={5} rowSpacing={1}>
          {renderSection(
            'Good Morning:',
            '45%',
            'essentialPhrases.goodMorning.text',
            'essentialPhrases.goodMorning.pronunciation',
          )}
          {renderSection(
            'Good Afternoon:',
            '55%',
            'essentialPhrases.goodAfternoon.text',
            'essentialPhrases.goodAfternoon.pronunciation',
          )}
        </Grid>
      </Box>
      <Box sx={{ padding: '16px 25px' }}>
        <Grid container spacing={5} rowSpacing={1}>
          {renderSection(
            'Good Night:',
            '35%',
            'essentialPhrases.goodNight.text',
            'essentialPhrases.goodNight.pronunciation',
          )}
          {renderSection(
            'Hello:',
            '35%',
            'essentialPhrases.hello.text',
            'essentialPhrases.hello.pronunciation',
          )}
        </Grid>
      </Box>
      <Box sx={{ padding: '16px 25px' }}>
        <Grid container spacing={5} rowSpacing={1}>
          {renderSection(
            'Where ist:',
            '35%',
            'essentialPhrases.whereIs.text',
            'essentialPhrases.whereIs.pronunciation',
          )}
          {renderSection(
            'Please:',
            '35%',
            'essentialPhrases.please.text',
            'essentialPhrases.please.pronunciation',
          )}
        </Grid>
      </Box>
      <Box sx={{ padding: '16px 25px' }}>
        <Grid container spacing={5} rowSpacing={1}>
          {renderSection(
            'Thank You:',
            '35%',
            'essentialPhrases.thankYou.text',
            'essentialPhrases.thankYou.pronunciation',
          )}
          {renderSection(
            'Excuse Me:',
            '35%',
            'essentialPhrases.excuseMe.text',
            'essentialPhrases.excuseMe.pronunciation',
          )}
        </Grid>
      </Box>
      <Box sx={{ padding: '16px 25px' }}>
        <Grid container spacing={5} rowSpacing={1}>
          {renderSection(
            'I Don`t Understand:',
            '80%',
            'essentialPhrases.iDontUnderstand.text',
            'essentialPhrases.iDontUnderstand.pronunciation',
          )}
        </Grid>
      </Box>
      <Modal
        className={classes.popupModelStyle}
        open={isOpen}
        onClose={() => handleClose()}
      >
        <Box
          sx={{ width: 520, padding: '16px 52px' }}
          className={classes.popupStyle}
        >
          <div
            role="button"
            tabIndex={0}
            style={{ textAlign: 'right' }}
            onClick={() => handleClose()}
            onKeyDown={() => handleClose()}
          >
            X
          </div>
          <Grid container rowSpacing={1} className={classes.gridStyle}>
            <Grid item xs={12} md={12} sm={12} lg={12}>
              <Button style={{ color: 'black' }}>
                <span style={{ marginRight: '6px', display: 'flex' }}>
                  <PlusIcon />
                </span>
                Add phrase
              </Button>
            </Grid>

            <Grid item xs={12} md={12} sm={12} lg={12}>
              <InputLabel className={classes.label} shrink>
                *Phrase in English :
              </InputLabel>
              <Controller
                name="essentialPhrases.iDontUnderstand.pronunciation"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField fullWidth onChange={onChange} value={value} />
                )}
              />
            </Grid>

            <Grid item xs={12} md={12} sm={12} lg={12}>
              <InputLabel className={classes.label} shrink>
                *Translation :
              </InputLabel>
              <Controller
                name="essentialPhrases.iDontUnderstand.pronunciation"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField fullWidth onChange={onChange} value={value} />
                )}
              />
            </Grid>

            <Grid item xs={12} md={12} sm={12} lg={12}>
              <InputLabel className={classes.label} shrink>
                *Pronunciation :
              </InputLabel>
              <Controller
                name="essentialPhrases.iDontUnderstand.pronunciation"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField fullWidth onChange={onChange} value={value} />
                )}
              />
            </Grid>
            <Grid>
              <Box mt="20px" style={{ textAlign: 'center' }}>
                <Box component="span">
                  <Button className={classes.btnStyleSave}>Save</Button>
                </Box>
                <Box ml="20px" component="span">
                  <Button
                    className={classes.btnStyleCancle}
                    // onClick={handleSubmit(onSubmit)}
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}
