import React, { ReactElement } from 'react';

import GoogleImageCard from './GoogleImageCard';
import SelectImageTypeTabs from './SelectImageTypeTabs';
import UploadImageCard from './UploadImageCard';

interface LocationImageProps {
  selectedTab: number;
  setSelectedTab: (tab: number) => void;
  customSelectedImage: string;
  setCustomSelectedImage: (img: string) => void;
  googleSelectedImage: string;
  setImagesPopupOpen: (popup: boolean) => void;
  disable: boolean;
}

function LocationImage({
  selectedTab,
  setSelectedTab,
  customSelectedImage,
  setCustomSelectedImage,
  googleSelectedImage,
  setImagesPopupOpen,
  disable,
}: LocationImageProps): ReactElement {
  return (
    <div style={{ maxWidth: '500px' }}>
      <SelectImageTypeTabs
        tab={selectedTab}
        onTabChanged={(tab: number) => setSelectedTab(tab)}
      />
      {selectedTab ? (
        <UploadImageCard
          img={customSelectedImage}
          onChangeImage={(imgsrc) => setCustomSelectedImage(imgsrc)}
        />
      ) : (
        <GoogleImageCard
          onClick={() => setImagesPopupOpen(true)}
          disable={disable}
          imgSrc={googleSelectedImage}
          buttonTxt="change image"
        />
      )}
    </div>
  );
}

export default LocationImage;
