import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { UseForms } from '../interfaces/UseForms.interface';

const schema = yup
  .object({
    title: yup.string().required(),
    pageSubTitle: yup.string().required(),
    city: yup.string().required(),
    countryCode: yup.string().required(),
    introduction: yup.string().required(),
    description: yup.string().required(),
  })
  .required();

export function useForms(
  title: string,
  pageSubTitle: string,
  city: string,
  countryCode: string,
  introduction: string,
  description: string,
): UseForms {
  const { handleSubmit, control, getValues, setValue } = useForm({
    defaultValues: {
      title: title || '',
      pageSubTitle: pageSubTitle || '',
      city: city || '',
      countryCode: countryCode || '',
      introduction: introduction || '',
      description: description || '',
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    setValue('title', title || '');
    setValue('pageSubTitle', pageSubTitle || '');
    setValue('city', city || '');
    setValue('countryCode', countryCode || '');
    setValue('introduction', introduction || '');
    setValue('description', description || '');
  }, [title, pageSubTitle, city, countryCode, introduction, description]);

  return {
    handleSubmit,
    control,
    getValues,
  };
}
