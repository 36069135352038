import React from 'react';

function Clients() {
  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="18"
      fill="none"
      viewBox="0 0 21 18"
    >
      <path
        stroke="#011531"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M10.529 8.286a1.493 1.493 0 00-.271 0A3.63 3.63 0 016.75 4.647 3.644 3.644 0 0110.397 1a3.644 3.644 0 01.132 7.286zM15.893 2.643a2.873 2.873 0 012.875 2.875A2.88 2.88 0 0116 8.393a.929.929 0 00-.214 0M6.598 11.317c-1.988 1.331-1.988 3.5 0 4.822 2.26 1.512 5.964 1.512 8.223 0 1.988-1.33 1.988-3.5 0-4.822-2.251-1.503-5.956-1.503-8.223 0zM17.429 15.786a3.972 3.972 0 001.61-.715c1.281-.96 1.281-2.546 0-3.507-.452-.345-1.002-.575-1.585-.707M5.157 2.643a2.873 2.873 0 00-2.875 2.875A2.879 2.879 0 005.05 8.393a.928.928 0 01.213 0M3.571 15.786a3.972 3.972 0 01-1.61-.715c-1.281-.96-1.281-2.546 0-3.507.452-.345 1.002-.575 1.586-.707"
      />
    </svg>
  );
}

export default Clients;
