import { Grid } from '@material-ui/core';
import EastIcon from '@mui/icons-material/East';
import Box from '@mui/material/Box';
import { ReactElement } from 'react';

import CreateType from '../../common/CreateType';
import { useStyles } from './CreateTypeStyle';

interface CreateTypeSectionProps {
  withTypes: boolean;
  handleNewType: (typeTitle: string) => void;
}

export default function CreateTypeSection({
  withTypes,
  handleNewType,
}: CreateTypeSectionProps): ReactElement {
  const classes = useStyles();

  const renderNoTypes = () => {
    return (
      <Grid container spacing={2} style={{ display: 'block' }}>
        <div className={classes.boxStyle}>
          <div className={classes.innerDivStyle}>
            <Box className={classes.divStyle}>
              <EastIcon fontSize="large" style={{ marginRight: '25px' }} />
              <CreateType onSelect={handleNewType} />
            </Box>
          </div>
        </div>
      </Grid>
    );
  };

  const renderWithTypes = () => {
    return (
      <div
        style={{
          textAlign: 'left',
          marginBottom: '30px',
        }}
      >
        <Box>
          <CreateType onSelect={handleNewType} />
        </Box>
      </div>
    );
  };

  return (
    <div style={{ marginTop: withTypes ? '30px' : '110px' }}>
      {withTypes ? renderWithTypes() : renderNoTypes()}
    </div>
  );
}
