import { Box, makeStyles } from '@material-ui/core';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
} from '@mui/material';
import { ReactElement, useState } from 'react';

import PlusWithCircle from '../../../Icons/PlusWithCircle';
import { useClient } from '../../clients/hooks/useClients';
import { useClientLocation } from '../../locations/hooks/useClientLocations';
import SelectClient from './SelectClient';
import SelectLocation from './SelectLocation';

export interface ImportLocationProps {
  open: boolean;
  onClose: () => void;
  clientId: string;
}

const useStyles = makeStyles(() => ({
  mainTitle: {
    fontFamily: 'Poppins!important' as 'Poppins',
    fontSize: '28px!important' as '28px',
    lineHeight: '42px!important' as '42px',
    fontWeight: 700,
    fontStyle: 'normal!important' as 'normal',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  popupStyle: {
    width: '600px',
    height: '700px',
  },
  btnStyleCancle: {
    fontSize: '16px!important' as any,
    lineHeight: '24px!important' as any,
    fontWeight: 'normal!important' as any,
    fontFamily: 'Poppins!important' as any,
    textTransform: 'initial!important' as any,
    background: '#0CE1B7',
    color: '#011531!important',
    width: '180px',
    height: '50px',
    border: '1px solid #011531!important' as any,
    borderRadius: '4px!important' as any,
  },
  btnStyleSave: {
    fontSize: '16px!important' as any,
    lineHeight: '24px!important' as any,
    fontWeight: 'normal!important' as any,
    fontFamily: 'Poppins!important' as any,
    textTransform: 'initial!important' as any,
    background: '#0CE1B7!important' as any,
    borderRadius: '4px!important' as any,
    color: '#011531!important' as any,
    width: '180px',
    marginRight: '20px!important' as any,
    height: '50px',
  },
  paperHeight: {
    maxHeight: 'calc(100% - 0px)!important' as any,
    borderRadius: '20px!important' as any,
  },
}));

export default function ImportLocation({
  open,
  onClose,
  clientId,
}: ImportLocationProps): ReactElement {
  const [showOptions, setShowOptions] = useState(true);
  const { clients } = useClient();
  const filterClients = clients.filter(({ _id: id }) => id !== clientId);
  const { exportLocationMutation, insertExcelMutation } =
    useClientLocation(clientId);
  const [selectedLocation, setSelectedLocation] = useState<
    undefined | string
  >();
  const [selectedClientId, setSelectedClientId] = useState<
    string | undefined
  >();

  const classes = useStyles();

  const handleExport = () => {
    if (clientId && selectedLocation) {
      exportLocationMutation({ clientId, selectedLocation });
    }
    handleClose();
  };

  const handleClose = () => {
    onClose();
    setSelectedClientId(undefined);
    setShowOptions(true);
  };

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      console.log(event.target.files[0]);
      insertExcelMutation(event?.target.files[0]);
    }
  };

  const chooseFromExistingClient = () => (
    <div style={{ padding: '0px 90px' }}>
      <SelectClient
        clients={filterClients}
        onSelect={(id: string) => setSelectedClientId(id)}
      />
      {selectedClientId && (
        <SelectLocation
          onLocationSelected={(locationId) => setSelectedLocation(locationId)}
          selectedClient={selectedClientId}
          currentClientId={clientId}
        />
      )}
      <DialogActions>
        <Box
          mt="20px"
          mb="50px"
          style={{ textAlign: 'center', marginBottom: '40px' }}
        >
          <Box component="span" style={{ justifyContent: 'center' }}>
            <Box component="span">
              <Button className={classes.btnStyleSave} onClick={handleExport}>
                Ok
              </Button>
              <Button className={classes.btnStyleCancle} onClick={handleClose}>
                Cancel
              </Button>
            </Box>
          </Box>
        </Box>
      </DialogActions>
    </div>
  );

  const renderOptions = () => (
    <>
      <Button variant="contained" component="label">
        Upload
        <input
          disabled
          accept=".xls,.xlsx"
          type="file"
          onChange={(event) => handleChange(event)}
        />
      </Button>
      <div>upload an excel\CSV document from your computer</div>
      <br />
      <Divider>Or</Divider>
      <br />
      <Button autoFocus onClick={() => setShowOptions(false)}>
        choose from an existing client
      </Button>
      <br />
    </>
  );

  return (
    <Dialog
      open={open}
      sx={{
        minHeight: '600px',
        maxHeight: '800px',
        maxWidth: '100%',
        paddingTop: '45px',
      }}
      classes={{ paper: classes.paperHeight }}
    >
      <DialogTitle sx={{ fontWeight: 700 }} className={classes.mainTitle}>
        <PlusWithCircle />
        <span style={{ marginLeft: '5px' }}>Import location</span>
      </DialogTitle>
      {showOptions ? renderOptions() : chooseFromExistingClient()}
    </Dialog>
  );
}
