import React from 'react';

function HandIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="42"
      height="45"
      viewBox="0 0 42 45"
      fill="none"
    >
      <path
        d="M35.7 7.03125C35.3505 7.03125 35.0079 7.05992 34.6736 7.11519C33.8349 4.60943 31.4738 2.79889 28.7 2.79889C28.135 2.79889 27.587 2.87408 27.0655 3.01483C25.9525 1.20674 23.9662 0 21.7 0C19.4338 0 17.4388 1.21201 16.3275 3.02695C15.8113 2.88721 15.2688 2.8125 14.7088 2.8125C11.235 2.8125 8.40088 5.65137 8.40088 9.14062V19.8281C7.94238 19.7402 7.47338 19.6875 7.00088 19.6875C3.18763 19.6875 0 22.7812 0 26.7275C0 28.5108 0.661325 30.201 1.87775 31.5132L8.98713 39.1808C12.4163 42.8818 17.2725 45 22.3038 45H25.1983C34.4663 45 42 37.4326 42 28.125V13.3594C42 9.87012 39.1738 7.03125 35.7 7.03125ZM37.8 28.125C37.8 35.1044 32.1484 40.7812 25.2788 40.7812H22.3038C18.4179 40.7812 14.7061 39.1588 12.0575 36.3023L5.0785 28.7763C4.53583 28.191 4.25548 27.4228 4.25548 26.746C4.25548 24.7983 5.87685 23.9862 6.9986 23.9862C7.7476 23.9862 8.49485 24.2865 9.04785 24.881L11.3858 27.4025C11.5325 27.4834 11.7163 27.5449 11.8913 27.5449C12.2505 27.5449 12.5969 27.2661 12.5969 26.8404V9.14062C12.5969 7.97607 13.5375 7.03125 14.6181 7.03125C15.7775 7.03125 16.6394 7.97607 16.6394 9.14062V22.5C16.8 23.2734 17.43 23.9062 18.2 23.9062C18.97 23.9062 19.5213 23.2767 19.5213 22.5791L19.6 6.32812C19.6 5.16357 20.5406 4.21875 21.6213 4.21875C22.7806 4.21875 23.6425 5.16357 23.6425 6.32812V22.5C23.8 23.2734 24.43 23.9062 25.2 23.9062C25.97 23.9062 26.5991 23.2766 26.5991 22.5791L26.5998 9.20215C26.5998 8.0376 27.5404 7.09277 28.621 7.09277C29.7804 7.09277 30.6423 8.0376 30.6423 9.20215V22.5791C30.8 23.2734 31.43 23.9062 32.2 23.9062C32.97 23.9062 33.5213 23.2767 33.5213 22.5791V13.3594C33.5213 12.1948 34.4619 11.25 35.5425 11.25C36.7019 11.25 37.5638 12.1948 37.5638 13.3594L37.8 28.125Z"
        fill="#EC088C"
      />
    </svg>
  );
}

export default HandIcon;
