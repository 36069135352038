/* eslint-disable @typescript-eslint/no-unused-vars */
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { ReactElement, useState } from 'react';

interface SelectTypeProps {
  type: string;
  onChange: (type: string) => void;
}

export default function SelectType({
  type,
  onChange,
}: SelectTypeProps): ReactElement {
  const handleChange = (event: SelectChangeEvent) => {
    onChange(event.target.value);
  };

  return (
    <Box sx={{ minWidth: 380 }}>
      <FormControl>
        <InputLabel id="demo-simple-select-label">type</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={type}
          label="type"
          onChange={handleChange}
        >
          <MenuItem value="Coffee">Coffee</MenuItem>
          <MenuItem value="Lunch">Lunch</MenuItem>
          <MenuItem value="Drinks">Drinks</MenuItem>
          <MenuItem value="Shopping">Shopping</MenuItem>
          <MenuItem value="Culture">Culture</MenuItem>
          <MenuItem value="Outdoor">Outdoor</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}
