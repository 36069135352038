import React from 'react';

export function Plus() {
  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.2243 0.5H9.7757C11.7292 0.5 13.139 1.01431 14.0623 1.93767C14.9857 2.86102 15.5 4.27078 15.5 6.2243V9.7757C15.5 11.7292 14.9857 13.139 14.0623 14.0623C13.139 14.9857 11.7292 15.5 9.7757 15.5H6.2243C4.27078 15.5 2.86102 14.9857 1.93767 14.0623C1.01431 13.139 0.5 11.7292 0.5 9.7757V6.2243C0.5 4.27078 1.01431 2.86102 1.93767 1.93767C2.86102 1.01431 4.27078 0.5 6.2243 0.5Z"
        stroke="#011531"
      />
      <path d="M8 4.39999L8 11.6" stroke="#011531" strokeLinecap="round" />
      <path d="M11.6001 8L4.4001 8" stroke="#011531" strokeLinecap="round" />
    </svg>
  );
}
