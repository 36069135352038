/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-underscore-dangle */
import { makeStyles } from '@material-ui/core';
import Autocomplete from '@mui/material/Autocomplete';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { useRef, useState } from 'react';

import { useClientLocation } from '../../locations/hooks/useClientLocations';

interface SelectProps {
  selectedClient: string;
  currentClientId: string;
  onLocationSelected: (locationId: string) => void;
}

const useStyles = makeStyles(() => ({
  radioStyle: {
    padding: '10px 0px',
    borderBottom: '1px solid #E1E1E1',
  },
  mainDiv: {
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
    marginBottom: '12px',
  },
  numberStyle: {
    borderRadius: '17.5px',
    background: '#0CE1B7',
    height: '30px',
    width: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textStyle: {
    fontFamily: 'Poppins!important' as 'Poppins',
    fontSize: '16px!important' as '28px',
    lineHeight: '24px!important' as '42px',
    fontWeight: 700,
    marginLeft: '10px',
    fontStyle: 'normal!important' as 'normal',
  },
  customSpan: {
    fontFamily: 'Poppins!important' as 'Poppins',
    fontSize: '16px!important' as '28px',
    lineHeight: '24px!important' as '42px',
    fontWeight: 400,
    fontStyle: 'normal!important' as 'normal',
    color: '#011531',
  },
  autoComplete: {
    boxShadow: 'none!important' as 'none',
    maxHeight: '310px',
  },

  customRadio: {
    '&.Mui-checked': {
      color: '#0CE1B7!important' as '#0CE1B7', // Replace with your custom color
    },
  },
}));

export default function SelectLocation({
  selectedClient,
  onLocationSelected,
  currentClientId,
}: SelectProps) {
  const { locations: selectedClientLocations } =
    useClientLocation(selectedClient);
  const { locations: currentClientLocations } =
    useClientLocation(currentClientId);
  const availableLocations = selectedClientLocations.filter((location) => {
    return !currentClientLocations.find(
      ({ title: currentTitle }) => currentTitle === location.title,
    );
  });
  const radioGroupRef = useRef<HTMLElement>(null);
  const [radioValue, setRadioValue] = useState('');
  const classes = useStyles();
  const handleChange = (event: SelectChangeEvent) => {
    setRadioValue(event.target.value);
    onLocationSelected(event.target.value);
  };

  return (
    <>
      <div className={classes.mainDiv} style={{ marginTop: '30px' }}>
        <div className={classes.numberStyle}>2</div>
        <div className={classes.textStyle}>Find Location</div>
      </div>
      <Autocomplete
        open
        id="virtualize-demo"
        classes={{ paper: classes.autoComplete }}
        style={{ boxShadow: 'none', maxHeight: '24vh' }}
        options={availableLocations}
        isOptionEqualToValue={(option, value) => option._id === value._id}
        getOptionLabel={(option) => (option ? option.title : '')}
        sx={{ minHeight: '400px', boxShadow: 'none' }}
        renderInput={(params) => <TextField {...params} label="search" />}
        renderOption={(_, option) => {
          return (
            <RadioGroup
              className={classes.radioStyle}
              ref={radioGroupRef}
              aria-label="ringtone"
              name="ringtone"
              value={radioValue}
              key={option._id}
              onChange={handleChange}
            >
              <FormControlLabel
                value={option._id}
                sx={{ fontSize: '25px' }}
                key={option._id}
                control={<Radio className={classes.customRadio} />}
                label={
                  <span className={classes.customSpan}>{option.title}</span>
                }
              />
            </RadioGroup>
          );
        }}
      />
    </>
  );
}
