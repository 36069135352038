import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { ReactElement } from 'react';
import { Control, Controller } from 'react-hook-form';

interface AddLocationStep3FormProps {
  control: Control<{
    generalTips: {
      emergencyServices: {
        policePhone: string;
      };
      policeStation: {
        phone: string;
        address: string;
      };
      pharmacy: {
        name: string;
        phone: string;
        address: string;
      };
      touristInfoOffice: {
        phone: string;
        address: string;
      };
      ambulancePhone: string;
      currency: string;
      taxi: {
        name: string;
        phone: string;
      };
      toAvoid: string;
      tipping: string;
      gifts: string;
      security: string;
    };
  }>;
}

export default function AddLocationStep3Form({
  control,
}: AddLocationStep3FormProps): ReactElement {
  return (
    <>
      <Box sx={{ display: 'flex', padding: '16px 25px' }}>
        <Grid container spacing={1} rowSpacing={1}>
          <Grid item xs={3}>
            <Controller
              name="generalTips.emergencyServices.policePhone"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  multiline
                  fullWidth
                  onChange={onChange}
                  value={value}
                  label="emergency phone"
                />
              )}
            />
          </Grid>
          <Grid item xs={3}>
            <Controller
              name="generalTips.policeStation.phone"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  multiline
                  fullWidth
                  onChange={onChange}
                  value={value}
                  label="police station"
                />
              )}
            />
          </Grid>
          <Grid item xs={3}>
            <Controller
              name="generalTips.pharmacy.name"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  multiline
                  fullWidth
                  onChange={onChange}
                  value={value}
                  label="pharmacy"
                />
              )}
            />
          </Grid>
          <Grid item xs={3}>
            <Controller
              name="generalTips.security"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField onChange={onChange} value={value} label="security" />
              )}
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ display: 'flex', padding: '26px 25px' }}>
        <Grid container spacing={1} rowSpacing={1}>
          <Grid item xs={3}>
            <Controller
              name="generalTips.pharmacy.phone"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  fullWidth
                  onChange={onChange}
                  value={value}
                  label="pharmacy phone"
                />
              )}
            />
          </Grid>
          <Grid item xs={3}>
            <Controller
              name="generalTips.pharmacy.address"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  fullWidth
                  onChange={onChange}
                  value={value}
                  label="pharmacy address"
                />
              )}
            />
          </Grid>
          <Grid item xs={3}>
            <Controller
              name="generalTips.touristInfoOffice.phone"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  fullWidth
                  onChange={onChange}
                  value={value}
                  label="tourist info"
                />
              )}
            />
          </Grid>
          <Grid item xs={3}>
            <Controller
              name="generalTips.touristInfoOffice.address"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  fullWidth
                  onChange={onChange}
                  value={value}
                  label="tourist address"
                />
              )}
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ display: 'flex', mt: 2, padding: '10px 25px' }}>
        <Grid container spacing={1} rowSpacing={1}>
          <Grid item xs={1.7}>
            <Controller
              name="generalTips.ambulancePhone"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  onChange={onChange}
                  value={value}
                  label="ambulance"
                />
              )}
            />
          </Grid>
          <Grid item xs={1.7}>
            <Controller
              name="generalTips.currency"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField onChange={onChange} value={value} label="currency" />
              )}
            />
          </Grid>
          <Grid item xs={1.7}>
            <Controller
              name="generalTips.taxi.name"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  onChange={onChange}
                  value={value}
                  label="taxi name"
                />
              )}
            />
          </Grid>
          <Grid item xs={1.7}>
            <Controller
              name="generalTips.taxi.phone"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  onChange={onChange}
                  value={value}
                  label="taxi phone"
                />
              )}
            />
          </Grid>
          <Grid item xs={1.7}>
            <Controller
              name="generalTips.toAvoid"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField onChange={onChange} value={value} label="to avoid" />
              )}
            />
          </Grid>
          <Grid item xs={1.7}>
            <Controller
              name="generalTips.tipping"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField onChange={onChange} value={value} label="tipping" />
              )}
            />
          </Grid>
          <Grid item xs={1.7}>
            <Controller
              name="generalTips.gifts"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField onChange={onChange} value={value} label="gifts" />
              )}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
