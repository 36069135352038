import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  btnStyleCancle: {
    fontSize: '16px!important' as any,
    lineHeight: '24px!important' as any,
    fontWeight: 'normal!important' as any,
    fontFamily: 'Poppins!important' as any,
    textTransform: 'initial!important' as any,
    background: '#0CE1B7',
    color: '#011531!important' as any,
    borderRadius: '4px!important' as any,
    border: '1px solid #011531!important' as any,
    width: '180px',
    height: '50px',
  },
  btnStyleNext: {
    fontSize: '16px!important' as any,
    lineHeight: '24px!important' as any,
    fontWeight: 'normal!important' as any,
    fontFamily: 'Poppins!important' as any,
    textTransform: 'initial!important' as any,
    background: '#0CE1B7!important' as any,
    borderRadius: '4px!important' as any,
    color: '#011531!important' as any,
    borderColor: '#0CE1B7!important' as '#0CE1B7',
    width: '180px',
    height: '50px',
  },
  boxStyle: {
    background: '#FFFFFF',
    boxShadow: '10px 10px 48px rgba(1, 21, 49, 0.1)',
    borderRadius: '4px',
    margin: '0px 50px 0px 0px ',
    height: '223px',
  },
  divStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#FFFFFF',
    border: '1px dashed #011531',
    borderRadius: '4px',
    padding: '26px',
    paddingTop: '64px',
    paddingBottom: '57px',
  },
  innerDivStyle: {
    background: '#FFFFFF',
    borderRadius: '4px',
    padding: '26px',
  },
  btnDivStyle: {
    paddingTop: '20px',
  },
}));
