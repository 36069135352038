/* eslint-disable no-console */
import './images.css';

import { Box, makeStyles } from '@material-ui/core';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ReactElement, useState } from 'react';

import { Photo } from '../../locations/hooks/useGoogleMaps';
import Images from './Images';

interface ImagesProps {
  open: boolean;
  onClose: () => void;
  images: Photo[];
  onSelected: (index: number) => void;
}

const useStyles = makeStyles(() => ({
  titleDiv: {
    textAlign: 'center',
    marginTop: '16px!important' as any,
  },
  titleMainDiv: {
    color: '#011531',
    fontSize: '28px',
    fontWeight: 700,
    lineHeight: '42px',
  },
  titleInnerDiv: {
    color: '#011531',
    fontSize: '22px',
    fontWeight: 300,
    lineHeight: '33px',
  },
  btnStyleCancle: {
    fontSize: '16px!important' as any,
    lineHeight: '24px!important' as any,
    fontWeight: 'normal!important' as any,
    fontFamily: 'Poppins!important' as any,
    textTransform: 'initial!important' as any,
    background: '#0CE1B7',
    color: '#011531!important' as any,
    borderRadius: '4px!important' as any,
    border: '1px solid #011531!important' as any,
    width: '180px',
    height: '50px',
  },
  btnStyleSave: {
    fontSize: '16px!important' as any,
    lineHeight: '24px!important' as any,
    fontWeight: 'normal!important' as any,
    fontFamily: 'Poppins!important' as any,
    textTransform: 'initial!important' as any,
    background: '#0CE1B7!important' as any,
    borderRadius: '4px!important' as any,
    color: '#011531!important' as any,
    width: '180px',
    height: '50px',
  },
  footerDiv: {
    display: 'flex!important' as any,
    justifyContent: 'center!important' as any,
    marginBottom: '34px',
  },
}));

export default function ImagesPopup({
  open,
  onClose,
  images,
  onSelected,
}: ImagesProps): ReactElement {
  const [selected, setSelecteed] = useState<number>(0);
  const handleApprove = () => {
    onSelected(selected);
    onClose();
  };

  const classes = useStyles();
  return (
    <div>
      <Dialog
        open={open}
        className="image-popup-class"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className={classes.titleDiv} id="alert-dialog-title">
          <div className={classes.titleMainDiv}>Location Images </div>
          <div className={classes.titleInnerDiv}> from Google Places API</div>
        </DialogTitle>
        <DialogContent>
          <Images
            onSelect={(index: number) => setSelecteed(index)}
            photos={images}
          />
        </DialogContent>
        <DialogActions className={classes.footerDiv}>
          <Box mt="20px" style={{ textAlign: 'right' }}>
            <Box style={{ marginRight: '20px' }} component="span">
              <Button className={classes.btnStyleSave} onClick={handleApprove}>
                Save
              </Button>
            </Box>
            <Box ml={10} component="span">
              <Button
                className={classes.btnStyleCancle}
                onClick={() => onClose()}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
}
