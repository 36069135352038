import React from 'react';

function LunchIcon({ fill }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill={fill}
    >
      <path
        d="M10.3777 4V26.5185"
        stroke="#011531"
        strokeOpacity="0.4"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 4.5625V10.1921C7 13.0069 10.3778 13.0069 10.3778 13.0069C10.3778 13.0069 13.7555 13.0069 13.7555 10.1921V4.5625"
        stroke="#011531"
        strokeOpacity="0.4"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23 13.5703V26.5185M23 13.5703H18.4963V8.50369C18.4963 4 23 4 23 4V13.5703Z"
        stroke="#011531"
        strokeOpacity="0.4"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default LunchIcon;
