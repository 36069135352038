import React from 'react';

function Download() {
  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.4559 13.65H5.54389C2.59789 13.65 1.33789 12.39 1.33789 9.44405V9.36605C1.33789 6.70205 2.38789 5.41806 4.73989 5.19606C4.97989 5.17806 5.20789 5.35806 5.23189 5.60406C5.25589 5.85006 5.07589 6.07205 4.82389 6.09605C2.93989 6.27005 2.23789 7.15805 2.23789 9.37205V9.45005C2.23789 11.892 3.10189 12.756 5.54389 12.756H9.4559C11.8979 12.756 12.7619 11.892 12.7619 9.45005V9.37205C12.7619 7.14605 12.0479 6.25805 10.1279 6.09605C9.8819 6.07205 9.6959 5.85606 9.7199 5.61006C9.7439 5.36406 9.9539 5.17806 10.2059 5.20206C12.5939 5.40606 13.6619 6.69605 13.6619 9.37805V9.45605C13.6619 12.39 12.4019 13.65 9.4559 13.65Z"
        fill="#011531"
      />
      <path
        d="M7.50005 9.67805C7.25405 9.67805 7.05005 9.47405 7.05005 9.22805V1.50005C7.05005 1.25405 7.25405 1.05005 7.50005 1.05005C7.74605 1.05005 7.95005 1.25405 7.95005 1.50005V9.22805C7.95005 9.48005 7.74605 9.67805 7.50005 9.67805Z"
        fill="#011531"
      />
      <path
        d="M7.49998 10.35C7.38598 10.35 7.27198 10.308 7.18198 10.218L5.172 8.20803C4.998 8.03403 4.998 7.74603 5.172 7.57203C5.346 7.39803 5.634 7.39803 5.808 7.57203L7.49998 9.26403L9.19198 7.57203C9.36598 7.39803 9.65398 7.39803 9.82798 7.57203C10.002 7.74603 10.002 8.03403 9.82798 8.20803L7.81798 10.218C7.72798 10.308 7.61398 10.35 7.49998 10.35Z"
        fill="#011531"
      />
    </svg>
  );
}

export default Download;
