import { yupResolver } from '@hookform/resolvers/yup';
import { Box, makeStyles } from '@material-ui/core';
import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import GoogleImageCard from '../../common/GoogleImageCard';
import SelectImageTypeTabs from '../../common/SelectImageTypeTabs';
import SelectType from '../../common/SelectType';
import UploadImageCard from '../../common/UploadImageCard';
import {
  googleApiKey,
  LanLngCoords,
  Photo,
  photosApiUrl,
} from '../../locations/hooks/useGoogleMaps';
import { Place } from '../../maps/Searchbar';
import WrapperMap from '../../maps/WrapperMap';
import ImagesPopup from '../imagesPopup/ImagesPopup';
import AddItemForm from './AddItemForm';

const schema = yup
  .object({
    title: yup.string().required(),
    subType: yup.string().required(),
    description: yup.string().required(),
    phone: yup.string(),
    website: yup.string(),
    facebook: yup.string(),
    instagram: yup.string(),
  })
  .required();

enum ImageType {
  GoogleImage,
  SimpleImage,
}

const useStyles = makeStyles(() => ({
  titleStyle: {
    fontSize: '28px',
    lineHeight: '60px',
    fontWeight: 'lighter',
    fontFamily: 'Poppins',
    textAlign: 'left',
  },
  btnStyleSave: {
    fontSize: '16px!important' as '16px',
    lineHeight: '24px!important' as '24px',
    fontWeight: 'normal!important' as 'normal',
    fontFamily: 'Poppins!important' as 'Poppins',
    textTransform: 'initial!important' as 'initial',
    background: '#0CE1B7!important' as '#0CE1B7',
    borderRadius: '4px!important' as '4px',
    color: '#011531!important' as '#011531',
    width: '140px',
    height: '50px',
  },
  cancelBtn: {
    fontSize: '16px!important' as '16px',
    lineHeight: '24px!important' as '24px',
    fontWeight: 'normal!important' as 'normal',
    color: ' #0CB4A5!important' as '#0CB4A5',
    textDecorationLine: 'underline!important' as 'underline',
    textTransform: 'capitalize!important' as 'capitalize',
  },
}));
const types = ['Coffee', 'Lunch', 'Drinks', 'Shopping', 'Culture', 'Outdoor'];

interface AddItemPopuptProps {
  open: boolean;
  onAddingItem: (newItem: any) => void;
  onClose: () => void;
}

export default function AddItemPopup({
  open,
  onAddingItem,
  onClose,
}: AddItemPopuptProps) {
  const [selectedType, setSelectedType] = useState(types[0]);
  const [images, setImages] = useState<Photo[]>([]);
  const [imagesPopupOpen, setImagesPopupOpen] = useState<boolean>(false);
  const [customSelectedImage, setCustomSelectedImage] = useState<string>('');
  const [googleSelectedImage, setGoogleSelectedImage] = useState<string>('');
  const initialImageRef = { title: '', url: '' };
  const [itemImageRef, setItemImageRef] = useState(initialImageRef);
  const [location, setLocation] = useState({
    address: '',
    coordinates: [-1, -1],
    placeId: '',
    accesible: false,
  });

  const classes = useStyles();

  const [selectedTab, setSelectedTab] = useState<ImageType>(
    ImageType.GoogleImage,
  );
  const { handleSubmit, control, getValues } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      contact: {
        facebook: '',
        instagram: '',
        phone: '',
        website: '',
      },
      title: '',
      subType: '',
      description: '',
      openingHours: {
        monday: '',
        tuesday: '',
        wednesday: '',
        thursday: '',
        friday: '',
        saturday: '',
        sunday: '',
      },
    },
  });

  const handleLocationChanged = (
    photos: Photo[],
    position: LanLngCoords,
    place: Place,
    accesible: boolean,
  ) => {
    const { lat, lng } = position;
    setImages(photos);
    setLocation({
      address: place.description,
      coordinates: [lat, lng],
      placeId: place.placeId,
      accesible,
    });
    setGoogleSelectedImage(
      photos.length
        ? `${photosApiUrl}=${photos[0].photo_reference}&key=${googleApiKey}`
        : '',
    );
    setItemImageRef({
      title: photos[0].pageImageRef?.title || itemImageRef?.title,
      url: photos[0].pageImageRef?.url || itemImageRef?.url,
    });
  };

  const handleSelectedTab = (tab: number) => {
    setSelectedTab(tab);
  };

  const handleSelectedType = (type: string) => {
    setSelectedType(type);
  };

  const handleImageRef = (index: number) => {
    setGoogleSelectedImage(
      `${photosApiUrl}=${images[index].photo_reference}&key=${googleApiKey}`,
    );
    setItemImageRef({
      title: images[index].pageImageRef?.title || itemImageRef?.title,
      url: images[index].pageImageRef?.url || itemImageRef?.url,
    });
  };

  const onSubmit = () => {
    onAddingItem({
      ...getValues(),
      imageUrl: selectedTab ? customSelectedImage : googleSelectedImage,
      itemImageRef: selectedTab ? initialImageRef : itemImageRef,
      location,
      published: true,
      selectedType,
    });
  };

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="xl"
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className={classes.titleStyle} id="alert-dialog-title">
          Add Item
        </DialogTitle>
        <div style={{ padding: '0px 25px' }}>
          <SelectType type={selectedType} onChange={handleSelectedType} />
        </div>
        <AddItemForm control={control} />

        <Grid
          rowSpacing={1}
          columnSpacing={2.5}
          container
          style={{ padding: '16px 25px' }}
        >
          <Grid item sm={12} lg={7} md={12} xs={12}>
            <WrapperMap
              onSelectLocation={handleLocationChanged}
              showLanLng={false}
            />
          </Grid>
          <Grid item sm={12} lg={5} md={12} xs={12}>
            <div style={{ marginTop: '10px', textAlign: 'left' }}>
              Location Images:
            </div>
            <div style={{ marginTop: '10px' }}>
              <Box className="location-tab">
                <SelectImageTypeTabs
                  tab={selectedTab}
                  onTabChanged={handleSelectedTab}
                />
              </Box>
              {selectedTab ? (
                <UploadImageCard
                  img={customSelectedImage}
                  onChangeImage={(imgsrc) => setCustomSelectedImage(imgsrc)}
                />
              ) : (
                <GoogleImageCard
                  disable={!images.length}
                  onClick={() => setImagesPopupOpen(true)}
                  title="depultative google"
                  imgSrc={googleSelectedImage}
                  buttonTxt="change image"
                />
              )}
            </div>
          </Grid>
        </Grid>
        <ImagesPopup
          onSelected={handleImageRef}
          open={imagesPopupOpen}
          onClose={() => setImagesPopupOpen(false)}
          images={images}
        />
        <Box
          mt="20px"
          style={{
            textAlign: 'right',
            marginBottom: '45px',
            marginRight: '45px',
          }}
        >
          <Box ml="20px" component="span">
            <Button
              className={classes.btnStyleSave}
              onClick={handleSubmit(onSubmit)}
            >
              Save
            </Button>
          </Box>
          <Box ml="20px" component="span">
            <Button className={classes.cancelBtn} onClick={() => onClose()}>
              Cancel
            </Button>
          </Box>
        </Box>
      </Dialog>
    </div>
  );
}
