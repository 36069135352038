import React from 'react';

export function Search() {
  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2901_1209)">
        <path
          d="M8.75008 15.8333C12.6621 15.8333 15.8334 12.662 15.8334 8.74999C15.8334 4.83797 12.6621 1.66666 8.75008 1.66666C4.83806 1.66666 1.66675 4.83797 1.66675 8.74999C1.66675 12.662 4.83806 15.8333 8.75008 15.8333Z"
          stroke="#011531"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.3333 18.3333L15 15"
          stroke="#011531"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2901_1209">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
