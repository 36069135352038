import { makeStyles } from '@material-ui/core';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { ReactElement } from 'react';

import BundleAddIcon from '../../Icons/BundleAddIcon';
import { Location } from '../locations/hooks/useClientLocations';

interface LocationsProps {
  locations: Location[];
  searchText: string;
  onSelect: (id: string, title: string) => void;
}

const useStyles = makeStyles(() => ({
  titleStyle: {
    fontSize: '16px',
    lineHeight: '24px',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
  },
  spanStyle: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontSize: '12px',
    lineHeight: '18px',
  },
  hrStyle: {
    border: '1px solid #E1E1E1',
  },
}));

export default function Locations({
  locations,
  searchText,
  onSelect,
}: LocationsProps): ReactElement {
  const classes = useStyles();
  return (
    <List sx={{ width: '100%', maxHeight: '500px', overflowY: 'scroll' }}>
      {locations
        .filter(({ title }) => title.toLowerCase().includes(searchText))
        .map(({ _id: id, title }) => (
          <div key={id}>
            <ListItem
              key={id}
              disableGutters
              secondaryAction={
                <IconButton
                  aria-label="comment"
                  onClick={() => onSelect(id, title)}
                >
                  <BundleAddIcon />
                </IconButton>
              }
            >
              <ListItemText
                className={classes.titleStyle}
                primary={`${title}`}
              />
            </ListItem>
            <hr className={classes.hrStyle} />
          </div>
        ))}
    </List>
  );
}
