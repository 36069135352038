/* eslint-disable @typescript-eslint/no-unused-vars */
import { makeStyles } from '@material-ui/core';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { ReactElement, useState } from 'react';

interface SelectImageTypeTabsProps {
  tab: number;
  onTabChanged: (tab: number) => void;
}

const useStyles = makeStyles(() => ({
  tabStyle: {
    backgroundColor: '#FFFFFF',
  },
}));

const activeTabStyle = {
  color: '#0CE1B7!important' as any,
};

export default function SelectImageTypeTabs({
  tab,
  onTabChanged,
}: SelectImageTypeTabsProps): ReactElement {
  const classes = useStyles();
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    onTabChanged(newValue);
  };
  return (
    <Box sx={{ width: '100%', bgcolor: '#F9F9F9', height: '60px' }}>
      <Tabs
        className={classes.tabStyle}
        value={tab}
        onChange={handleChange}
        variant="fullWidth"
        sx={{
          '& .MuiTabs-indicator': {
            backgroundColor: '#0CE1B7',
          },
        }}
      >
        <Tab
          sx={{
            color: () => (tab === 0 ? activeTabStyle : null),
            '&.MuiTabs-indicator': {
              background: '0CE1B7',
            },
          }}
          label="google places"
        />
        <Tab
          sx={{
            color: () => (tab === 1 ? activeTabStyle : null),
          }}
          label="api photo"
        />
      </Tabs>
    </Box>
  );
}
