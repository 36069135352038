import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';

import { queryKeys } from '../../../../react-query/consts';
import { queryClient } from '../../../../react-query/queryClient';
import {
  googleApiKey,
  LanLngCoords,
  Photo,
  photosApiUrl,
} from '../../hooks/useGoogleMaps';
import { editLocation, fetchPage } from '../apis';
import {
  ImageType,
  UseBasicLocation,
} from '../interfaces/UseBasicLocation.interface';
import { useForms } from './useForms';

export function useBasicLocation(
  locationId: string,
  client: { clientId: string; clientName: string },
): UseBasicLocation {
  const navigate = useNavigate();
  const location = useLocation();
  const { data: page = {} } = useQuery([queryKeys.page, locationId], () =>
    fetchPage(locationId),
  );
  const [imagesPopupOpen, setImagesPopupOpen] = useState<boolean>(false);
  const [exitPopupOpen, setExitPopupOpen] = useState<boolean>(false);
  const [customSelectedImage, setCustomSelectedImage] = useState<string>('');
  const { pageImageRef: originalImageRef, dockingPoint } = page;
  const [googleSelectedImage, setGoogleSelectedImage] = useState<string>('');
  const [images, setImages] = useState<Photo[]>([]);
  const [pageImageRef, setPageImageRef] = useState(originalImageRef);
  const [coordinates, setCoordinates] = useState(dockingPoint?.coordinates);
  const [selectedTab, setSelectedTab] = useState<ImageType>(
    ImageType.GoogleImage,
  );
  const { handleSubmit, control, getValues } = useForms(
    page?.title || '',
    page.pageSubTitle || '',
    page.city || '',
    page.countryCode || '',
    page.introduction || '',
    page.description || '',
  );

  useEffect(() => {
    setGoogleSelectedImage(page.imageUrl ? page.imageUrl : '');
    setCoordinates(dockingPoint?.coordinates);
    setPageImageRef({
      title: originalImageRef?.title || '',
      url: originalImageRef?.url || '',
    });
  }, [page]);

  const handleImageRef = (index: number) => {
    setGoogleSelectedImage(
      `${photosApiUrl}=${images[index].photo_reference}&key=${googleApiKey}`,
    );
    setPageImageRef({
      title: images[index].pageImageRef?.title || pageImageRef.title,
      url: images[index].pageImageRef?.url || pageImageRef.url,
    });
  };

  const handleLocationChanged = (photos: Photo[], position: LanLngCoords) => {
    const { lat, lng } = position;
    setCoordinates([lat, lng]);
    setImages(photos);
  };

  const { mutate: editLocationMutation } = useMutation(editLocation, {
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.page, locationId]);
    },
  });

  const handleEditMutation = async () => {
    editLocationMutation({
      ...getValues(),
      imageUrl: selectedTab ? customSelectedImage : googleSelectedImage,
      pageImageRef: {
        title: selectedTab ? '' : pageImageRef.title,
        url: selectedTab ? '' : pageImageRef.url,
      },
      dockingPoint: { coordinates },
      locationId,
    });
  };

  const handleDecision = async (toSave: boolean) => {
    if (toSave) {
      await handleEditMutation();
    }
    navigate(`/clients/${client.clientName}/locations`, {
      replace: true,
      state: {
        client: {
          _id: client.clientId,
          ...client,
        },
      },
    });
  };

  const onSubmit = async () => {
    await handleEditMutation();
    navigate(`${location.pathname}/step2`, {
      state: {
        locationId,
        client,
      },
    });
  };

  return {
    page,
    imagesPopupOpen,
    setImagesPopupOpen,
    exitPopupOpen,
    setExitPopupOpen,
    customSelectedImage,
    setCustomSelectedImage,
    editLocationMutation,
    googleSelectedImage,
    pageImageRef,
    selectedTab,
    setSelectedTab,
    images,
    coordinates,
    handleImageRef,
    handleLocationChanged,
    handleSubmit,
    control,
    getValues,
    handleDecision,
    onSubmit,
  };
}
