import { InputLabel, makeStyles } from '@material-ui/core';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { ReactElement } from 'react';
import { Control, Controller } from 'react-hook-form';

interface BasicFormProps {
  control: Control<{
    contact: {
      facebook: string;
      instagram: string;
      phone: string;
      website: string;
    };
    title: string;
    subType: string;
    description: string;
    openingHours: {
      monday: string;
      tuesday: string;
      wednesday: string;
      thursday: string;
      friday: string;
      saturday: string;
      sunday: string;
    };
  }>;
}
const useStyles = makeStyles(() => ({
  label: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 'lighter',
    fontFamily: 'Poppins',
    textAlign: 'left',
  },
  divStyle: {
    padding: '0px 10px',
  },
  lineStyle: {
    textAlign: 'left',
    width: '100%',
    borderBottom: '1px solid #E1E1E1',
    lineHeight: '0.1em',
    margin: '10px 0 20px',
  },
}));

export default function AddItemForm({ control }: BasicFormProps): ReactElement {
  const classes = useStyles();

  return (
    <>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', padding: '16px 25px' }}>
        <Grid container spacing={1} rowSpacing={1}>
          <Grid item xs={4}>
            <InputLabel className={classes.label} shrink>
              *Item title :
            </InputLabel>
            <Controller
              name="title"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  multiline
                  fullWidth
                  onChange={onChange}
                  value={value}
                  rows={3}
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <InputLabel className={classes.label} shrink>
              *Sub-type :
            </InputLabel>
            <Controller
              name="subType"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  multiline
                  fullWidth
                  onChange={onChange}
                  value={value}
                  rows={3}
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <InputLabel className={classes.label} shrink>
              *Description :
            </InputLabel>
            <Controller
              name="description"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  multiline
                  fullWidth
                  onChange={onChange}
                  value={value}
                  rows={3}
                />
              )}
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ display: 'flex', padding: '26px 25px' }}>
        <Grid container spacing={1} rowSpacing={1}>
          <Grid item xs={3}>
            <InputLabel className={classes.label} shrink>
              Phone :
            </InputLabel>
            <Controller
              name="contact.phone"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField fullWidth onChange={onChange} value={value} />
              )}
            />
          </Grid>
          <Grid item xs={3}>
            <InputLabel className={classes.label} shrink>
              Website :
            </InputLabel>
            <Controller
              name="contact.website"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField fullWidth onChange={onChange} value={value} />
              )}
            />
          </Grid>
          <Grid item xs={3}>
            <InputLabel className={classes.label} shrink>
              Facebook :
            </InputLabel>
            <Controller
              name="contact.facebook"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField fullWidth onChange={onChange} value={value} />
              )}
            />
          </Grid>
          <Grid item xs={3}>
            <InputLabel className={classes.label} shrink>
              Instagram :
            </InputLabel>
            <Controller
              name="contact.instagram"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField fullWidth onChange={onChange} value={value} />
              )}
            />
          </Grid>
        </Grid>
      </Box>
      <div style={{ display: 'flex', padding: '0px 25px' }}>
        <div style={{ width: '12%' }}>Opening Hours:</div>
        <div className={classes.lineStyle} />
      </div>
      <Box sx={{ display: 'flex', mt: 2, padding: '10px 25px' }}>
        <div className={classes.divStyle}>
          <InputLabel className={classes.label}>*MON</InputLabel>
          <Controller
            name="openingHours.monday"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField onChange={onChange} value={value} />
            )}
          />
        </div>
        <div className={classes.divStyle}>
          <InputLabel className={classes.label}>*TUE</InputLabel>

          <Controller
            name="openingHours.tuesday"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField onChange={onChange} value={value} />
            )}
          />
        </div>
        <div className={classes.divStyle}>
          <InputLabel className={classes.label}>*WED</InputLabel>

          <Controller
            name="openingHours.wednesday"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField onChange={onChange} value={value} />
            )}
          />
        </div>
        <div className={classes.divStyle}>
          <InputLabel className={classes.label}>*THU</InputLabel>
          <Controller
            name="openingHours.thursday"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField onChange={onChange} value={value} />
            )}
          />
        </div>
        <div className={classes.divStyle}>
          <InputLabel className={classes.label}>*FRI</InputLabel>
          <Controller
            name="openingHours.friday"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField onChange={onChange} value={value} />
            )}
          />
        </div>
        <div className={classes.divStyle}>
          <InputLabel className={classes.label}>*SAT</InputLabel>
          <Controller
            name="openingHours.saturday"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField onChange={onChange} value={value} />
            )}
          />
        </div>
        <div className={classes.divStyle}>
          <InputLabel className={classes.label}>*SUN</InputLabel>

          <Controller
            name="openingHours.sunday"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField onChange={onChange} value={value} />
            )}
          />
        </div>
      </Box>
    </>
  );
}
