import { QueryClient } from 'react-query';

export function generateQueryClient(): QueryClient {
  return new QueryClient({
    defaultOptions: {
      queries: {
        // onError: queryErrorHandler,
        staleTime: 600000000, // 10,000 minutes
        cacheTime: 900000,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      },
      mutations: {
        // onError: queryErrorHandler,
      },
    },
  });
}

export const queryClient = generateQueryClient();
