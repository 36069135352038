import { UseMutateFunction, useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { axiosInstance } from '../../../axiosInstance';

interface UserAuth {
  accessToken: string;
  refreshToken: string;
}

export async function signIn(user: {
  email: string;
  password: string;
}): Promise<UserAuth> {
  const { data } = await axiosInstance.post('/users/signIn', user);

  return data;
}

interface UseAuth {
  signInMutation: UseMutateFunction<
    UserAuth,
    unknown,
    {
      email: string;
      password: string;
    },
    unknown
  >;
}

export function useAuth(): UseAuth {
  const navigate = useNavigate();
  const { mutate: signInMutation } = useMutation(signIn, {
    onSuccess: ({ accessToken }) => {
      axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
      localStorage.setItem('accessToken', accessToken);
    },
    onSettled: () => {
      navigate('/clients');
    },
  });

  return { signInMutation };
}
