/* eslint-disable react/require-default-props */
import { makeStyles } from '@material-ui/core/styles';
import Box from '@mui/material/Box';
import { ReactElement, useState } from 'react';

import UploadBtn from './UploadBtn';

const useStyles = makeStyles(() => ({
  imgDiv: {
    border: '1px solid #E2E2E2',
    display: 'inline-flex',
    padding: '20px',
    borderRadius: '10px',
  },
  logoInputDiv: {
    display: 'flex',
  },
  bundleInput: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

interface UploadProps {
  id?: string | undefined;
  img: string | undefined;
  isFrom: string | undefined;
  additioalImgStyle?: any;
  additioalBtnStyle?: any;
  onChangeImage: (data: any, id: string) => void;
}

export default function UploadFile({
  id = '',
  img = '',
  isFrom = '',
  additioalImgStyle,
  additioalBtnStyle,
  onChangeImage,
}: UploadProps): ReactElement {
  const classes = useStyles();
  const [image, setImage] = useState<string | undefined>(img);

  const handleImageChanged = (pathToS3: string) => {
    setImage(pathToS3);
    onChangeImage(pathToS3, id);
  };

  return (
    <div
      className={classes.imgDiv}
      style={{
        height: isFrom === 'bundle' ? '164px' : 'auto',
        ...additioalImgStyle,
      }}
    >
      <div>
        <Box
          component="img"
          sx={{
            height: 140,
            width: 296,
          }}
          style={{ height: isFrom === 'bundle' ? '100px' : 'auto' }}
          alt="The house from the offer."
          src={image || '/Frame.png'}
        />
        <div>
          <div
            style={additioalBtnStyle}
            className={
              isFrom === 'bundle' ? classes.bundleInput : classes.logoInputDiv
            }
          >
            <UploadBtn onChangeImage={handleImageChanged} />
          </div>
        </div>
      </div>
    </div>
  );
}
