import { Item } from './Item';

export const typesArr = [
  'Coffee',
  'Lunch',
  'Drinks',
  'Shopping',
  'Outdoor',
  'Culture',
];

export interface Type {
  _id?: string;
  typeTitle?: string;
  typeDescription?: string;
  items: Item[];
  pageId: string;
  createdAt?: string;
  updatedAt?: string;
}
