import './Login.css';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import * as React from 'react';

import Logo from '../../Icons/Logo';
import Mark from '../../Icons/Mark';
import { useAuth } from './hooks/useAuth';
import PasswordDialog from './PasswordDialog';

const theme = createTheme();

export default function LogIn() {
  const { signInMutation } = useAuth();
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get('email')?.toString();
    const password = data.get('password')?.toString();

    if (email && password) {
      signInMutation({ email, password });
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Logo />
          <div className="cms">CMS</div>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{
              mt: 1,
              backgroundColor: '#FFFFFF',
              borderRadius: '20px',
              padding: '27px 46px',
              boxShadow: '0px 20px 50px rgba(0, 0, 0, 0.07)',
            }}
          >
            <div className="login">Log In</div>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Admin Email"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <button
              type="button"
              className="porgotBtn"
              onClick={handleClickOpen}
            >
              <Link
                href="#"
                variant="body2"
                sx={{
                  color: '#011531',
                  fontSize: '14px',
                  fontFamily: 'Poppins, sans-serif',
                  textDecorationColor: 'unset',
                }}
              >
                Forgot password?
              </Link>
            </button>
            <PasswordDialog
              open={open}
              handleClose={handleClose}
              setOpen={setOpen}
            />
            <div className="btnConteiner">
              <Button
                type="submit"
                sx={{
                  backgroundColor: 'hsla(168, 90%, 46%, 1)',
                  borderRadius: '4px',
                  boxShadow: '0px 6px 10px rgba(12, 225, 183, 0.2)',
                  textTransform: 'none',
                  padding: '8px 60px',
                }}
              >
                <div className="submitBtn">Log In</div>
              </Button>
            </div>
            <div className="signupConteiner">
              <div className="new">
                <span style={{ marginRight: '5px' }}>
                  <Mark />
                </span>
                New Here?
              </div>
              <button type="button" className="sign">
                Sign up
              </button>
            </div>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
