import React from 'react';

function DrinksIcon({ fill }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill={fill}
    >
      <path
        d="M23.8051 7.458C23.9096 7.32629 23.9751 7.16795 23.9942 7.00093C24.0133 6.83391 23.9852 6.66488 23.9131 6.513C23.8402 6.35984 23.7254 6.23042 23.582 6.13966C23.4386 6.04889 23.2725 6.00048 23.1029 6H6.89717C6.72747 6.00048 6.56137 6.04889 6.418 6.13966C6.27464 6.23042 6.15986 6.35984 6.08689 6.513C6.01481 6.66488 5.98673 6.83391 6.00582 7.00093C6.02492 7.16795 6.09043 7.32629 6.19492 7.458L14.0997 17.34V22.2H8.92288C8.6841 22.2 8.4551 22.2948 8.28626 22.4636C8.11742 22.6324 8.02256 22.8613 8.02256 23.1C8.02256 23.3387 8.11742 23.5676 8.28626 23.7364C8.4551 23.9052 8.6841 24 8.92288 24H21.0771C21.3159 24 21.5449 23.9052 21.7138 23.7364C21.8826 23.5676 21.9775 23.3387 21.9775 23.1C21.9775 22.8613 21.8826 22.6324 21.7138 22.4636C21.5449 22.2948 21.3159 22.2 21.0771 22.2H15.9003V17.34L23.8051 7.458ZM8.76982 7.8H21.2302L19.0694 10.5H10.9666L8.76982 7.8ZM15 15.585L12.3711 12.3H17.629L15 15.585Z"
        fill="#011531"
        fillOpacity="0.4"
      />
    </svg>
  );
}

export default DrinksIcon;
