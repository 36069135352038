import React from 'react';

export default function CloseIcon() {
  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
    >
      <path
        d="M9.53336 5.75719L5.45336 9.83719M5.45336 5.75719L9.53336 9.83719M14.2934 7.79719C14.2934 11.5528 11.2489 14.5972 7.49336 14.5972C3.73782 14.5972 0.693359 11.5528 0.693359 7.79719C0.693359 4.04165 3.73782 0.997192 7.49336 0.997192C11.2489 0.997192 14.2934 4.04165 14.2934 7.79719Z"
        stroke="#0CB4A5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
