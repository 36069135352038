import { axiosInstance } from '../../../axiosInstance';

export async function fetchPage(pageId: string) {
  const { data } = await axiosInstance.get(`/test/pages/${pageId}`);
  return data;
}

export async function editLocation(details: {
  title: string;
  pageSubTitle: string;
  city: string;
  countryCode: string;
  introduction: string;
  description: string;
  imageUrl: string;
  pageImageRef: {
    title: string;
    url: string;
  };
  dockingPoint: {
    coordinates: number[];
  };
  locationId: string;
}) {
  const { locationId, ...rest } = details;
  const { data } = await axiosInstance.post(
    `/test/pages/update-page/${locationId}`,
    rest,
  );
  return data;
}
