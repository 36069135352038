import { InputLabel, makeStyles } from '@material-ui/core';
import Divider from '@mui/material/Divider';
import MuiGrid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import * as React from 'react';
import { ReactElement, useState } from 'react';

import { Type } from '../../interfaces/Type';

const Grid = styled(MuiGrid)(({ theme }) => ({
  width: '100%',
  ...theme.typography.body2,
  '& [role="separator"]': {
    margin: theme.spacing(0, 2),
  },
}));

const useStyles = makeStyles(() => ({
  divStyle: {
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: 400,
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    color: '#011531',
    opacity: '0.7',
  },
}));

interface TypeDescriptionProps {
  type: Type;
  onDescriptionChange: (txt: string) => void;
}

export default function TypeDescription({
  type,
  onDescriptionChange,
}: TypeDescriptionProps): ReactElement {
  const [input, setInput] = useState<string>(type?.typeDescription || '');
  const classes = useStyles();
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInput(e.target.value || '');
    onDescriptionChange(e.target.value);
  };
  return (
    <Grid container display="flex" flexWrap="nowrap" alignItems="center">
      <Grid item xs={6} lg={2} md={2} sm={3}>
        <div className={classes.divStyle} key={type.typeTitle}>
          {type.typeTitle}
        </div>
      </Grid>
      <Divider orientation="vertical" style={{ margin: '0px 30px' }} flexItem />
      <Grid item xs={6} lg={2} md={2} sm={3}>
        <div className={classes.divStyle} key={type.typeTitle}>
          Items Included
        </div>
        <div className={classes.divStyle}>{type.items.length}</div>
      </Grid>
      <Divider orientation="vertical" style={{ margin: '0px 30px' }} flexItem />
      <Grid item xs={12} lg={8} md={8} sm={6}>
        <InputLabel
          style={{ textAlign: 'left' }}
          className={classes.divStyle}
          shrink
        >
          *Description :
        </InputLabel>
        <TextField
          id="outlined-basic"
          variant="outlined"
          sx={{ width: '100%' }}
          value={type?.typeDescription || ''}
          onChange={handleInputChange}
        />
      </Grid>
    </Grid>
  );
}
