import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import * as React from 'react';

interface DeleteItemProps {
  open: boolean;
  onClose: () => void;
  toDeleteItem: (toDelete: boolean) => void;
}

export default function DeleteItem({
  open,
  onClose,
  toDeleteItem,
}: DeleteItemProps) {
  const handleClick = (decition: boolean) => {
    toDeleteItem(decition);
    onClose();
  };
  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">delete item</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            this action will remove the entire item, are u sure?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClick(true)}>yes</Button>
          <Button onClick={() => handleClick(false)} autoFocus>
            no
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
