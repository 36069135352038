/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { axiosInstance } from '../../../axiosInstance';
import { Book } from '../../../interfaces/Book';
import { Item } from '../../../interfaces/Item';
import { Page } from '../../../interfaces/Page';
import { Type } from '../../../interfaces/Type';
import { queryKeys } from '../../../react-query/consts';
import { queryClient } from '../../../react-query/queryClient';
import { Client } from '../../clients/hooks/useClients';
import { LocationBasic } from './useClientLocations';

async function fetchPage(pageId: string) {
  const { data } = await axiosInstance.get(`/test/pages/${pageId}`);
  return data;
}

export async function editLocation(details: {
  locationBasic: LocationBasic;
  imageUrl: string;
  pageImageRef: {
    title: string;
    url: string;
  };
  dockingPoint: {
    dockingPoint: number[];
  };
  locationId: string;
}) {
  const { locationId, ...rest } = details;
  const { data } = await axiosInstance.post(
    `/test/pages/update-page/${locationId}`,
    rest,
  );
  return data;
}

export async function deleteItem(details: { typeId: string; itemId: string }) {
  const { typeId, itemId } = details;
  const { data } = await axiosInstance.delete(
    `/test/item?itemId=${itemId}&typeId=${typeId}`,
  );
  return data;
}

export async function deleteBooking(details: {
  pageId: string;
  bookingTitle: string;
}) {
  const { pageId, bookingTitle } = details;
  const { data } = await axiosInstance.delete(
    `test/booking?pageId=${pageId}&bookingTitle=${bookingTitle}`,
  );
  return data;
}

export async function editItem(item: Item) {
  const { _id: itemId } = item;
  const { data } = await axiosInstance.post(`/test/items/edit/${itemId}`, item);
  return data;
}

export async function editBooking(details: {
  pageId: string;
  editedBook: Book;
  bookIndex: number;
}) {
  const { pageId, editedBook, bookIndex } = details;
  const { data } = await axiosInstance.post(`/test/edit-booking/${pageId}`, {
    ...editedBook,
    bookIndex,
  });
  return data;
}

export async function addItem(details: { typeId: string }) {
  const { typeId } = details;
  const { data } = await axiosInstance.post(`/test/items/add/${typeId}`, {
    ...details,
  });
  return data;
}

export async function addTypesAndItems(details: {
  pageId: string;
  types: Type[];
  client: Client;
}) {
  const { pageId, client } = details;
  const { data } = await axiosInstance.post(`/test/types-and-items/${pageId}`, {
    ...details,
  });
  return { ...data, client };
}

export async function addBooking(details: { pageId: string }) {
  const { pageId, ...rest } = details;
  const { data } = await axiosInstance.post(`/test/add-booking/${pageId}`, {
    ...rest,
  });
  return data;
}

export async function updateGeneralTips(details: {
  locationId: string;
  generalTips: any;
  client: Client;
}) {
  const { locationId, client, generalTips } = details;
  const { data } = await axiosInstance.post(
    `/test/pages/update-page/${locationId}`,
    {
      ...generalTips,
    },
  );
  return { ...data, client };
}

export interface UsePage {
  page: Page;
  editLocationMutation: any;
  deleteItemMutation: any;
  editItemMutation: any;
  deleteBookingMutation: any;
  editBookingMutation: any;
  addItemMutation: any;
  addBookingMutation: any;
  addTypesAndItemsMutation: any;
  updateGeneralTipsMutation: any;
}

export function usePage(locationId: string): UsePage {
  const navigate = useNavigate();
  const { data: page = {} } = useQuery([queryKeys.page, locationId], () =>
    fetchPage(locationId),
  );

  const { mutate: editLocationMutation } = useMutation(editLocation, {
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.page, locationId]);
    },
  });

  const { mutate: deleteItemMutation } = useMutation(deleteItem, {
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.page, locationId]);
    },
  });

  const { mutate: editItemMutation } = useMutation(editItem, {
    onSuccess: (resData) => {
      queryClient.setQueryData([queryKeys.page, locationId], (old: any) => {
        const typeIndex = old.types.findIndex(
          (updated: { _id: string }) => updated._id === resData.typeId,
        );
        const itemIndex = old.types[typeIndex].items.findIndex(
          (updated: { _id: string }) => updated._id === resData._id,
        );
        old.types[typeIndex].items[itemIndex] = resData;
        return old;
      });
    },
  });

  const { mutate: deleteBookingMutation } = useMutation(deleteBooking, {
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.page, locationId]);
    },
  });

  const { mutate: editBookingMutation } = useMutation(editBooking, {
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.page, locationId]);
    },
  });

  const { mutate: addItemMutation } = useMutation(addItem, {
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.page, locationId]);
    },
  });

  const { mutate: addBookingMutation } = useMutation(addBooking, {
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.page, locationId]);
    },
  });

  const { mutate: addTypesAndItemsMutation } = useMutation(addTypesAndItems, {
    onSettled: ({ client }) => {
      const { clientName } = client;
      queryClient.invalidateQueries([queryKeys.page, locationId]);
      navigate(`../clients/${clientName}/locations/edit/step1/step2/step3`, {
        replace: false,
        state: {
          locationId,
          client,
        },
      });
    },
  });

  const { mutate: updateGeneralTipsMutation } = useMutation(updateGeneralTips, {
    onSettled: ({ client }) => {
      const { clientName } = client;
      queryClient.invalidateQueries([queryKeys.page, locationId]);
      navigate(
        `../clients/${clientName}/locations/edit/step1/step2/step3/step4`,
        {
          replace: false,
          state: {
            locationId,
            client,
          },
        },
      );
    },
  });

  return {
    page,
    editLocationMutation,
    deleteItemMutation,
    editItemMutation,
    deleteBookingMutation,
    editBookingMutation,
    addItemMutation,
    addBookingMutation,
    addTypesAndItemsMutation,
    updateGeneralTipsMutation,
  };
}
