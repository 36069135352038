/* eslint-disable @typescript-eslint/no-explicit-any */
import { UseMutateFunction, useMutation, useQuery } from 'react-query';

import { axiosInstance } from '../../../axiosInstance';
import { Bundle } from '../../../interfaces/Bundle';
import { queryKeys } from '../../../react-query/consts';
import { queryClient } from '../../../react-query/queryClient';

export interface shortenLocation {
  id: string;
  title: string;
}

async function getBundles(clientId: string): Promise<Bundle[]> {
  const {
    data: { bundles = [] },
  } = await axiosInstance.get(`/test/bundles/${clientId}`);
  return bundles;
}

export async function addBundle(bundle: {
  clientId: string;
  title: string;
  bundleUUID: string;
  pages: string[];
}) {
  const { clientId } = bundle;
  const { data } = await axiosInstance.post(
    `/test/add-bundle/${clientId}`,
    bundle,
  );
  return data;
}

export async function editBundle(bundle: {
  clientId: string;
  bundleId: string;
  title: string;
  bundleUUID: string;
  pages: string[];
}) {
  const { clientId } = bundle;
  const { data } = await axiosInstance.post(
    `/test/edit-bundle/${clientId}`,
    bundle,
  );
  return data;
}

export async function deleteBundle(details: {
  clientId: string;
  bundleId: string;
  clientName: string;
}) {
  const { clientId, bundleId, clientName } = details;
  const { data } = await axiosInstance.delete(
    `/test/bundle?clientId=${clientId}&bundleId=${bundleId}&clientName=${clientName}`,
  );
  return data;
}

interface UseClient {
  bundles: Bundle[];
  addBundleMutation: UseMutateFunction<
    any,
    unknown,
    {
      clientId: string;
      title: string;
      bundleUUID: string;
      pages: string[];
    },
    unknown
  >;
  editBundleMutation: UseMutateFunction<
    any,
    unknown,
    {
      clientId: string;
      bundleId: string;
      title: string;
      bundleUUID: string;
      pages: string[];
    },
    unknown
  >;
  deleteBundleMutation: UseMutateFunction<
    any,
    unknown,
    {
      clientId: string;
      bundleId: string;
      clientName: string;
    },
    unknown
  >;
}

export function useBundle(clientId: string): UseClient {
  const { data: bundles = [] } = useQuery([queryKeys.bundles, clientId], () =>
    getBundles(clientId),
  );

  const { mutate: addBundleMutation } = useMutation(addBundle, {
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.bundles, clientId]);
    },
  });

  const { mutate: editBundleMutation } = useMutation(editBundle, {
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.bundles, clientId]);
    },
  });

  const { mutate: deleteBundleMutation } = useMutation(deleteBundle, {
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.bundles, clientId]);
    },
  });

  return {
    bundles,
    addBundleMutation,
    editBundleMutation,
    deleteBundleMutation,
  };
}
