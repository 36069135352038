import { makeStyles } from '@material-ui/core';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { ReactElement, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { Pencil } from '../../Icons/Pencil';
import { Client } from '../clients/hooks/useClients';
import DeleteBundlePopup from '../popups/deleteBundlePopup/DeleteBundlePopup';
import BundlesTable from './BundlesTable';
import { useBundle } from './hooks/useBundle';

interface AllBundlesProps {
  onRefresh: (client: Client) => void;
}

const useStyles = makeStyles(() => ({
  mainDiv: {
    maxWidth: '1100px',
    margin: '38px auto',
  },

  btnStyleSave: {
    fontSize: '16px!important' as any,
    lineHeight: '24px!important' as any,
    fontWeight: '300!important' as any,
    fontFamily: 'Poppins!important' as any,
    textTransform: 'initial!important' as any,
    background: '#0CE1B7!important' as any,
    borderRadius: '4px!important' as any,
    color: '#011531!important' as any,
    borderColor: '#0CE1B7!important' as any,
    width: '200px',
    height: '40px',
  },
}));

export default function AllBundles({
  onRefresh,
}: AllBundlesProps): ReactElement {
  const [deleteBundlePopupOpen, setDeleteBundlePopupOpen] = useState(false);
  const [selectedBundle, setSelectedBundle] = useState('');
  const location = useLocation();
  const {
    state: { client },
  } = location;
  const { _id: clientId = '', clientName = '' } = client;
  const { bundles, deleteBundleMutation } = useBundle(clientId);

  const classes = useStyles();
  useEffect(() => {
    onRefresh(client);
  }, []);

  const handleDeleteBundle = (bundleId: string) => {
    setSelectedBundle(bundleId);
    setDeleteBundlePopupOpen(true);
  };

  const handleDecition = (toDelete: boolean) => {
    if (toDelete) {
      deleteBundleMutation({ clientId, bundleId: selectedBundle, clientName });
    }
    setSelectedBundle('');
  };

  return (
    <div className={classes.mainDiv}>
      <Box width="90%" overflow="hidden" m={1.5}>
        <Stack spacing={2} direction="row" justifyContent="flex-end">
          <Link
            to={`${location.pathname}/new`}
            state={{ client }}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            <Button className={classes.btnStyleSave} variant="outlined">
              <Pencil />
              Add bundle
            </Button>
          </Link>
        </Stack>
      </Box>

      <DeleteBundlePopup
        open={deleteBundlePopupOpen}
        onClose={() => setDeleteBundlePopupOpen(false)}
        toDeleteBundle={handleDecition}
      />
      {/* <h2>selected client: {clientName}</h2> */}
      <BundlesTable
        onDeleteBundle={handleDeleteBundle}
        bundles={bundles}
        client={client}
      />
    </div>
  );
}
