import { UseMutateFunction, useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { axiosInstance } from '../../../../axiosInstance';
import { Page } from '../../../../interfaces/Page';
import { queryKeys } from '../../../../react-query/consts';
import { queryClient } from '../../../../react-query/queryClient';
import { Client } from '../../../clients/hooks/useClients';

async function fetchPage(pageId: string) {
  const { data } = await axiosInstance.get(`/test/pages/${pageId}`);
  return data;
}

export async function updateEssentialPhrases(details: {
  locationId: string;
  essentialPhrases: any;
  client: Client;
}) {
  const { locationId, client, essentialPhrases } = details;
  const { data } = await axiosInstance.post(
    `/test/pages/update-page/${locationId}`,
    {
      ...essentialPhrases,
    },
  );
  return { ...data, client };
}

export interface UseEssentialPhrases {
  page: Page;
  updateEssentialPhrasesMutation: UseMutateFunction<
    any,
    unknown,
    {
      locationId: string;
      essentialPhrases: any;
      client: Client;
    },
    unknown
  >;
}

export function useEssentialPhrases(locationId: string): UseEssentialPhrases {
  const navigate = useNavigate();
  const { data: page = {} } = useQuery([queryKeys.page, locationId], () =>
    fetchPage(locationId),
  );

  const { mutate: updateEssentialPhrasesMutation } = useMutation(
    updateEssentialPhrases,
    {
      onSettled: ({ client }) => {
        const { clientName } = client;
        queryClient.invalidateQueries([queryKeys.page, locationId]);
        navigate(
          `../clients/${clientName}/locations/edit/step1/step2/step3/step4/step5`,
          {
            replace: false,
            state: {
              locationId,
              client,
            },
          },
        );
      },
    },
  );

  return {
    page,
    updateEssentialPhrasesMutation,
  };
}
