/* eslint-disable no-underscore-dangle */
import { makeStyles } from '@material-ui/core';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';
import { ReactElement, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { Cloud } from '../../../Icons/Cloud';
import { Pencil } from '../../../Icons/Pencil';
import { Plus } from '../../../Icons/Plus';
import { Client } from '../../clients/hooks/useClients';
import ImportLocation from '../../popups/ImportLocationPopup/ImportLocation';
import ChangePublishModePopup from '../../popups/publishModePopup/ChangePublishModePopup';
import { useClientLocation } from '../hooks/useClientLocations';
import LocationTable from './LocationTable';

const useStyles = makeStyles(() => ({
  mainDiv: {
    marginTop: '45px',
    padding: '0 50px 0px 16px',
  },
  aLink: {
    textDecoration: 'none !important',
  },
  ml10: {
    marginLeft: '10px',
  },
  headerButton: {
    backgroundColor: '#0CE1B7 !important',
    boxShadow: '0px 6px 10px rgba(12, 225, 183, 0.2)',
    borderRadius: '4px !important',
    fontFamily: 'Poppins !important',
    fontStyle: 'normal !important',
    fontWeight: 300,
    fontSize: '16px !important',
    lineHeight: '24px !important',
    color: '#011531 !important',
    width: '200px',
    height: '40px',
  },
  updateTimeLabel: {
    fontFamily: 'Poppins !important',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '12px',
    lineHeight: '20px',
    textTransform: 'capitalize',
    color: '#011531',
    opacity: '0.4',
    textAlign: 'end',
  },
}));

interface AllLocationsProps {
  onRefresh: (client: Client) => void;
}

export default function AllLocations({
  onRefresh,
}: AllLocationsProps): ReactElement {
  const [importPopupOpen, setImportPopupOpen] = useState<boolean>(false);
  const [snack, setSnack] = useState({
    open: false,
    message: 'loading..',
  });
  const [publishPage, setPublishPage] = useState({
    open: false,
    id: '',
    published: false,
    locationTitle: '',
  });
  const location = useLocation();
  const {
    state: { client },
  } = location;
  const { _id: clientId = '', clientName = '' } = client;
  const { locations, togglePageMutation, syncAllMutation, syncLoading } =
    useClientLocation(clientId);

  useEffect(() => {
    onRefresh(client);
  }, []);

  useEffect(() => {
    setSnack({ ...snack, open: !!syncLoading });
  }, [syncLoading]);

  const handlePublishesMode = (
    id: string,
    published: boolean,
    locationTitle: string,
  ) => {
    setPublishPage({ open: true, id, published, locationTitle });
  };

  const handleClose = () => {
    setPublishPage({
      id: '',
      open: false,
      published: false,
      locationTitle: '',
    });
  };

  const savePublishChanges = (toSave: boolean) => {
    if (toSave) {
      const index = locations.findIndex(
        ({ _id: locationId }) => locationId === publishPage.id,
      );
      // locations[index].published = !locations[index].published;
      togglePageMutation(locations[index]._id);
    }
    handleClose();
  };

  const classes = useStyles();

  return (
    <div className={classes.mainDiv}>
      <Stack spacing={2} direction="row" justifyContent="flex-end">
        <Link
          className={classes.aLink}
          to={`${location.pathname}/new`}
          state={{ client }}
        >
          <Button className={classes.headerButton}>
            <Pencil />
            <span className={classes.ml10}>add location</span>
          </Button>
        </Link>
        <Button
          className={classes.headerButton}
          onClick={() => setImportPopupOpen(true)}
        >
          <Plus />
          <span className={classes.ml10}>import location</span>
        </Button>
        <ImportLocation
          open={importPopupOpen}
          onClose={() => setImportPopupOpen(false)}
          clientId={clientId}
        />
        <div>
          <Button
            onClick={() => syncAllMutation(clientId)}
            className={classes.headerButton}
          >
            <Cloud />
            <span className={classes.ml10}>sync all</span>
          </Button>
          <div className={classes.updateTimeLabel}>Last update 27.11.22</div>
        </div>
      </Stack>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snack.open}
        onClose={() => setSnack({ ...snack, open: false })}
        message={snack.message}
      />
      <ChangePublishModePopup
        locationStatus={publishPage.published}
        locationTitle={publishPage.locationTitle}
        open={publishPage.open}
        onSave={savePublishChanges}
      />
      <LocationTable
        client={{ clientId, clientName }}
        locations={locations}
        onChangePublishMode={handlePublishesMode}
      />
    </div>
  );
}
