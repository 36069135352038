/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/alt-text */
import { makeStyles } from '@material-ui/core/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { ReactElement, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { Client, useClient } from './hooks/useClients';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  width: '229px',
  height: '150px',
}));

const useStyles = makeStyles(() => ({
  link: {
    background: '#f1f1f1',
    '&:hover': {
      background: '#f1dddd',
      cursor: 'pointer',
      alignContent: 'center',
      alignItems: 'center',
    },
  },
  img: {
    marginLeft: 'auto',
    marginRight: 'auto',
    verticalAlign: 'middle',
    maxWidth: '200px',
    maxHeiht: '100px',
  },
}));

interface AllClientProps {
  setSelected: (selected: Client) => void;
}

export default function AllClients({
  setSelected,
}: AllClientProps): ReactElement {
  const classes = useStyles();
  const { clients } = useClient();

  useEffect(() => {
    setSelected(clients[0]);
  }, [clients]);

  return (
    <Box sx={{ mt: 12, maxWidth: '850px' }}>
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        {clients.map((client) => (
          <Grid item xs={2} sm={4} md={4} key={client._id}>
            <Item
              key={client._id}
              className={classes.link}
              onClick={() => setSelected(client)}
            >
              <img src={client.clientLogoURL} className={classes.img} />
              <div>{client.clientName}</div>
            </Item>
          </Grid>
        ))}
        <Grid item xs={2} sm={4} md={4}>
          <div>
            <Link to="new">
              <Item className={classes.link}>add client</Item>
            </Link>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
}
