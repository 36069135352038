import React from 'react';

function Edit() {
  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      fill="none"
      viewBox="0 0 17 17"
    >
      <g stroke="#011531" strokeLinecap="round" clipPath="url(#clip0_621_1300)">
        <path d="M7 3H3a2 2 0 00-2 2v9a2 2 0 002 2h9a2 2 0 002-2v-4" />
        <path d="M10.563 3.637L5.245 9.603a.5.5 0 00-.122.263l-.405 2.873a.5.5 0 00.68.535l2.474-.986a.5.5 0 00.188-.132l5.34-5.99m-2.837-2.529l2.196-2.463a.5.5 0 01.706-.041l2.09 1.863a.5.5 0 01.04.706L13.4 6.166m-2.837-2.529L13.4 6.166" />
      </g>
      <defs>
        <clipPath id="clip0_621_1300">
          <path fill="#fff" d="M0 0H17V17H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Edit;
