import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { ReactElement, useState } from 'react';

import UploadFile from './UploadFile';

interface UploadProps {
  img: string | undefined;
  onChangeImage: (pathToS3: string) => void;
}

export default function UploadImageCard({
  img,
  onChangeImage,
}: UploadProps): ReactElement {
  const [image, setImage] = useState<string | undefined>(img);

  const handleImageChanged = (pathToS3: string) => {
    setImage(pathToS3);
    onChangeImage(pathToS3);
  };

  return (
    <Card
      sx={{
        width: '100%',
        padding: '20px',
        backgroundColor: '#F9F9F9',
      }}
    >
      <CardContent>
        <UploadFile
          img={image}
          isFrom="bundle"
          additioalImgStyle={{ border: 'none', borderRadius: 'none' }}
          additioalBtnStyle={{ marginTop: '-35px' }}
          onChangeImage={handleImageChanged}
        />
      </CardContent>
    </Card>
  );
}
