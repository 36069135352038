import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { Book } from '../../../interfaces/Book';
import GoogleImageCard from '../../common/GoogleImageCard';
import SelectImageTypeTabs from '../../common/SelectImageTypeTabs';
import UploadImageCard from '../../common/UploadImageCard';
import {
  fetchAutocomplete,
  fetchPlaceDetails,
  googleApiKey,
  Photo,
  photosApiUrl,
} from '../../locations/hooks/useGoogleMaps';
import ImagesPopup from '../imagesPopup/ImagesPopup';
import EditBookingForm from './EditBookingForm';

interface EditItemPopupProps {
  book: Book;
  onEditBooking: (book: Book) => void;
  open: boolean;
  onClose: () => void;
}

const schema = yup
  .object({
    title: yup.string().required(),
    subType: yup.string().required(),
    description: yup.string().required(),
    phone: yup.string().required(),
    website: yup.string().required(),
    facebook: yup.string().required(),
    instagram: yup.string().required(),
  })
  .required();

enum ImageType {
  GoogleImage,
  SimpleImage,
}

export default function EditBookingPopup({
  book,
  onEditBooking,
  open,
  onClose,
}: EditItemPopupProps) {
  const [images, setImages] = useState<Photo[]>([]);
  const [imagesPopupOpen, setImagesPopupOpen] = useState<boolean>(false);
  const [customSelectedImage, setCustomSelectedImage] = useState<string>('');
  const [selectedTab, setSelectedTab] = useState<ImageType>(
    ImageType.GoogleImage,
  );
  const {
    title,
    description,
    tripLentgh,
    redirectionUrl,
    imageUrl,
    bookingImageRef: originalBookingImageRef = {
      title: '',
      url: '',
    },
  } = book;
  const [bookingImageRef, setBookingImageRef] = useState(
    originalBookingImageRef,
  );
  const [googleSelectedImage, setGoogleSelectedImage] = useState<string>(
    imageUrl || '',
  );
  const { handleSubmit, control, getValues, setValue } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      title,
      description,
      tripLentgh,
      redirectionUrl,
    },
  });

  const handleSave = () => {
    onEditBooking({
      ...getValues(),
      bookingImageRef: selectedTab ? originalBookingImageRef : bookingImageRef,
      imageUrl: selectedTab ? customSelectedImage : googleSelectedImage,
    });
  };

  const handleImageRef = (index: number) => {
    setGoogleSelectedImage(
      `${photosApiUrl}=${images[index].photo_reference}&key=${googleApiKey}`,
    );
    setBookingImageRef({
      title: images[index].pageImageRef?.title || bookingImageRef?.title,
      url: images[index].pageImageRef?.url || bookingImageRef?.url,
    });
  };

  const fetchPlaces = async () => {
    const places = await fetchAutocomplete(title);
    const place = places.find(({ placeId }) => placeId);
    if (place) {
      const { photos } = await fetchPlaceDetails(place?.placeId);
      setImages(photos);
      setImagesPopupOpen(true);
    }
  };

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="xl"
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Edit Booking</DialogTitle>
        <EditBookingForm control={control} />
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            margin: 4,
          }}
        >
          <div style={{ width: '50%', marginTop: 70 }}>
            <SelectImageTypeTabs
              tab={selectedTab}
              onTabChanged={(tab: number) => setSelectedTab(tab)}
            />
            {selectedTab ? (
              <UploadImageCard
                img={customSelectedImage}
                onChangeImage={(imgsrc) => setCustomSelectedImage(imgsrc)}
              />
            ) : (
              <GoogleImageCard
                disable={false}
                onClick={fetchPlaces}
                title="depultative google"
                imgSrc={googleSelectedImage}
                buttonTxt="change image"
              />
            )}
          </div>
        </div>
        <ImagesPopup
          onSelected={handleImageRef}
          open={imagesPopupOpen}
          onClose={() => setImagesPopupOpen(false)}
          images={images}
        />
        <DialogActions>
          <Button onClick={handleSave}>save</Button>
          <Button onClick={() => onClose()} autoFocus>
            cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
