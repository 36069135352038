/* eslint-disable no-underscore-dangle */
import { makeStyles } from '@material-ui/core';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { ReactElement, useState } from 'react';

import { Client } from '../../clients/hooks/useClients';

interface SelectProps {
  clients: Client[];
  onSelect: (_id: string) => void;
}

const useStyles = makeStyles(() => ({
  sortedBy: {
    width: '100%',
  },
  searchBox: {
    width: '100%',
    background: '#FFFFFF',
    borderRadius: '4px',
    textAlign: 'left',
  },
  mainDiv: {
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
    marginBottom: '12px',
  },
  numberStyle: {
    borderRadius: '17.5px',
    background: '#0CE1B7',
    height: '30px',
    width: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textStyle: {
    fontFamily: 'Poppins!important' as 'Poppins',
    fontSize: '16px!important' as '28px',
    lineHeight: '24px!important' as '42px',
    fontWeight: 700,
    marginLeft: '10px',
    fontStyle: 'normal!important' as 'normal',
  },
}));

export default function SelectClient({
  clients,
  onSelect,
}: SelectProps): ReactElement {
  const [value, setValue] = useState('');
  const classes = useStyles();

  const handleChange = (event: SelectChangeEvent) => {
    setValue(event.target.value);
    const client = clients.find(
      ({ clientName }) => clientName === event.target.value,
    );
    if (client) {
      onSelect(client?._id);
    }
  };

  return (
    <div>
      <div className={classes.mainDiv}>
        <div className={classes.numberStyle}>1</div>
        <div className={classes.textStyle}>Choose from an existing client</div>
      </div>
      <FormControl sx={{ width: '400px' }}>
        <InputLabel id="demo-simple-select-autowidth-label">
          Choose Client
        </InputLabel>
        <Select
          labelId="demo-simple-select-autowidth-label"
          id="demo-simple-select-autowidth"
          value={value}
          onChange={handleChange}
          autoWidth
          label="Choose Client"
        >
          {clients.map(({ clientName }) => (
            <MenuItem
              sx={{ width: '400px', left: '455px' }}
              key={clientName}
              value={clientName}
            >
              {clientName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
