/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { axiosInstance } from '../../../axiosInstance';
import { PDF_SERVICE_URI } from '../../../consts';
import { queryKeys } from '../../../react-query/consts';
import { queryClient } from '../../../react-query/queryClient';

export interface Location {
  _id: string;
  title: string;
  published: boolean;
  updatedAt: string;
  pageGuideURL: string;
}

export interface LocationBasic {
  title: string;
  pageSubTitle: string;
  city: string;
  countryCode: string;
  introduction: string;
  description: string;
  imageUrl: string;
  pageImageRef: {
    title: string;
    url: string;
  };
  dockingPoint: {
    coordinates: number[];
  };
  clientId: string;
  clientName: string;
  next: boolean;
}

export interface LocationAutocomplete {
  description: string;
  placeId: string;
}

export async function addLocation(locationBasic: LocationBasic) {
  const { clientName, next } = locationBasic;
  const { data } = await axiosInstance.post('/pages/create', locationBasic);
  return { ...data, clientName, next };
}

async function getLocations(clientId: string): Promise<Location[]> {
  const { data } = await axiosInstance.get(`/test/locations/${clientId}`);
  return data;
}

export const exportLocation = async (details: {
  clientId: string;
  selectedLocation: string;
}) => {
  const { clientId, selectedLocation } = details;
  const { data } = await axiosInstance.get(
    `/test/export-page?toClientId=${clientId}&locationId=${selectedLocation}`,
  );
  return data;
};

export const syncAll = async (clientId: string) => {
  const { data } = await axiosInstance.get(`/test/sync-locations/${clientId}`);
  return data;
};

async function togglePage(pageId: string): Promise<Location> {
  const { data } = await axiosInstance.get(
    `/test/toggle-publish-mode/${pageId}`,
  );
  return data;
}

export async function finishLocationProcess(details: {
  toPublish: boolean;
  pageId: string;
  client: { clientId: string; clientName: string };
}) {
  const { client, ...rest } = details;
  const { data } = await axiosInstance.post(
    '/test/location-complete-process/',
    rest,
  );
  return { ...data, client };
}

export async function updateLocationPdf(details: {
  page_id: string;
  client_id: string;
}) {
  axiosInstance.post('/convert/topdf/', details);
}

export async function updateLocationPdfV2(pageId: string) {
  axiosInstance({
    baseURL: `${PDF_SERVICE_URI}/offline-guide/upsert/${pageId}`,
    method: 'GET',
  });
}

const insertExcel = async (file: File) => {
  const formData = new FormData();
  formData.append('file', file);

  const { data } = await axiosInstance({
    url: 'test/excel',
    method: 'POST',
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return data;
};

interface UseClientLocation {
  locations: Location[];
  exportLocationMutation: any;
  addLocationMutation: any;
  togglePageMutation: any;
  finishProcessMutation: any;
  insertExcelMutation: any;
  syncAllMutation: any;
  syncLoading: any;
}

export function useClientLocation(clientId: string): UseClientLocation {
  const navigate = useNavigate();
  const { data: locations = [] } = useQuery(
    [queryKeys.locations, clientId],
    () => getLocations(clientId),
  );

  const { mutate: exportLocationMutation } = useMutation(exportLocation, {
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.locations, clientId]);
    },
  });

  const { mutate: syncAllMutation, isLoading: syncLoading } =
    useMutation(syncAll);

  const { mutate: addLocationMutation } = useMutation(addLocation, {
    onSettled: (resData) => {
      const { _id: locationId, clientName, next } = resData;
      queryClient.invalidateQueries([queryKeys.locations, clientId]);
      next &&
        navigate(`../clients/${clientName}/locations/edit/step1/step2`, {
          replace: false,
          state: {
            locationId,
            client: {
              clientId,
              clientName,
            },
          },
        });
    },
  });

  const { mutate: togglePageMutation } = useMutation(togglePage, {
    onSuccess: (resData) => {
      queryClient.setQueryData([queryKeys.locations, clientId], (old: any) => {
        const index = old.findIndex(
          (newLocation: { _id: string }) => newLocation._id === resData._id,
        );
        old[index].published = resData.published;
        return old;
      });
    },
  });

  const { mutate: finishProcessMutation } = useMutation(finishLocationProcess, {
    onSuccess: ({ _id: pageId, client }) => {
      const { clientId: _id, clientName } = client;
      const details = {
        page_id: pageId,
        client_id: clientId,
      };
      updateLocationPdf(details);
      updateLocationPdfV2(pageId);
      queryClient.invalidateQueries([queryKeys.locations, clientId]);
      navigate(`/clients/${clientName}/locations`, {
        state: {
          client: {
            clientName,
            _id,
          },
        },
      });
    },
  });

  const { mutate: insertExcelMutation } = useMutation(insertExcel, {
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.bundles, clientId]);
    },
  });

  return {
    locations,
    exportLocationMutation,
    addLocationMutation,
    togglePageMutation,
    finishProcessMutation,
    insertExcelMutation,
    syncAllMutation,
    syncLoading,
  };
}
