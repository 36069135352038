/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { ReactElement, useEffect, useState } from 'react';
import { Control, Controller } from 'react-hook-form';

interface EditBookingFormProps {
  control: Control<{
    title: string;
    description: string;
    tripLentgh: string;
    redirectionUrl: string;
  }>;
}

export default function EditBookingForm({
  control,
}: EditBookingFormProps): ReactElement {
  return (
    <Box sx={{ display: 'flex', padding: '16px 25px' }}>
      <Grid container spacing={1} rowSpacing={1}>
        <Grid item xs={4}>
          <Controller
            name="title"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                multiline
                fullWidth
                onChange={onChange}
                value={value}
                rows={3}
                label="*title"
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            name="description"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                multiline
                fullWidth
                onChange={onChange}
                value={value}
                rows={3}
                label="*description"
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            name="tripLentgh"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                multiline
                fullWidth
                onChange={onChange}
                value={value}
                rows={3}
                label="*tripLentgh"
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            name="redirectionUrl"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                multiline
                fullWidth
                onChange={onChange}
                value={value}
                rows={3}
                label="*redirectionUrl"
              />
            )}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
