/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
import { makeStyles } from '@material-ui/core/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { ReactElement } from 'react';

interface CardProps {
  title?: string;
  disable: boolean;
  imgSrc: string | undefined;
  buttonTxt: string;
  onClick: () => void;
}

const useStyles = makeStyles(() => ({
  imgDiv: {
    display: 'inline-flex',
    padding: '20px',
    height: '164px',
  },
}));

export default function GoogleImageCard({
  disable,
  imgSrc,
  buttonTxt,
  onClick,
}: CardProps): ReactElement {
  const classes = useStyles();
  return (
    <Card
      sx={{
        width: '100%',
        padding: '20px',
        backgroundColor: '#F9F9F9',
      }}
    >
      <CardContent>
        <div className={classes.imgDiv}>
          <div>
            <Box
              component="img"
              sx={{
                height: 140,
                width: 296,
              }}
              style={{ height: '100px' }}
              src={imgSrc || '/Frame.png'}
            />
            <div>
              <Button
                disabled={disable}
                onClick={onClick}
                style={{ display: 'block' }}
                sx={{
                  width: '160px',
                  fontFamily: 'poppins',
                  color: '#0CB4A5',
                  lineHeight: '24px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: '16px',
                  background: '#FFFFFF',
                  border: '1px solid #0CB4A5',
                  borderRadius: '4px',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  marginTop: '-17px',
                }}
                size="large"
              >
                {buttonTxt}
              </Button>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}
