import React from 'react';

function ShoppingIcon({ fill }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill={fill}
    >
      <path
        d="M6.56097 8.99999L5.79268 6H4L6.56097 8.99999ZM6.56097 8.99999L9.12194 19H22.439L25 8.99999H6.56097Z"
        stroke="#011531"
        strokeOpacity="0.4"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.5 25C9.32843 25 10 24.3284 10 23.5C10 22.6716 9.32843 22 8.5 22C7.67157 22 7 22.6716 7 23.5C7 24.3284 7.67157 25 8.5 25Z"
        stroke="#011531"
        strokeOpacity="0.4"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.5 25C23.3284 25 24 24.3284 24 23.5C24 22.6716 23.3284 22 22.5 22C21.6716 22 21 22.6716 21 23.5C21 24.3284 21.6716 25 22.5 25Z"
        stroke="#011531"
        strokeOpacity="0.4"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ShoppingIcon;
