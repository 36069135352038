import React from 'react';

function Location() {
  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
    >
      <path
        fill="#011531"
        d="M9.382.003L8.526 0l-.008.006A8.993 8.993 0 000 9a8.994 8.994 0 008.519 8.997l.864.002A8.973 8.973 0 0018 9.001 8.973 8.973 0 009.383.003zm6.36 4.154a8.236 8.236 0 011.561 4.786l-3.253.001a11.109 11.109 0 00-.57-3.466 10.91 10.91 0 002.263-1.321zm-.429-.549c-.643.487-1.34.894-2.073 1.216A10.9 10.9 0 0010.393.808a8.264 8.264 0 014.92 2.8zM9.348.847a10.226 10.226 0 013.245 4.234 10.204 10.204 0 01-3.245.598V.847zm0 5.53a10.944 10.944 0 003.485-.642 10.4 10.4 0 01.522 3.21l-4.007.002v-2.57zm0 3.266l3.985-.001a10.307 10.307 0 01-.675 3.115 11.123 11.123 0 00-3.31-.569V9.643zM7.488.83a10.92 10.92 0 00-2.766 3.859 10.204 10.204 0 01-1.93-1.199A8.291 8.291 0 017.488.83zM2.35 4.03c.657.518 1.368.958 2.118 1.31a11.103 11.103 0 00-.615 3.61l-3.158.003A8.225 8.225 0 012.35 4.03zm.264 10.278A8.224 8.224 0 01.723 9.648l3.152-.002c.068 1.208.327 2.378.77 3.483-.72.318-1.403.714-2.03 1.178zm.468.518c.57-.415 1.189-.77 1.841-1.058a10.78 10.78 0 002.562 3.406 8.332 8.332 0 01-4.403-2.348zm5.569 2.403a10.29 10.29 0 01-3.08-3.723 10.39 10.39 0 013.08-.613v4.336zm0-5.032a11.08 11.08 0 00-3.362.67 10.271 10.271 0 01-.718-3.22l4.08-.002v2.552zm0-3.25L4.55 8.95c.006-1.145.194-2.265.56-3.334 1.13.446 2.32.7 3.542.752v2.578zm0-3.275a10.244 10.244 0 01-3.292-.705A10.245 10.245 0 018.652.773v4.898zm.696 11.485v-4.273c1.038.022 2.059.195 3.037.516a10.283 10.283 0 01-3.037 3.757zm1.046.038a10.767 10.767 0 002.649-3.554c.7.285 1.365.646 1.98 1.076a8.293 8.293 0 01-4.629 2.478zm5.09-3.004a10.724 10.724 0 00-2.173-1.191c.412-1.068.654-2.196.719-3.358l3.248-.002a8.242 8.242 0 01-1.795 4.55z"
      />
    </svg>
  );
}

export default Location;
