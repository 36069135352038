/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
import './Sidebar.css';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { axiosInstance } from '../../axiosInstance';
import Bundles from '../../Icons/Bundles';
import Clients from '../../Icons/Clients';
import ClientsBig from '../../Icons/ClientsBig';
import Edit from '../../Icons/Edit';
import Location from '../../Icons/Location';
import LogoSm from '../../Icons/LogoSm';
import Logout from '../../Icons/Logout';
import { Client, useClient } from '../clients/hooks/useClients';

const drawerWidth = 300;

interface SidebarProps {
  client: Client | undefined;
  pageTitle: string;
}

export default function Sidebar({ client, pageTitle }: SidebarProps) {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="relative"
        sx={{
          width: '250px',
          ml: `${drawerWidth}px`,
          background: 'unset',
          boxShadow: 'unset',
          color: '#011531',
        }}
      >
        <Toolbar>
          <Typography variant="h6" noWrap component="div">
            <ClientsBig />
            <span style={{ marginLeft: '5px' }}>{pageTitle}</span>
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <div
          style={{
            display: 'flex',
            gap: '10px',
            margin: '40px',
          }}
        >
          <div className="logo-sidebar">
            <LogoSm />
          </div>
          <div style={{ textAlign: 'left', alignSelf: 'center' }}>
            <div className="logo-name">Guideful</div>
            <div className="logo-cms">CMS</div>
          </div>
        </div>
        {client && (
          <List>
            <Link
              to="/clients"
              state={{ client }}
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              <ListItem key="Clients">
                <ListItemButton>
                  <ListItemIcon>
                    <Clients />
                  </ListItemIcon>
                  <ListItemText primary="Clients" className="lise-text" />
                </ListItemButton>
              </ListItem>
            </Link>
            <Link
              to={`/clients/${client?.clientName}/edit`}
              state={{ client }}
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              <ListItem key={`Edit ${client?.clientName}`}>
                <ListItemButton>
                  <ListItemIcon>
                    <Edit />
                  </ListItemIcon>
                  <ListItemText
                    primary={`Edit ${client?.clientName}`}
                    className="lise-text"
                  />
                </ListItemButton>
              </ListItem>
            </Link>
          </List>
        )}
        <div style={{ padding: '0 40px' }}>
          <Divider />
        </div>
        {client && (
          <List>
            <Link
              to={`/clients/${client?.clientName}/locations`}
              onClick={(e) =>
                location.pathname.includes('locations')
                  ? e.preventDefault()
                  : null
              }
              state={{ client }}
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              <ListItem key="Locations">
                <ListItemButton>
                  <ListItemIcon>
                    <Location />
                  </ListItemIcon>
                  <ListItemText primary="Locations" className="lise-text" />
                </ListItemButton>
              </ListItem>
            </Link>
            <Link
              to={`/clients/${client?.clientName}/bundles`}
              onClick={(e) =>
                location.pathname.includes('edit') ||
                location.pathname.includes('new')
                  ? e.preventDefault()
                  : null
              }
              state={{ client }}
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              <ListItem key="Bundles">
                <ListItemButton>
                  <ListItemIcon>
                    <Bundles />
                  </ListItemIcon>
                  <ListItemText primary="Bundles" className="lise-text" />
                </ListItemButton>
              </ListItem>
            </Link>
          </List>
        )}
        <Toolbar sx={{ marginTop: '200px' }}>
          <Button
            sx={{ width: '100%', bgcolor: '#F0F0F0', borderRadius: '4px' }}
            className="logout-btn"
            onClick={() => {
              navigate('/');
              axiosInstance.defaults.headers.common.Authorization = undefined;
            }}
          >
            <span style={{ marginRight: '10px', marginTop: '7px' }}>
              <Logout />
            </span>
            Log Out
          </Button>
        </Toolbar>
      </Drawer>
    </Box>
  );
}
