/* eslint-disable @typescript-eslint/no-unused-vars */
import { ErrorMessage } from '@hookform/error-message';
import { InputLabel, makeStyles } from '@material-ui/core';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { ReactElement, useEffect, useState } from 'react';
import { Control, Controller, FieldErrorsImpl } from 'react-hook-form';

import UploadFile from '../common/UploadFile';

interface BasicFormProps {
  control: Control<{
    bundleUUID: string;
    title: string;
    date: string;
    shorex: string;
    bannerUrl: string;
  }>;
  errors: Partial<
    FieldErrorsImpl<{
      bundleUUID: string;
      title: string;
      date: string;
      shorex: string;
    }>
  >;
}

const useStyles = makeStyles(() => ({
  label: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 'lighter',
    fontFamily: 'Poppins',
    textAlign: 'left',
  },
  innerDiv: {
    display: 'grid',
    gridGap: '10px',
    'grid-template-columns': 'repeat(auto-fill, minmax(122px, 300px))',
    maxWidth: '100%',
  },
  gridDiv: {
    alignItems: 'start!important' as any,
    display: 'flex',
    flexDirection: 'column!important' as any,
    width: '100%',
  },
  titleDiv: {
    textAlign: 'left',
    marginBottom: '10px',
    marginLeft: '0px',
  },
}));

export default function AddBundleForm({
  control,
  errors,
}: BasicFormProps): ReactElement {
  const classes = useStyles();
  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', width: '100%', mt: 10 }}>
      <div style={{ width: '70%' }}>
        <div className={classes.innerDiv}>
          <div>
            <InputLabel className={classes.label} shrink>
              *Bundle code :
            </InputLabel>
            <Controller
              name="bundleUUID"
              control={control}
              render={({ field: { onChange, value }, formState }) => (
                <TextField
                  style={{ display: 'flex' }}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </div>
          <ErrorMessage errors={errors} name="bundleUUID" as="p" />
          <div>
            <InputLabel className={classes.label} shrink>
              *Bundle Title :
            </InputLabel>
            <Controller
              name="title"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  style={{ display: 'flex' }}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </div>
          <ErrorMessage errors={errors} name="title" as="p" />
          <div>
            <InputLabel className={classes.label} shrink>
              *Departure Date :{' '}
            </InputLabel>
            <Controller
              name="date"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  style={{ display: 'flex' }}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </div>
          <div>
            <InputLabel className={classes.label} shrink>
              Shorex\ Bookings URL :{' '}
            </InputLabel>
            <Controller
              name="shorex"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  style={{ display: 'flex' }}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </div>
          <div>
            <InputLabel className={classes.label} shrink>
              Closing banner url :
            </InputLabel>
            <Controller
              name="bannerUrl"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  style={{ display: 'flex' }}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </div>
        </div>
      </div>
      <div>
        <div>
          <div className={classes.label}>Closing banner image:</div>
          <UploadFile
            id="shorex"
            isFrom="bundle"
            img=""
            onChangeImage={() => console.log('test')}
          />
        </div>
      </div>
    </Box>
  );
}
