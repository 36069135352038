import React from 'react';

function ClientsBig() {
  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="22"
      fill="none"
      viewBox="0 0 25 22"
    >
      <path
        stroke="#011531"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12.711 9.955a1.835 1.835 0 00-.333 0 4.462 4.462 0 01-4.31-4.472A4.479 4.479 0 0112.55 1a4.478 4.478 0 01.162 8.955zM19.304 3.02a3.53 3.53 0 013.533 3.533 3.538 3.538 0 01-3.402 3.533 1.14 1.14 0 00-.262 0M7.88 13.68c-2.443 1.636-2.443 4.301 0 5.927 2.777 1.857 7.33 1.857 10.106 0 2.444-1.636 2.444-4.301 0-5.926-2.766-1.848-7.32-1.848-10.106 0zM21.192 19.173c.727-.152 1.413-.445 1.979-.879 1.575-1.18 1.575-3.13 0-4.31-.556-.425-1.232-.707-1.949-.869M6.109 3.02a3.531 3.531 0 00-3.534 3.533 3.538 3.538 0 003.402 3.533c.081-.01.172-.01.263 0M4.16 19.173c-.727-.152-1.413-.445-1.979-.879-1.575-1.18-1.575-3.13 0-4.31.556-.425 1.232-.707 1.949-.869"
      />
    </svg>
  );
}

export default ClientsBig;
