import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { Book } from '../../../interfaces/Book';
import GoogleImageCard from '../../common/GoogleImageCard';
import SelectImageTypeTabs from '../../common/SelectImageTypeTabs';
import UploadImageCard from '../../common/UploadImageCard';
import {
  googleApiKey,
  Photo,
  photosApiUrl,
} from '../../locations/hooks/useGoogleMaps';
// import { Place } from '../../locations/Searchbar';
import WrapperMap from '../../maps/WrapperMap';
import EditBookingForm from '../editBookingPopup/EditBookingForm';
import ImagesPopup from '../imagesPopup/ImagesPopup';

interface EditItemPopupProps {
  onAddingBooking: (book: Book) => void;
  open: boolean;
  onClose: () => void;
}

const schema = yup
  .object({
    title: yup.string().required(),
    description: yup.string().required(),
    tripLentgh: yup.string().required(),
    redirectionUrl: yup.string().required(),
  })
  .required();

enum ImageType {
  GoogleImage,
  SimpleImage,
}

export default function EditBookingPopup({
  onAddingBooking,
  open,
  onClose,
}: EditItemPopupProps) {
  const [images, setImages] = useState<Photo[]>([]);
  const [imagesPopupOpen, setImagesPopupOpen] = useState<boolean>(false);
  const [customSelectedImage, setCustomSelectedImage] = useState<string>('');
  const [selectedTab, setSelectedTab] = useState<ImageType>(
    ImageType.GoogleImage,
  );
  const initialBookingImageRef = {
    title: '',
    url: '',
  };

  const [bookingImageRef, setBookingImageRef] = useState(
    initialBookingImageRef,
  );

  const [googleSelectedImage, setGoogleSelectedImage] = useState<string>('');
  const { handleSubmit, control, getValues, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      title: '',
      description: '',
      tripLentgh: '',
      redirectionUrl: '',
    },
  });

  const onSubmit = () => {
    onAddingBooking({
      ...getValues(),
      imageUrl: selectedTab ? customSelectedImage : googleSelectedImage,
      bookingImageRef: selectedTab ? initialBookingImageRef : bookingImageRef,
    });
    reset();
  };

  const handleImageRef = (index: number) => {
    setGoogleSelectedImage(
      `${photosApiUrl}=${images[index].photo_reference}&key=${googleApiKey}`,
    );
    setBookingImageRef({
      title: images[index].pageImageRef?.title || bookingImageRef?.title,
      url: images[index].pageImageRef?.url || bookingImageRef?.url,
    });
  };

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="xl"
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Add Booking</DialogTitle>
        <EditBookingForm control={control} />
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            margin: 4,
          }}
        >
          <WrapperMap
            onSelectLocation={(photos: Photo[]) => setImages(photos)}
            showLanLng={false}
          />
          <div style={{ width: '50%', marginTop: 70 }}>
            <SelectImageTypeTabs
              tab={selectedTab}
              onTabChanged={(tab: number) => setSelectedTab(tab)}
            />
            {selectedTab ? (
              <UploadImageCard
                img={customSelectedImage}
                onChangeImage={(imgsrc) => setCustomSelectedImage(imgsrc)}
              />
            ) : (
              <GoogleImageCard
                disable={!images.length}
                onClick={() => setImagesPopupOpen(true)}
                title="depultative google"
                imgSrc={googleSelectedImage}
                buttonTxt="change image"
              />
            )}
          </div>
        </div>
        <ImagesPopup
          onSelected={handleImageRef}
          open={imagesPopupOpen}
          onClose={() => setImagesPopupOpen(false)}
          images={images}
        />
        <DialogActions>
          <Button onClick={handleSubmit(onSubmit)}>save</Button>
          <Button onClick={() => onClose()} autoFocus>
            cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
