import React from 'react';

function EditPencil() {
  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.16393 4.05309L1.43325 10.4824C1.36757 10.5561 1.32517 10.6476 1.31139 10.7453L0.864806 13.9142C0.811232 14.2944 1.18833 14.5906 1.54497 14.4485L4.27595 13.3604C4.34806 13.3317 4.41249 13.2866 4.46414 13.2286L10.217 6.77441M7.16393 4.05309L9.55256 1.37325C9.7363 1.16711 10.0524 1.14895 10.2585 1.33269L12.5651 3.38863C12.7712 3.57237 12.7894 3.88844 12.6056 4.09458L10.217 6.77441M7.16393 4.05309L10.217 6.77441"
        stroke="#011531"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default EditPencil;
