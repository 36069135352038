import { Box, makeStyles } from '@material-ui/core';
import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';
import { ReactElement } from 'react';

import CloseIcon from '../../Icons/CloseIcon';
import { shortenLocation } from './hooks/useBundle';

interface SelectedLocationsProps {
  selectedLocations: shortenLocation[];
  onDeleteLocation: (locationId: string) => void;
}

const ListItem = styled('li')(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const useStyles = makeStyles(() => ({
  boxStyle: {
    listStyle: 'none',
    display: 'flex',
    flexWrap: 'wrap',
    padding: '0px',
  },
  divStyle: {
    backgroundColor: '#0cb4a51a!important' as any,
    border: '1px solid #0CB4A5!important' as any,
    borderRadius: '4px!important' as any,
    color: '#0BBBA3!important' as any,
    padding: '6px!important' as any,
  },
}));

export default function SelectedLocations({
  selectedLocations,
  onDeleteLocation,
}: SelectedLocationsProps): ReactElement {
  const handleDelete = (chipToDelete: shortenLocation) => () => {
    onDeleteLocation(chipToDelete.id);
  };

  const classes = useStyles();
  return (
    <Box
      className={classes.boxStyle}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        p: 0.5,
        m: 0,
        padding: '0px',
      }}
      component="ul"
    >
      {selectedLocations.map((data, index) => {
        return (
          <ListItem key={data.id}>
            <Chip
              className={classes.divStyle}
              icon={<CloseIcon />}
              label={`${index + 1}. ${data.title}`}
              onDelete={handleDelete(data)}
            />
          </ListItem>
        );
      })}
    </Box>
  );
}
