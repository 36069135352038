import axios, { AxiosRequestConfig } from 'axios';

import { SERVICE_URI } from '../consts';

const config: AxiosRequestConfig = {
  baseURL: SERVICE_URI,
  headers: {
    'access-control-allow-origin': '*',
  },
};

export const axiosInstance = axios.create(config);

const setAccessToken = (accessToken: string) => {
  axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
};

const getAccessTokenFromStorage = () => {
  return localStorage.getItem('accessToken');
};

const accessToken = getAccessTokenFromStorage();
if (accessToken) {
  setAccessToken(accessToken);
}
