import { makeStyles } from '@material-ui/core';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import * as React from 'react';
import { ReactElement } from 'react';

import { typesArr } from '../../interfaces/Type';

const useStyles = makeStyles(() => ({
  createTypeBtn: {
    fontSize: '16px!important' as '16px',
    lineHeight: '24px!important' as '24px',
    fontWeight: 'normal!important' as 'normal',
    fontFamily: 'Poppins!important' as 'Poppins',
    textTransform: 'initial!important' as 'initial',
    background: '#0CE1B7!important' as '#0CE1B7',
    borderRadius: '4px!important' as '4px',
    color: '#011531!important' as '#011531',
    width: '180px',
    height: '50px',
  },
}));

interface CreateTypeProps {
  onSelect: (typeTitle: string) => void;
}

export default function CreateType({
  onSelect,
}: CreateTypeProps): ReactElement {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const classes = useStyles();
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (typeTitle: string) => {
    onSelect(typeTitle);
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        className={classes.createTypeBtn}
      >
        Create Type
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {typesArr.map((type) => (
          <MenuItem
            sx={{ width: '180px' }}
            key={type}
            onClick={() => handleSelect(type)}
          >
            {type}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
