import React from 'react';

export default function LikeIcon() {
  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
    >
      <path
        d="M17.6121 2.41451C17.1722 1.96607 16.65 1.61034 16.0752 1.36763C15.5005 1.12492 14.8845 1 14.2623 1C13.6402 1 13.0241 1.12492 12.4493 1.36763C11.8746 1.61034 11.3524 1.96607 10.9126 2.41451L9.99979 3.34475L9.087 2.41451C8.19859 1.50911 6.99365 1.00047 5.73725 1.00047C4.48086 1.00047 3.27592 1.50911 2.38751 2.41451C1.4991 3.31992 1 4.5479 1 5.82832C1 7.10872 1.4991 8.33673 2.38751 9.24214L3.30029 10.1724L9.99979 17L16.6993 10.1724L17.6121 9.24214C18.0521 8.79386 18.4011 8.2617 18.6393 7.67599C18.8775 7.09021 19 6.46237 19 5.82832C19 5.19429 18.8775 4.56645 18.6393 3.9807C18.4011 3.39494 18.0521 2.86275 17.6121 2.41451Z"
        stroke="#0CB4A5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
