import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { Item } from '../../../../interfaces/Item';
import { queryKeys } from '../../../../react-query/consts';
import { queryClient } from '../../../../react-query/queryClient';
import { addTypesAndItems, fetchPage } from '../apis';
import { UseCreateType } from '../interfaces/UseCreateType.interface';

export function useCreateType(
  locationId: string,
  client: { clientName: string; clientId: string },
): UseCreateType {
  const { data: page = {} } = useQuery([queryKeys.page, locationId], () =>
    fetchPage(locationId),
  );

  const navigate = useNavigate();
  const [addItemPopupOpen, setAddItemPopupOpen] = useState<boolean>(false);
  const [editItemPopupOpen, setEditItemPopupOpen] = useState<boolean>(false);
  const [deleteItemPopupOpen, setDeleteItemPopupOpen] =
    useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState('');
  const [editItemPopupProps, setEditItemPopupProps] = useState<
    Item | undefined
  >(undefined);

  const { _id: pageId } = page;
  const [currentTab, setCurrentTab] = useState('');
  const [tabIndex, setTabIndex] = useState<number>(-1);

  useEffect(() => {
    setTabIndex(page?.types?.length ? 0 : tabIndex);
    setCurrentTab(
      page?.types?.length && page?.types[0]?.typeTitle
        ? page?.types[0]?.typeTitle
        : '',
    );
  }, [page]);

  const handleNewType = (typeTitle: string) => {
    setCurrentTab(currentTab === '' ? typeTitle : currentTab);
    setTabIndex(tabIndex === -1 ? 0 : tabIndex);
    const exist = page?.types.find(
      ({ typeTitle: title }: { typeTitle: string }) => title === typeTitle,
    );

    page.types = exist
      ? page?.types
      : [...page.types, { typeTitle, typeDescription: '', items: [], pageId }];

    queryClient.setQueryData([queryKeys.page, locationId], page);
  };

  const handleTabSelected = (typeTitle?: string) => {
    setCurrentTab(typeTitle || currentTab);
    const newIndex = page.types.findIndex(
      ({ typeTitle: title }: { typeTitle: string }) => title === typeTitle,
    );
    setTabIndex(newIndex);
  };

  const handleSubmit = () => {
    const { types = [] } = page;
    addTypesAndItemsMutation({ pageId, types });
  };

  const handleEditItem = (itemTitle: string) => {
    const indexOfType = page?.types.findIndex(
      ({ typeTitle }: { typeTitle: string }) => typeTitle === currentTab,
    );
    const item = page?.types[indexOfType].items.find(
      ({ title }: { title: string }) => title === itemTitle,
    );
    setEditItemPopupProps(item);
    setEditItemPopupOpen(true);
  };

  const handleDecition = (toDelete: boolean) => {
    const indexOfType = page?.types.findIndex(
      ({ typeTitle }: { typeTitle: string }) => typeTitle === currentTab,
    );
    if (toDelete && indexOfType !== -1) {
      page.types[indexOfType].items = page?.types[indexOfType].items.filter(
        ({ title }: { title: string }) => title !== selectedItem,
      );
    }
    setSelectedItem('');
  };

  const handleDeleteItem = (itemTitle: string) => {
    setSelectedItem(itemTitle);
    setDeleteItemPopupOpen(true);
  };

  const saveEditedItem = (editedItem: Item) => {
    const indexOfType = page?.types.findIndex(
      ({ typeTitle }: { typeTitle: string }) => typeTitle === currentTab,
    );
    const indexOfItem = page?.types[indexOfType].items.findIndex(
      ({ title }: { title: string }) => title === editItemPopupProps?.title,
    );
    page.types[indexOfType].items[indexOfItem] = editedItem;
    setEditItemPopupProps(undefined);
    setEditItemPopupOpen(false);
  };

  const saveNewItem = (newItemElement: any) => {
    const targetType = page?.types.findIndex(
      ({ typeTitle }: { typeTitle: string }) =>
        typeTitle === newItemElement.selectedType,
    );

    if (targetType !== -1) {
      page.types[targetType].items.push({ ...newItemElement, pageId });
      setAddItemPopupOpen(false);
      queryClient.setQueryData([queryKeys.page, locationId], page);
    }
  };

  const handleCancelEdit = () => {
    setEditItemPopupOpen(false);
    setEditItemPopupProps(undefined);
  };

  const handleDescriptionChange = (txt: string) => {
    const typeIndex = page?.types.findIndex(
      ({ typeTitle }: { typeTitle: string }) => typeTitle === currentTab,
    );
    page.types[typeIndex].typeDescription = txt;
  };

  const handleItemPublishMode = (itemTitle: string) => {
    const typeIndex = page?.types.findIndex(
      ({ typeTitle }: { typeTitle: string }) => typeTitle === currentTab,
    );

    const itemIndex = page?.types[typeIndex].items.findIndex(
      ({ title }: { title: string }) => title === itemTitle,
    );

    if (typeIndex !== -1 && itemIndex !== -1) {
      page.types[typeIndex].items[itemIndex].published =
        !page.types[typeIndex].items[itemIndex].published;

      queryClient.setQueryData([queryKeys.page, locationId], page);
    }
  };

  const { mutate: addTypesAndItemsMutation } = useMutation(addTypesAndItems, {
    onSettled: () => {
      const { clientName } = client;
      queryClient.invalidateQueries([queryKeys.page, locationId]);
      navigate(`../clients/${clientName}/locations/edit/step1/step2/step3`, {
        replace: false,
        state: {
          locationId,
          client,
        },
      });
    },
  });

  return {
    page,
    addTypesAndItemsMutation,
    addItemPopupOpen,
    setAddItemPopupOpen,
    editItemPopupOpen,
    setEditItemPopupOpen,
    deleteItemPopupOpen,
    setDeleteItemPopupOpen,
    selectedItem,
    setSelectedItem,
    editItemPopupProps,
    setEditItemPopupProps,
    tabIndex,
    handleNewType,
    handleTabSelected,
    handleSubmit,
    handleEditItem,
    handleDecition,
    handleDeleteItem,
    saveEditedItem,
    saveNewItem,
    handleCancelEdit,
    handleDescriptionChange,
    handleItemPublishMode,
  };
}
