function UpDownArrow() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
    >
      <g clipPath="url(#clip0_925_2496)">
        <path
          d="M15.833 3.95825L13.458 1.58325L11.083 3.95825"
          stroke="#011531"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.91699 15.0417L5.54199 17.4167L3.16699 15.0417"
          stroke="#011531"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.458 1.58325L13.458 17.4166"
          stroke="#011531"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.54199 1.58325L5.54199 17.4166"
          stroke="#011531"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_925_2496">
          <rect
            width="19"
            height="19"
            fill="white"
            transform="translate(19) rotate(90)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
export default UpDownArrow;
