import { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';

import { axiosInstance } from '../axiosInstance';

interface ProtectedRouteProps {
  children: ReactElement;
}

export default function ProtectedRoute({
  children,
}: ProtectedRouteProps): ReactElement {
  const user = axiosInstance.defaults.headers.common.Authorization;
  if (!user) {
    return <Navigate to="/" replace />;
  }
  return children;
}
