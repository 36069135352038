import '../../clients/addClient.css';

import { Box, makeStyles } from '@material-ui/core';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ReactElement } from 'react';

import HandIcon from '../../../Icons/HandIcon';

interface ExitPopupProps {
  open: boolean;
  onClose: () => void;
  onDecide: (toSave: boolean) => void;
}

const useStyles = makeStyles(() => ({
  titleDiv: {
    textAlign: 'center',
    marginTop: '20px!important' as '20px',
  },
  waitMin: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '28px',
    lineHeight: '42px',
    color: '#EC088C',
  },
  spanDiv: {
    fontWeight: 700,
  },
  waitMinText: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '22px',
    lineHeight: '33px',
    color: '#011531',
  },
  btnStyleCancle: {
    fontSize: '16px!important' as '16px',
    lineHeight: '24px!important' as '24px',
    fontWeight: 'normal!important' as 'normal',
    fontFamily: 'Poppins!important' as 'Poppins',
    textTransform: 'initial!important' as 'initial',
    background: '#0CE1B7',
    color: '#011531!important' as '#011531',
    borderRadius: '4px!important' as '4px',
    border: '1px solid #011531!important' as '1px solid #011531',
    width: '180px',
    height: '50px',
  },
  btnStyleSave: {
    fontSize: '16px!important' as '16px',
    lineHeight: '24px!important' as '24px',
    fontWeight: 'normal!important' as 'normal',
    fontFamily: 'Poppins!important' as 'Poppins',
    textTransform: 'initial!important' as 'initial',
    background: '#0CE1B7!important' as '#0CE1B7',
    borderRadius: '4px!important' as '4px',
    color: '#011531!important' as '#011531',
    width: '180px',
    height: '50px',
  },
  footerDiv: {
    justifyContent: 'center!important' as 'center',
    marginBottom: '30px',
  },
  descDiv: {
    padding: '20px 48px!important' as '20px 48px',
    paddingTop: '0px!important' as '0px',
  },
}));

export default function ExitPopup({
  open,
  onClose,
  onDecide,
}: ExitPopupProps): ReactElement {
  const classes = useStyles();
  return (
    <div>
      <Dialog
        open={open}
        onClose={() => onClose()}
        className="exit-popup"
        style={{ maxWidth: '610px', margin: 'auto', borderRadius: '20px' }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className={classes.titleDiv} id="alert-dialog-title">
          <div>
            <HandIcon />
          </div>
          <div className={classes.waitMin}>Wait a minute!</div>
        </DialogTitle>
        <DialogContent className={classes.descDiv}>
          <DialogContentText id="alert-dialog-description">
            <span className={classes.waitMinText}>
              You are about to exit in the middle of the content editing
              process,<span className={classes.spanDiv}> Are you sure?</span>
            </span>
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.footerDiv}>
          <Box mt="20px" mb="50px" style={{ textAlign: 'right' }}>
            <Box style={{ marginRight: '20px' }} component="span">
              <Button
                className={classes.btnStyleSave}
                onClick={() => onDecide(true)}
              >
                Exit and save
              </Button>
            </Box>
            <Box ml={10} component="span">
              <Button
                className={classes.btnStyleCancle}
                onClick={() => onDecide(false)}
              >
                Exit without saving
              </Button>
            </Box>
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
}
