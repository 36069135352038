import { Input, InputAdornment, makeStyles } from '@material-ui/core';
import { Box, Grid } from '@mui/material';
import Fab from '@mui/material/Fab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import DeleteIcon from '../../Icons/DeleteIcon';
import EditPencilGreen from '../../Icons/EditPencilGreen';
import LikeIcon from '../../Icons/LikeIcon';
import { Search } from '../../Icons/Search';
import { Bundle } from '../../interfaces/Bundle';
import SelectSort from '../common/SelectSort';

function createData(
  id: string,
  title: string,
  pages: string,
  updatedAt: string,
) {
  return { id, title, pages, updatedAt };
}

interface BundleTableProp {
  bundles: Bundle[];
  client: {
    clientId: string;
    clientName: string;
  };
  onDeleteBundle: (bundleId: string) => void;
}

export enum SortType {
  none,
  fromNewestToOldest,
  fromOldestToNewest,
  alphabetical,
}

const useStyles = makeStyles(() => ({
  mainDiv: {
    maxWidth: '1100px',
    margin: '38px auto',
  },
  searchBox: {
    width: '100%',
    background: '#FFFFFF',
    border: ' 0.5px solid rgba(1, 21, 49, 0.4)',
    borderRadius: '4px',
    padding: '11px 22px',
  },
  sortBy: {
    padding: '6px',
  },
  tableRow: {
    background: '#FFFFFF',
    borderSpacing: '0px 15px',
  },
  tableRowHead: {
    background: 'unset',
    borderSpacing: '0px 15px',
  },
  tableHead: {
    borderSpacing: '0px 15px!important' as any,
  },
  tableHeader: {
    background: 'unset',
  },
  rowTitle: {
    fontSize: '16px!important' as any,
    lineHeight: '24px!important' as any,
    fontWeight: '400!important' as any,
    fontFamily: 'Poppins!important' as any,
    textTransform: 'capitalize!important' as any,
    color: '#011531!important' as any,
  },
  cell: {
    fontSize: '14px!important' as any,
    lineHeight: '21px!important' as any,
    fontWeight: '400!important' as any,
    fontFamily: 'Poppins!important' as any,
    textTransform: 'capitalize!important' as any,
    color: '#011531!important' as any,
    opacity: '0.4',
  },
  elipsisCell: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    '-webkit-line-clamp': '1',
    '-webkit-box-orient': 'vertical',
    maxWidth: '200px',
    display: '-webkit-box!important' as any,
  },
  fabStyle: {
    boxShadow: 'none!important' as any,
    background: 'none!important' as any,
  },
  tableDiv: {
    backgroundColor: 'unset!important' as any,
    boxShadow: 'none!important' as any,
  },
}));

export default function BundlesTable({
  bundles,
  client,
  onDeleteBundle,
}: BundleTableProp) {
  const navigate = useNavigate();
  const [sortType, setSortType] = React.useState('');
  const [searchText, setSearchText] = React.useState('');
  const rows = bundles.map((bundle) => {
    const { title, pages: pagesProps, updatedAt, _id: id } = bundle;
    const pages = pagesProps.map(({ title: pageTitle }) => pageTitle).join();
    return createData(id, title, pages, updatedAt.toString());
  });

  const classes = useStyles();

  const handleSort = (
    a: { id: string; title: string; pages: string; updatedAt: string },
    b: { id: string; title: string; pages: string; updatedAt: string },
  ) => {
    if (sortType === 'alphabetical') {
      return a.title.localeCompare(b.title);
    }
    if (sortType === 'fromNewestToOldest') {
      return Date.parse(b.updatedAt) - Date.parse(a.updatedAt);
    }
    if (sortType === 'fromOldestToNewest') {
      return Date.parse(a.updatedAt) - Date.parse(b.updatedAt);
    }
    return 0;
  };

  return (
    <>
      <div className={classes.mainDiv}>
        <Box width="90%" overflow="hidden" m={1.5}>
          <Grid container spacing={5} rowSpacing={3.5}>
            <Grid item lg={9} md={9} xs={12}>
              <Input
                className={classes.searchBox}
                startAdornment={
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                }
                disableUnderline
                placeholder="Search"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setSearchText(event.target.value)
                }
                value={searchText}
              />
            </Grid>
            <Grid item lg={3} md={3} xs={12}>
              <SelectSort
                sortType={sortType}
                onChange={(type: string) => setSortType(SortType[Number(type)])}
              />
            </Grid>
          </Grid>
        </Box>
      </div>
      <Box className={classes.tableDiv} sx={{ width: '90%', m: 1.5 }}>
        <TableContainer className={classes.tableDiv}>
          <Table
            className={classes.tableHead}
            stickyHeader
            aria-label="sticky table"
          >
            <TableHead className={classes.tableHead}>
              <TableRow className={classes.tableRowHead}>
                <TableCell
                  key="status"
                  align="center"
                  className={classes.cell}
                  style={{ minWidth: 170 }}
                >
                  Bundle Code
                </TableCell>
                <TableCell
                  key="bundle"
                  align="center"
                  className={classes.cell}
                  style={{ minWidth: 170, maxWidth: 300 }}
                >
                  Locations included in Bundle
                </TableCell>
                <TableCell
                  key="update"
                  align="center"
                  className={classes.cell}
                  style={{ minWidth: 170 }}
                >
                  last updated
                </TableCell>
                <TableCell
                  key="action"
                  align="center"
                  className={classes.cell}
                  style={{ minWidth: 170 }}
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={classes.tableRow}>
              {rows
                .filter(({ title }) =>
                  title?.toLowerCase().includes(searchText),
                )
                .sort(handleSort)
                .map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      <TableCell className={classes.rowTitle} align="center">
                        <span className={classes.elipsisCell}>{row.title}</span>
                      </TableCell>
                      <TableCell className={classes.cell} align="center">
                        <span className={classes.elipsisCell}>{row.pages}</span>
                      </TableCell>
                      <TableCell className={classes.cell} align="center">
                        {row.updatedAt.slice(0, 10)}
                      </TableCell>
                      <TableCell align="center">
                        <Fab
                          className={classes.fabStyle}
                          onClick={() =>
                            navigate(
                              `/clients/${client.clientName}/bundles/edit`,
                              {
                                replace: true,
                                state: {
                                  client,
                                  bundle: bundles.find(
                                    ({ _id }) => _id === row.id,
                                  ),
                                },
                              },
                            )
                          }
                          size="small"
                          aria-label="edit"
                        >
                          <EditPencilGreen />
                        </Fab>
                        <Fab
                          className={classes.fabStyle}
                          size="small"
                          aria-label="edit"
                          onClick={() => onDeleteBundle(row.id)}
                        >
                          <DeleteIcon />
                        </Fab>
                        <Fab
                          className={classes.fabStyle}
                          size="small"
                          onClick={() => console.log('bla')}
                          aria-label="edit"
                        >
                          <LikeIcon />
                        </Fab>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
}
