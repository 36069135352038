/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
import './images.css';

import { Box, makeStyles } from '@material-ui/core';
import Avatar from '@mui/joy/Avatar';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Radio from '@mui/joy/Radio';
import RadioGroup from '@mui/joy/RadioGroup';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import Grid from '@mui/material/Grid';
import { on } from 'events';
import { ReactElement, useEffect, useRef, useState } from 'react';

import {
  googleApiKey,
  Photo,
  photosApiUrl,
} from '../../locations/hooks/useGoogleMaps';

interface MapProps {
  onSelect: (index: number) => void;
  photos: Photo[];
}

const useStyles = makeStyles(() => ({
  radioGrp: {
    flexWrap: 'wrap!important' as any,
    display: 'flex!important' as any,
    flexDirection: 'row!important' as any,
  },
  labelDiv: {
    flexDirection: 'row!important' as any,
    borderRadius: '4px!important' as any,
  },
  spanDiv: {
    top: '50%',
    position: 'absolute!important' as any,
  },
}));

export default function Images({
  photos = [],
  onSelect,
}: MapProps): ReactElement {
  const classes = useStyles();
  return (
    <FormControl>
      <RadioGroup
        overlay
        name="member"
        defaultValue={0}
        className={classes.radioGrp}
        onChange={(e) => onSelect(Number(e.target.value))}
        sx={{ gap: 2, mt: 1 }}
      >
        {photos.length &&
          photos.map((photo, index) => (
            <Sheet
              component="label"
              className={classes.labelDiv}
              key={photo.photo_reference}
              variant="outlined"
              sx={{
                p: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                boxShadow: 'sm',
                borderRadius: 'md',
                bgcolor: 'background.body',
                gap: 1.5,
              }}
            >
              <Radio
                value={index}
                className={classes.spanDiv}
                sx={{
                  mt: -1,
                  mr: -1,
                  mb: 0.5,
                  color: '#0CE1B7',
                  borderColor: '#0CE1B7',
                  alignSelf: 'flex-end',
                  '--Radio-action-radius': (theme) => theme.vars.radius.md,
                }}
              />
              <img
                src={`${photosApiUrl}=${photo.photo_reference}&key=${googleApiKey}`}
                alt={`person${index}`}
                style={{ width: '96px', height: '96px', marginLeft: '30px' }}
              />
            </Sheet>
          ))}
      </RadioGroup>
    </FormControl>
  );
}
