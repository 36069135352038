import React from 'react';

function Mark() {
  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="10"
      fill="none"
      viewBox="0 0 10 10"
    >
      <path
        fill="#011531"
        d="M4.5 6.5h1v1h-1v-1zm0-4h1v3h-1v-3zM5 0a5 5 0 100 10A5 5 0 005 0zm0 9a4 4 0 110-8 4 4 0 010 8z"
      />
    </svg>
  );
}
export default Mark;
