/* eslint-disable consistent-return */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
import { useQuery } from 'react-query';

import { axiosInstance } from '../../../axiosInstance';
import { queryKeys } from '../../../react-query/consts';

export const photosApiUrl =
  'https://maps.googleapis.com/maps/api/place/photo?maxwidth=100&maxheight=100&photo_reference';
export const googleApiKey = 'AIzaSyBFdyonn45zixfNRgSE0jlbBFjwWr4Zo8Q';

export interface LocationAutocomplete {
  description: string;
  placeId: string;
}

export interface LanLngCoords {
  lat: number;
  lng: number;
}

export interface Photo {
  photo_reference: string;
  pageImageRef?: {
    title: string;
    url: string;
  };
}

export async function fetchAutocomplete(
  input: string,
): Promise<LocationAutocomplete[]> {
  const {
    data: { places = [] },
  } = await axiosInstance.get(`/test/autocomplete?input=${input}`);
  return places;
}

export async function fetchPlaceDetails(placeId: string) {
  const {
    data: { location = {}, photos = [], accesible = false },
  } = await axiosInstance.get(`/test/place-details?placeId=${placeId}`);
  return { location, photos, accesible };
}
