import React from 'react';

function Bundles() {
  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke="#011531"
        d="M6.708 2.802h0v1.602c0 .834-.219 1.389-.567 1.737-.348.348-.903.567-1.737.567h-1.6c-.834 0-1.389-.219-1.737-.567C.72 5.793.5 5.238.5 4.404v-1.6c0-.834.219-1.388.568-1.736C1.417.719 1.974.5 2.813.5h1.6c.834 0 1.388.22 1.735.567.347.347.564.901.56 1.735zM11.596.5h1.6c.834 0 1.389.219 1.737.567.348.348.567.903.567 1.737v1.6c0 .834-.22 1.389-.567 1.737-.348.348-.903.567-1.737.567h-1.6c-.834 0-1.39-.219-1.737-.567-.348-.348-.568-.903-.568-1.737v-1.6c0-.834.22-1.389.568-1.737C10.207.72 10.762.5 11.596.5zM11.596 9.283h1.6c.834 0 1.389.22 1.737.567.348.349.567.903.567 1.737v1.6c0 .834-.22 1.39-.567 1.737-.348.349-.903.568-1.737.568h-1.6c-.834 0-1.39-.22-1.737-.568-.348-.348-.568-.903-.568-1.737v-1.6c0-.834.22-1.388.568-1.737.348-.348.903-.567 1.737-.567zM6.708 11.594h0v1.602c0 .834-.219 1.389-.567 1.737-.348.348-.903.567-1.737.567h-1.6c-.834 0-1.388-.219-1.736-.568C.719 14.583.5 14.026.5 13.187v-1.6c0-.834.219-1.388.568-1.736.349-.348.906-.568 1.745-.568h1.6c.834 0 1.388.22 1.735.568.347.348.564.904.56 1.742z"
      />
    </svg>
  );
}

export default Bundles;
