import React from 'react';

function PlusWithCircle() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
    >
      <path
        d="M11.6706 1H18.3294C21.9818 1 24.6068 1.96145 26.3227 3.67732C28.0386 5.39319 29 8.0182 29 11.6706V18.3294C29 21.9818 28.0386 24.6068 26.3227 26.3227C24.6068 28.0386 21.9818 29 18.3294 29H11.6706C8.0182 29 5.39319 28.0386 3.67732 26.3227C1.96145 24.6068 1 21.9818 1 18.3294V11.6706C1 8.0182 1.96145 5.39319 3.67732 3.67732C5.39319 1.96145 8.0182 1 11.6706 1Z"
        stroke="#011531"
        strokeWidth="2"
      />
      <path
        d="M15 8.24976L15 21.7498"
        stroke="#011531"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M21.7505 15L8.25049 15"
        stroke="#011531"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default PlusWithCircle;
