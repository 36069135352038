import React from 'react';

function Lock() {
  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="33"
      fill="none"
      viewBox="0 0 30 33"
    >
      <rect width="29" height="22" x="0.5" y="10.5" stroke="#011531" rx="3.5" />
      <circle cx="7.8" cy="22" r="1.8" fill="#011531" />
      <circle cx="15" cy="22" r="3" fill="#011531" />
      <circle cx="22.2" cy="22" r="1.8" fill="#011531" />
      <path
        stroke="#011531"
        d="M7.5 5A4.5 4.5 0 0112 .5h7A4.5 4.5 0 0123.5 5v5.5h-16V5z"
      />
    </svg>
  );
}
export default Lock;
