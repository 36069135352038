import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { Item } from '../../../interfaces/Item';
import GoogleImageCard from '../../common/GoogleImageCard';
import SelectImageTypeTabs from '../../common/SelectImageTypeTabs';
import UploadImageCard from '../../common/UploadImageCard';
import {
  googleApiKey,
  LanLngCoords,
  Photo,
  photosApiUrl,
} from '../../locations/hooks/useGoogleMaps';
import { Place } from '../../maps/Searchbar';
import WrapperMap from '../../maps/WrapperMap';
import AddItemForm from '../addItemPopup/AddItemForm';
import ImagesPopup from '../imagesPopup/ImagesPopup';

interface EditItemPopupProps {
  open: boolean;
  onClose: () => void;
  item: Item;
  onEditItem: (editedItem: Item) => void;
}

const schema = yup
  .object({
    title: yup.string().required(),
    subType: yup.string().required(),
    description: yup.string().required(),
    phone: yup.string().required(),
    website: yup.string().required(),
    facebook: yup.string().required(),
    instagram: yup.string().required(),
  })
  .required();

enum ImageType {
  GoogleImage,
  SimpleImage,
}

export default function EditItemPopup({
  open,
  onClose,
  item,
  onEditItem,
}: EditItemPopupProps) {
  const [images, setImages] = useState<Photo[]>([]);
  const [imagesPopupOpen, setImagesPopupOpen] = useState<boolean>(false);
  const [customSelectedImage, setCustomSelectedImage] = useState<string>('');
  const [selectedTab, setSelectedTab] = useState<ImageType>(
    ImageType.GoogleImage,
  );
  const {
    itemImageRef: originalImageRef,
    location: originalLocation,
    contact,
    title,
    subType,
    description,
    openingHours,
    imageUrl = '',
    ...rest
  } = item;

  const [itemImageRef, setItemImageRef] = useState(originalImageRef);
  const [location, setLocation] = useState(originalLocation);
  const [googleSelectedImage, setGoogleSelectedImage] =
    useState<string>(imageUrl);

  const { control, getValues } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      contact: {
        facebook: contact.facebook || '',
        instagram: contact.instagram || '',
        phone: contact.phone || '',
        website: contact.website || '',
      },
      title,
      subType,
      description,
      openingHours,
    },
  });

  const handleLocationChanged = (
    photos: Photo[],
    position: LanLngCoords,
    place: Place,
    accesible: boolean,
  ) => {
    const { lat, lng } = position;
    setImages(photos);
    setLocation({
      address: place.description,
      coordinates: [lat, lng],
      placeId: place.placeId,
      accesible,
    });
  };

  const handleImageRef = (index: number) => {
    setGoogleSelectedImage(
      `${photosApiUrl}=${images[index].photo_reference}&key=${googleApiKey}`,
    );
    setItemImageRef({
      title: images[index].pageImageRef?.title || itemImageRef?.title,
      url: images[index].pageImageRef?.url || itemImageRef?.url,
    });
  };

  const handleSelectedTab = (tab: number) => {
    setSelectedTab(tab);
  };

  const handleSave = () => {
    onEditItem({
      imageUrl: selectedTab ? customSelectedImage : googleSelectedImage,
      itemImageRef,
      location,
      ...getValues(),
      ...rest,
    });
  };

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="xl"
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Edit Item</DialogTitle>
        <AddItemForm control={control} />
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            margin: 4,
          }}
        >
          <WrapperMap
            coordinate={item?.location?.coordinates}
            onSelectLocation={handleLocationChanged}
            showLanLng={false}
          />
          <div style={{ marginTop: 70 }}>
            <SelectImageTypeTabs
              tab={selectedTab}
              onTabChanged={handleSelectedTab}
            />
            {selectedTab ? (
              <UploadImageCard
                img={customSelectedImage}
                onChangeImage={(imgsrc) => setCustomSelectedImage(imgsrc)}
              />
            ) : (
              <GoogleImageCard
                disable={!images.length}
                onClick={() => setImagesPopupOpen(true)}
                title="depultative google"
                imgSrc={googleSelectedImage}
                buttonTxt="change image"
              />
            )}
          </div>
        </div>
        <ImagesPopup
          onSelected={handleImageRef}
          open={imagesPopupOpen}
          onClose={() => setImagesPopupOpen(false)}
          images={images}
        />
        <DialogActions>
          <Button onClick={handleSave}>save</Button>
          <Button onClick={() => onClose()} autoFocus>
            cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
